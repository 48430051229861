import { PermissionType } from 'services/enums/permissions';
import { Auth } from 'services/slices/authSlice';
import { ConfigState } from 'services/slices/config';
import { isProductionEnvironment } from 'utilities/environment';

export const canViewSupplyChainRoute = () => {
  return () => {
    return true;
  };
};

export const canViewOrganizationRoute = () => {
  return () => {
    return true;
  };
};

export const canViewAppRoute = () => {
  return () => {
    return true;
  };
};

export const whitelistOrganizationType = (organizationTypes: string[]) => {
  return (authState: Auth) => {
    if (organizationTypes.includes(authState.organizationType)) {
      return true;
    }
    return false;
  };
};

export const canViewOnlyInDev = () => {
  return () => {
    return !isProductionEnvironment();
  };
};

export const canViewWithPermission = (checkPermission: PermissionType) => {
  return (authState: Auth) => {
    return authState.permissions.includes(checkPermission);
  };
};

export const passesAllOf = (
  permissionFns: { (authState: Auth, flags: ConfigState): boolean }[]
) => {
  return (authState: Auth, flags: ConfigState) => {
    for (let index = 0; index < permissionFns.length; index++) {
      const fn = permissionFns[index];
      if (!fn(authState, flags)) {
        return false;
      }
    }
    return true;
  };
};

export const passesOneOf = (
  permissionFns: { (authState: Auth): boolean }[]
) => {
  return (authState: Auth) => {
    for (let index = 0; index < permissionFns.length; index++) {
      const fn = permissionFns[index];
      if (fn(authState)) {
        return true;
      }
    }
    return false;
  };
};

export const canViewFeature = (flag: keyof ConfigState) => {
  return (_: Auth, flags: ConfigState) => {
    return flags[flag];
  };
};
