export enum FeatureFlag {
  // TEAMS AND BRANCHES
  FEATURE_FLAG_ENABLE_TEAMS = 'FEATURE_FLAG_ENABLE_TEAMS',
  FEATURE_FLAG_ENABLE_BRANCH = 'FEATURE_FLAG_ENABLE_BRANCH',
  // VAULT
  FEATURE_FLAG_ENABLE_BRANCH_VAULT = 'FEATURE_FLAG_ENABLE_BRANCH_VAULT',
  // WORKFLOWS
  FEATURE_FLAG_ENABLE_WORKFLOW_COMMENTS = 'FEATURE_FLAG_ENABLE_WORKFLOW_COMMENTS',
  FEATURE_FLAG_ENABLE_CUSTOM_WORKFLOWS = 'FEATURE_FLAG_ENABLE_CUSTOM_WORKFLOWS',
  FEATURE_FLAG_ENABLE_ASSIGN_TEAM_TO_WORKFLOW_STEP = 'FEATURE_FLAG_ENABLE_ASSIGN_TEAM_TO_WORKFLOW_STEP',
  FEATURE_FLAG_ENABLE_UPLOAD_DOCUMENT_TO_WORKFLOW_STEP = 'FEATURE_FLAG_ENABLE_UPLOAD_DOCUMENT_TO_WORKFLOW_STEP',
  // NOTIFICATIONS
  FEATURE_FLAG_ENABLE_NOTIFICATIONS_EXTRA = 'FEATURE_FLAG_ENABLE_NOTIFICATIONS_EXTRA'
}
