import React, { PropsWithChildren } from 'react';
import { Dialog } from '@mui/material';

type PickupCodeValidationModalProps = {
  open: boolean;
  onClose: () => void;
};

const ValidatePickupCodeModal: React.FC<
  PropsWithChildren<PickupCodeValidationModalProps>
> = ({ open, onClose, children }) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '24.5rem',
          borderRadius: '0.75rem',
          border: '.125rem solid #F5F6F7',
          backgroundColor: '#FCFCFD'
        }
      }}
    >
      {children}
    </Dialog>
  );
};

export default ValidatePickupCodeModal;
