import { TeamApiData } from 'services/types/teams';
import { api } from './baseApi';
import { providesObjectWithIds } from './utils';

type CreateTeamQueryArgs = {
  data: {
    name: string;
  };
};

export const teamApi = api.injectEndpoints({
  endpoints: builder => ({
    getTeams: builder.query<TeamApiData[], unknown>({
      query: () => ({
        url: '{organizationId}/teams/',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: result => {
        if (result) {
          return providesObjectWithIds(Object.keys(result), 'teams');
        }
        return [];
      }
    }),
    addTeam: builder.mutation<TeamApiData, CreateTeamQueryArgs>({
      query: ({ data }) => ({
        url: '{organizationId}/teams/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: [{ type: 'teams', id: 'LIST' }]
    })
  }),
  overrideExisting: false
});

export const { useGetTeamsQuery, useAddTeamMutation, usePrefetch } = teamApi;
