import React, { PropsWithChildren } from 'react';
import { Dialog } from '@mui/material';

type SchedulePickupModalProps = {
  open: boolean;
  onClose: () => void;
};

const SchedulePickupModal: React.FC<
  PropsWithChildren<SchedulePickupModalProps>
> = ({ open, onClose, children }) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        padding: '1.25rem',
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '24.5rem',
            borderRadius: '0.75rem',
            border: '.125rem solid #F5F6F7',
            backgroundColor: '#FCFCFD'
          }
        }
      }}
    >
      {children}
    </Dialog>
  );
};

export default SchedulePickupModal;
