import React, { useState } from 'react';
import { Box } from '@mui/material';
import LineChart from '../../../../components/charts/linechart';
import { theme } from 'themes/theme';
import { DateRangeType, Nav } from './chartsNav';
import { useGetAggregateProductReceiptsQuery } from 'services/api/analyticsApi';
import { formattedDate } from 'utilities/helperFunc/formatter';

const TotalPrcValueChart = () => {
  const [dateRange, setDateRange] = useState<DateRangeType>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
    granularity: 'daily'
  });

  const { data: totalPrcValue } = useGetAggregateProductReceiptsQuery({
    params: {
      start: dateRange.startDate
        ? formattedDate(dateRange.startDate, 'YYYY-MM-DD')
        : '2024-01-01',
      include_closed: 'true',
      granularity: dateRange.granularity,
      ...(dateRange.endDate && {
        end: formattedDate(dateRange.endDate, 'YYYY-MM-DD')
      })
    }
  });

  const labels = totalPrcValue ? totalPrcValue.map(item => item.date) : [];
  const chartDataset = [
    {
      data: totalPrcValue ? totalPrcValue.map(item => item.value) : [],
      label: 'Total Value',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light
    }
  ];

  return (
    <Box
      sx={{
        width: '100%',
        height: '30.39rem',
        backgroundColor: '#FFFFFF',
        borderRadius: '0.5rem',
        border: '.063rem solid #F5F6F7',
        padding: '1rem'
      }}
    >
      <Nav
        header="Total Value of Product Receipts"
        setDateRange={setDateRange}
      />
      <LineChart labels={labels} datasets={chartDataset} />
    </Box>
  );
};

export default TotalPrcValueChart;
