import {
  WorkflowSchemaApiData,
  UserWorkflowApiData
} from 'services/types/workflow';
import { WithIdTransformed } from 'services/types/common';
import { api } from './baseApi';
import { providesObjectWithIds } from './utils';
import { convertToQueryString } from 'services/urlSearchParams';

export type WorkflowStepApiRequestData = {
  step_order: number;
  step_title: string;
  step_description: string;
  step_type: string;
  owned_by_role: string;
  owned_by_team: string;
  step_individual_owners?: { name: string; email: string }[];
  step_team_owner?: { team_id: string; name: string }[];
};

export type WorkflowApiRequestData = {
  steps: WorkflowStepApiRequestData[];
  use_case: string;
  should_publish?: boolean;
  version_id: number;
};

export type WorkflowUseCaseApiData = {
  value: string;
  description: string;
  metadata_options: string[];
};

type GetWorkflowsQueryArgs = {
  params: { include_drafts: string };
};

type ActionWorkflowStepQueryArgs = {
  workflowId: string;
  invalidateTag: string;
  invalidateTagId: string | number;
  data: {
    step_order: number;
    step_outcome: boolean;
    target_object: string;
    target_object_id: string;
    current_use_case: string;
  };
};

type GetUserActiveWorkflowsQueryArgs = {
  params: { limit: string };
};

export const workflowApi = api.injectEndpoints({
  endpoints: builder => ({
    getWorkflows: builder.query<
      WithIdTransformed<WorkflowSchemaApiData>,
      GetWorkflowsQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{organizationId}/workflows/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      providesTags: result => {
        if (result) {
          return providesObjectWithIds(Object.keys(result), 'workflows');
        }
        return [];
      }
    }),
    getWorkflow: builder.query({
      query: ({ useCase, params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{organizationId}/workflows/${useCase}/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      providesTags: result => {
        if (result) {
          return providesObjectWithIds(Object.keys(result), 'workflows');
        }
        return [];
      }
    }),
    saveWorkflow: builder.mutation({
      query: ({ data }) => ({
        url: '{organizationId}/workflows/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: [{ type: 'workflow', id: 'LIST' }]
    }),
    getWorkflowUseCases: builder.query<WorkflowUseCaseApiData[], unknown>({
      query: () => ({
        url: '{organizationId}/workflows/usecase/all/',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    actionStep: builder.mutation<unknown, ActionWorkflowStepQueryArgs>({
      query: ({ workflowId, data }) => ({
        url: `{organizationId}/workflows/${workflowId}/action/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: args.invalidateTag, id: args.invalidateTagId }
      ]
    }),
    getUserWorkflow: builder.query<
      UserWorkflowApiData[],
      GetUserActiveWorkflowsQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{organizationId}/user/workflows/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    })
  }),
  overrideExisting: false
});

export const {
  useGetWorkflowsQuery,
  useGetWorkflowQuery,
  useLazyGetWorkflowQuery,
  useSaveWorkflowMutation,
  useGetWorkflowUseCasesQuery,
  useActionStepMutation,
  useGetUserWorkflowQuery
} = workflowApi;
