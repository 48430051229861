import React from 'react';
import { Box } from '@mui/material';
import { ColumnFilter, ColumnFiltersState } from '@tanstack/react-table';
import { Chip } from 'components/chip';

interface SelectedFilterProps {
  columnFilters: ColumnFiltersState;
  setColumnFilters: (value: React.SetStateAction<ColumnFiltersState>) => void;
}

const SelectedFilters = ({
  columnFilters,
  setColumnFilters
}: SelectedFilterProps) => {
  const removeFilter = (id: string, valueToRemove?: string) => {
    setColumnFilters((prevFilters: ColumnFiltersState) =>
      prevFilters
        .map(filter => {
          if (filter.id === id) {
            if (Array.isArray(filter.value)) {
              const newValue = filter.value.filter(
                (value: string) => value !== valueToRemove
              );

              if (newValue.length > 0) {
                return { ...filter, value: newValue };
              }
              return undefined;
            }
            return undefined;
          }
          return filter;
        })
        .filter((filter): filter is ColumnFilter => filter !== undefined)
    );
  };

  return (
    <>
      {columnFilters.length >= 1 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.75rem',
            height: '3.75rem',
            flexGrow: 1,
            maxWidth: '100%',
            overflowX: 'auto',
            backgroundColor: '#FFF',
            borderTop: '.0625rem solid #F5F6F7',
            mb: '0.75rem'
          }}
        >
          {columnFilters.length >= 1 &&
            columnFilters.map((filter: ColumnFilter) => {
              return Array.isArray(filter.value) ? (
                filter.value.map((value: string, index: number) => {
                  return (
                    <Box key={index} sx={{ ml: '1rem' }}>
                      <Chip
                        label={value}
                        size={'md'}
                        onDelete={() => removeFilter(filter.id, value)}
                      />
                    </Box>
                  );
                })
              ) : (
                <Box sx={{ ml: '1rem' }}>
                  <Chip
                    label={`${filter.value}`}
                    size={'md'}
                    onDelete={() => removeFilter(filter.id)}
                  />
                </Box>
              );
            })}
        </Box>
      )}
    </>
  );
};

export default SelectedFilters;
