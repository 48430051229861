import _ from 'lodash';

export const convertToQueryString = (params: {
  [key: string]: string | string[] | number | number[] | undefined;
}): string => {
  const urlSearchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (_.isUndefined(value)) {
      continue;
    } else if (Array.isArray(value)) {
      value.forEach(item => {
        if (!_.isUndefined(item) && !_.isNull(item) && !_.isEmpty(item)) {
          urlSearchParams.append(key, item.toString());
        }
      });
    } else {
      urlSearchParams.append(key, value.toString());
    }
  }

  return urlSearchParams.toString();
};
