import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Divider,
  Dialog,
  DialogContent
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { ReactComponent as ZapIcon } from 'assets/svg/zap-fast.svg';
import { ReactComponent as Stars } from 'assets/svg/stars.svg';
import { ReactComponent as Home } from 'assets/svg/home.svg';
import { Button } from 'components/button';
import { FormRadio, FormSelect, FormSelectOption } from 'components/form';
import { SvgWrapper } from 'components/svg';
import { useAppSelector } from 'services/hook';
import { useGetProductsQuery } from 'services/api/productApi';
import { setItem } from 'utilities/helperFunc/localStore';
import { workflowTypeValidator } from './validators/workflowValidators';

export type AddWorkflowModalProps = {
  open: boolean;
  onClose: () => void;
  useCaseOptions: {
    title: string;
    value: string;
    subtitle: string;
  }[];
  customizableUseCaseOptions: {
    title: string;
    value: string;
    metadata_options: string[];
  }[];
  merchantOptions: {
    title: string;
    value: string;
  }[];
  customWorkflowFlag: boolean;
};

type FormValues = {
  workflowUseCase: string;
  workflowType: 'standard' | 'custom' | null;
  merchant: string;
  product: string;
};

const AddWorkflowModal: React.FC<AddWorkflowModalProps> = ({
  open,
  onClose,
  useCaseOptions,
  customizableUseCaseOptions,
  merchantOptions,
  customWorkflowFlag
}) => {
  const [formComponent, setFormComponent] = useState(0);
  const [isFormValid, setIsFormValid] = useState<string[]>([]);
  const { supplyChainProfileId, organizationType } = useAppSelector(
    state => state.auth
  );

  const {
    control,
    clearErrors,
    watch,
    setValue,
    reset,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      workflowUseCase: '',
      workflowType: null,
      merchant: '',
      product: ''
    },
    resolver: yupResolver(
      workflowTypeValidator(isFormValid)
    ) as unknown as Resolver<FormValues>
  });

  const navigate = useNavigate();
  const workflowTypeValue = watch('workflowType');
  const workflowUseCaseValue = watch('workflowUseCase');
  const selectedMerchant = watch('merchant');
  const selectedProduct = watch('product');

  const { data: productData } = useGetProductsQuery(
    organizationType === 'merchant' ? supplyChainProfileId : selectedMerchant
  );

  const selectedUseCase = customizableUseCaseOptions.find(
    useCase => useCase.value === workflowUseCaseValue
  );

  const metadataOptions = selectedUseCase?.metadata_options || [];

  const productOptions = useMemo(() => {
    const options: FormSelectOption[] = [];
    productData?.forEach(product => {
      options.push({
        title: product.name,
        value: product.product_id
      });
    });
    return options;
  }, [productData]);

  const onDialogClose = () => {
    clearErrors();
    onClose();
    setFormComponent(0);
    reset();
  };

  const switchToNextForm = () => {
    setFormComponent(prev => prev + 1);
  };

  const switchToPrevForm = () => {
    setFormComponent(prev => prev - 1);
  };

  useEffect(() => {
    if (metadataOptions.length !== 0) {
      setIsFormValid(metadataOptions);
    }
  }, [metadataOptions]);

  useEffect(() => {
    if (customWorkflowFlag == false && formComponent === 0) {
      setValue('workflowType', 'standard');
      setFormComponent(1);
    }
  }, [customWorkflowFlag, formComponent]);

  const renderFormContent = (formComponent: number) => {
    switch (formComponent) {
      case 1:
        return {
          heading:
            workflowTypeValue === 'standard'
              ? customWorkflowFlag
                ? 'Standard Workflow'
                : 'New Workflow'
              : 'Custom Workflow',
          subHeading: 'Select a workflow type below to create workflow',
          component: (
            <FormSelect<FormValues>
              name="workflowUseCase"
              options={
                workflowTypeValue === 'standard'
                  ? useCaseOptions.map(option => ({
                      title: option.title,
                      value: option.value
                    }))
                  : customizableUseCaseOptions.map(option => ({
                      title: option.title,
                      value: option.value
                    }))
              }
              label={'WORKFLOW TYPE'}
              control={control}
              renderSelected={(value: string) => _.startCase(value)}
            />
          ),
          disableBtn: workflowUseCaseValue === ''
        };
      case 2:
        return {
          heading: 'Custom Workflow',
          subHeading: 'Select merchant and/or product for this workflow',
          component: (
            <>
              {metadataOptions.includes('merchant') && (
                <FormSelect<FormValues>
                  name="merchant"
                  options={merchantOptions}
                  label={'MERCHANT'}
                  control={control}
                  renderSelected={(value: string) => _.startCase(value)}
                />
              )}
              {metadataOptions.includes('product') && (
                <FormSelect<FormValues>
                  name="product"
                  options={productOptions}
                  label={'PRODUCT'}
                  control={control}
                  renderSelected={(value: string) => _.startCase(value)}
                />
              )}
            </>
          ),
          disableBtn: !isValid
        };
      default:
        return {
          heading: 'New Workflow',
          subHeading:
            'Create a standard or custom workflow to match your internal processes.',
          component: (
            <FormRadio<FormValues>
              control={control}
              name="workflowType"
              label=""
              radioData={radioData}
            />
          ),
          disableBtn: workflowTypeValue === null
        };
    }
  };

  const { heading, subHeading, component, disableBtn } =
    renderFormContent(formComponent);

  const submitForm = () => {
    setItem('customWorkflowOptions', {
      merchant: selectedMerchant,
      product: selectedProduct
    });
    navigate(
      `/workflows-builder?create=true&workflowUseCase=${workflowUseCaseValue}`
    );
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '27.75rem',
            borderRadius: '0.75rem',
            backgroundColor: '#FCFCFD'
          }
        }
      }}
      open={open}
      onClose={onDialogClose}
    >
      <DialogContent
        sx={{ borderRadius: '.75rem .75rem 0rem 0rem', padding: 0 }}
      >
        <form noValidate>
          <Stack
            sx={{
              flexDirection: 'row',
              background: '#FCFCFD',
              padding: '1.25rem',
              pb: '1rem'
            }}
          >
            {/* New Workflow text column */}
            <Box display="flex" alignItems="flex-start">
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    borderRadius: '6.25rem',
                    border: 'none',
                    background: '#F8FBFE',
                    padding: '.5rem',
                    boxShadow: '0px 0px 0px 4px rgba(195, 215, 253, 0.20)',
                    marginBottom: '.75rem',
                    width: '2rem',
                    height: '2rem'
                  }}
                >
                  <SvgWrapper
                    icon={Home}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{ fill: '#A6C3FC', stroke: 'none' }}
                  />
                </Box>
                <Typography
                  variant="h7Semibold"
                  color="#344054"
                  style={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    lineHeight: '1.375rem',
                    marginBottom: '.125rem'
                  }}
                >
                  {heading}
                </Typography>
                <Typography
                  variant="bodyMediumRegular"
                  style={{
                    color: '#667085'
                  }}
                >
                  {subHeading}
                </Typography>
              </Stack>
            </Box>
          </Stack>

          <Divider />

          <Stack sx={{ margin: '2.25rem 1.25rem' }}>{component}</Stack>

          <Divider />

          {/* Bottom Buttons row */}
          <Stack
            sx={{
              display: 'flex',
              padding: '1.25rem 1.5rem',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '.75rem',
              alignSelf: 'stretch',
              flexDirection: 'row'
            }}
          >
            <Button
              color="grey"
              transparent
              size="lg"
              text={
                (customWorkflowFlag == false && formComponent === 1) ||
                formComponent === 0
                  ? 'Cancel'
                  : 'Back'
              }
              styleOverrides={{
                borderRadius: '.5rem',
                padding: '0.75rem',
                border: '.094rem solid #F2F4F7',
                color: '#475467',
                flex: '1 0 0'
              }}
              onClick={() =>
                (customWorkflowFlag == false && formComponent === 1) ||
                formComponent === 0
                  ? onDialogClose()
                  : switchToPrevForm()
              }
            />

            <Button
              color="primary"
              size="lg"
              text={
                (workflowTypeValue === 'standard' && formComponent === 1) ||
                formComponent === 2
                  ? 'Create Workflow'
                  : 'Next'
              }
              styleOverrides={{
                borderRadius: '.5rem',
                padding: '0.75rem',
                flex: '1 0 0'
              }}
              onClick={() =>
                formComponent === 2 ||
                (workflowTypeValue === 'standard' && formComponent === 1)
                  ? submitForm()
                  : switchToNextForm()
              }
              disabled={disableBtn}
            />
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddWorkflowModal;

const radioData = [
  {
    image: ZapIcon,
    heading: 'Standard',
    value: 'standard',
    subHeading: 'Create workflows using workflow types only'
  },
  {
    image: Stars,
    heading: 'Custom',
    value: 'custom',
    subHeading: 'Create workflows for specific usecases'
  }
];
