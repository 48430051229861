import React, { useContext } from 'react';
import { Dialog } from '@mui/material';
import { BranchesContext } from './branchesContext';

type AddBranchModalProps = {
  open: boolean;
  onClose: () => void;
};

const AddBranchModal: React.FC<AddBranchModalProps> = ({ open, onClose }) => {
  const { steps, currentStep } = useContext(BranchesContext);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        padding: '1.25rem',
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            borderRadius: '0.75rem',
            border: '.125rem solid #F5F6F7',
            backgroundColor: '#FCFCFD'
          }
        }
      }}
    >
      {steps[currentStep].element}
    </Dialog>
  );
};

export default AddBranchModal;
