import * as Yup from 'yup';
import { ProductReceiptIssueType } from '../context/createProductReceiptContext';

export const createProductReceiptValidator = (issueType: string | null) =>
  Yup.object({
    customer_name: Yup.string().required('This field is required'),
    bank: Yup.string().when({
      is: () => issueType === ProductReceiptIssueType.INTERMEDIARY,
      then: schema => schema.required('This field is required'),
      otherwise: schema => schema.notRequired()
    }),
    product: Yup.string().required('This field is required'),
    product_variant: Yup.string().when('product', {
      is: (product: string) => !product,
      then: schema =>
        schema.test(
          'product-variant-required',
          'Please select a product first',
          () => false
        )
    }),
    quantity: Yup.string()
      .required('This field is required')
      .matches(/^[0-9,]+$/, 'Quantity should be a valid number'),
    unit: Yup.string().required('This field is required'),
    amount: Yup.string()
      .required('This field is required')
      .matches(/^[0-9,.₦]+$/, 'Amount should be a valid number'),
    expiry_date: Yup.string()
  });

export const BatchCreateProductReceiptValidator = (issueType: string | null) =>
  Yup.object({
    customer_name: Yup.string().required('This field is required'),
    bank: Yup.string().when({
      is: () => issueType === ProductReceiptIssueType.INTERMEDIARY,
      then: schema => schema.required('This field is required'),
      otherwise: schema => schema.notRequired()
    }),
    product: Yup.string().required('This field is required'),
    product_variant: Yup.string().when('product', {
      is: (product: string) => !product,
      then: schema =>
        schema.test(
          'product-variant-required',
          'Please select a product first',
          () => false
        )
    }),
    unit: Yup.string().required('This field is required'),
    amount: Yup.string()
      .matches(/^[0-9,.₦]+$/, 'Amount should be a valid number')
      .required('This field is required'),
    quantity: Yup.array()
      .of(
        Yup.string().matches(/^[0-9,]+$/, 'Quantity should be a valid number')
      )
      .required('This field is required'),
    expiry_date: Yup.array().of(Yup.string()).required('This field is required')
  });
