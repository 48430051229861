import { useState, useEffect } from 'react';

interface ServerSidePagination<DataT> {
  pageSize: number;
  getServerPage: (page: number) => Promise<{ results: DataT[]; count: number }>;
  prefetchServerPage: (page: number) => void;
}

export default function useServerSidePagination<DataT>({
  pageSize,
  getServerPage,
  prefetchServerPage
}: ServerSidePagination<DataT>) {
  const [data, setData] = useState<DataT[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: pageSize
  });

  const prefetchPages = (pages: number[]) => {
    pages.forEach(page => prefetchServerPage(page));
  };

  const getPage = async (page: number) => {
    await getServerPage(page)
      .then(res => {
        setData(res.results);
        setRowCount(res.count);
        setPagination(prevState => {
          return {
            ...prevState,
            pageIndex: page - 1
          };
        });
      })
      .catch(error => console.error(error));
  };

  // When this is mounted fetch the first page
  useEffect(() => {
    const fetchFirstPage = async () => {
      await getPage(1);
    };
    fetchFirstPage();
  }, []);

  return { data, rowCount, pagination, prefetchPages, getPage };
}
