import React from 'react';
import { Box, Grid } from '@mui/material';
import SideBar from 'components/nav/sidebar';
import AppNavBar from 'components/nav/appNavbar';

const AppLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Grid container sx={{ height: 'inherit', overflow: 'hidden' }}>
      <Grid
        item
        xs={2.3}
        sx={{
          height: '100%',
          borderRight: '.094rem solid #F2F4F7',
          background: '#FCFCFC'
        }}
      >
        <SideBar />
      </Grid>
      <Grid
        item
        xs={9.7}
        sx={{
          background: '#FEFEFE'
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2
          }}
        >
          <AppNavBar />
        </Box>

        <Box
          sx={{
            px: {
              md: '3rem',
              lg: '6rem',
              xl: '10rem'
            },
            paddingY: {
              md: '2rem',
              lg: '2.5rem',
              xl: '5rem'
            },
            maxHeight: '93.7vh',
            overflowY: 'auto'
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AppLayout;
