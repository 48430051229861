import * as Yup from 'yup';

export const createReportValidator = Yup.object({
  reportType: Yup.string().required('This field is required'),
  reportName: Yup.string().required('This field is required'),
  dealers: Yup.array()
    .of(Yup.string())
    .when('reportType', {
      is: (reportType: string) => reportType === 'multiple-dealers',
      then: schema =>
        schema
          .min(1, 'Select at least 1 dealer')
          .required('This field is required')
    }),
  startDate: Yup.string().required('A start date is required'),
  endDate: Yup.string(),
  granularity: Yup.string(),
  emails: Yup.array()
    .of(Yup.string().email('Invalid email format'))
    .min(0)
    .max(9, 'You can only add 10 emails at once')
    .test(
      'isValidEmails',
      'Invalid email format',
      value =>
        value !== undefined &&
        value !== null &&
        value.every(el => Yup.string().email().isValidSync(el))
    )
    .test(
      'isUniqueEmails',
      'Duplicate emails detected',
      value =>
        value === undefined ||
        value === null ||
        new Set(value).size === value.length
    )
});
