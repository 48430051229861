import { NotificationApiData } from 'services/types/notifications';
import { api } from './baseApi';
import { providesObjectWithIds } from './utils';
import { WithIdTransformed } from 'services/types/common';
import { convertToQueryString } from 'services/urlSearchParams';

type GetNotificationsQueryArgs = {
  params: { include_seen: string };
};

type ViewNotificationsQueryArgs = {
  data: { notification_ids: string[] };
};

export const notificationApi = api.injectEndpoints({
  endpoints: builder => ({
    getNotifications: builder.query<
      WithIdTransformed<NotificationApiData>,
      GetNotificationsQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `notifications/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      providesTags: result => {
        if (result) {
          return providesObjectWithIds(Object.keys(result), 'notification');
        }
        return [];
      }
    }),
    viewNotification: builder.mutation<null, ViewNotificationsQueryArgs>({
      query: ({ data }) => ({
        url: 'notifications/{userId}/view/',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      }),
      invalidatesTags: (result, error, arg) =>
        arg.data.notification_ids.map(id => ({ type: 'notification', id }))
    })
  }),
  overrideExisting: false
});

export const { useGetNotificationsQuery } = notificationApi;
