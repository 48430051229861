import React from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';
import { Button } from 'components/button';
import { Drawer } from 'components/drawer';
import { FormInput } from 'components/form';
import { useForm } from 'react-hook-form';
import { TabContainer } from 'components/tabs';
import Members from './members';
import Permissions from './permissions';
import { Role } from '../types/rolesTypes';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  data: Role;
}

type FormValues = {
  role: string;
  description: string;
};

const EditRoles: React.FC<DrawerProps> = ({ open, onClose, data }) => {
  const { control } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      role: data.role.name,
      description: data.role.description
    }
  });

  const tabs = [
    {
      labelText: 'Permissions',
      content: <Permissions role={data.role} />
    },
    {
      labelText: 'Members',
      content: <Members role={data.role} users={data.users} />
    }
  ];

  const renderDrawerHeader = () => {
    return (
      <Box padding="1.25rem 1.25rem 1.25rem 0rem" width="inherit">
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          spacing={1}
          padding="1.25rem 0 0 0"
          width="inherit"
        >
          <Stack direction="row" spacing={1} justifyContent="flex-start">
            <Typography variant="h6Bold" color="#475467">
              {data.role.name}
            </Typography>
            {data.role.is_taficasa_default && (
              <Typography variant="bodyMediumMedium" color="#98A2B3">
                (Default Role)
              </Typography>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            width="inherit"
          >
            <Stack direction="column">
              <Typography variant="captionXSmall" color="#98A2B3">
                Description
              </Typography>
              <Typography variant="bodyMediumMedium" color="#667085">
                {data.role.description}
              </Typography>
            </Stack>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                width: '0.0625rem',
                height: '2.375rem',
                borderRadius: '0.125rem',
                borderLeft: '0.0625rem solid #F2F4F7',
                borderRight: '0.0625rem solid #F2F4F7',
                margin: '0rem 1.5625rem'
              }}
            />
            <Stack direction="column">
              <Typography variant="captionXSmall" color="#98A2B3">
                Permissions
              </Typography>
              <Typography variant="bodySmallMedium" color="#667085">
                {data.permissions.length}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    );
  };

  return (
    <Drawer open={open} onClose={onClose} header={renderDrawerHeader()}>
      <Box>
        <Box padding="1rem 1.25rem">
          <Stack
            display="flex"
            padding="1.25rem"
            flexDirection="column"
            alignItems="flex-start"
            gap="1rem"
            alignSelf="stretch"
            sx={{
              background: '#FBFBFC',
              border: '0.063rem solid #F2F4F7',
              borderRadius: '0.75rem'
            }}
          >
            <Typography variant="bodyLargeSemibold" color="#475467">
              About
            </Typography>
            <FormInput<FormValues>
              control={control}
              name="role"
              label="NAME OF ROLE"
              disabled={data.role.is_taficasa_default}
            />
            <FormInput<FormValues>
              control={control}
              name="description"
              label="DESCRIPTION"
              disabled={data.role.is_taficasa_default}
            />
          </Stack>
        </Box>

        <Stack
          sx={{
            display: 'flex',
            padding: '0.75rem 1.5rem',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            gap: '1rem',
            alignSelf: 'stretch',
            borderRadius: '0rem 0rem 0rem 0.75rem',
            borderTop: '0.063rem solid #F5F6F7',
            background: '#FCFCFD'
          }}
        >
          <Stack direction="row" spacing={1}>
            <Button
              color="grey"
              transparent
              size="md"
              text="Cancel"
              onClick={onClose}
            />
            <Button color="primary" size="md" text="Save changes" disabled />
          </Stack>
        </Stack>

        <TabContainer variant="fullWidth" tabs={tabs} />
      </Box>
    </Drawer>
  );
};

export default EditRoles;
