import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { ReactComponent as BankIcon } from 'assets/svg/bank.svg';
import { FormInput } from 'components/form';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import { createTeamValidator } from '../../teamsValidators';
import { TeamsContext } from '../../teamsContext';

type FormValues = {
  name: string;
  description: string;
};

const CreateTeamStep = () => {
  const { data, handleNextStep, setCreateTeamModalOpen } =
    useContext(TeamsContext);

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      name: data.name,
      description: data.description
    },
    resolver: yupResolver(
      createTeamValidator
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = data => {
    handleNextStep(data);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1.25rem',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '.125rem solid #F5F6F7',
          width: '24.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2.25rem',
            width: '2.25rem',
            borderRadius: '6.25rem',
            backgroundColor: '#E5EEFF',
            padding: '0.5rem',
            boxShadow: '0px 0px 0px 4px rgba(200, 219, 255, 0.20)',
            marginBottom: '1rem'
          }}
        >
          <SvgWrapper
            icon={BankIcon}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              opacity: '0.8rem'
            }}
            color="#C1D6FF"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h7Semibold" color="#344054">
            Create Team
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Create a team and share access to other team members by invite
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          margin: '1rem .75rem',
          padding: '1.25rem',
          overflowY: 'auto',
          backgroundColor: 'rgba(102, 112, 133, 0.02)',
          borderRadius: '0.5rem',
          border: '.063rem solid #F5F6F7',
          width: '24.5rem'
        }}
      >
        <FormInput<FormValues>
          control={control}
          name="name"
          label={'NAME OF TEAM'}
        />
        <FormInput<FormValues>
          control={control}
          name="description"
          label={'DESCRIPTION (OPTIONAL)'}
        />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '.063rem solid #F5F6F7',
            padding: '1.25rem 1.5rem',
            marginBottom: '0.25rem'
          }}
        >
          <Button
            color="grey"
            transparent
            size="lg"
            onClick={() => {
              setCreateTeamModalOpen(false);
            }}
            text="Back"
            width="48%"
          />
          <Button
            submit
            color="primary"
            size="lg"
            disabled={!isValid}
            text="Next"
            width="48%"
          />
        </Box>
      </form>
    </>
  );
};

export default CreateTeamStep;
