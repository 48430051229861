import React, { useContext, useState } from 'react';
import { Box, Button as MUIButton, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronUp } from 'assets/svg/chevron-up.svg';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrowLeft.svg';
import { SvgWrapper } from 'components/svg';
import { ProductApiData } from 'services/types/product';
import { formattedDate, formattedNumber } from 'utilities/helperFunc/formatter';
import {
  CreateProductReceiptContext,
  FormValues,
  ProductReceiptIssueType
} from '../../context/createProductReceiptContext';
import _ from 'lodash';

type ReviewBatchPRCProps = {
  productData: ProductApiData[] | undefined;
};

const ReviewBatchProductReceipts = ({ productData }: ReviewBatchPRCProps) => {
  const { data, selectedIssueType } = useContext(CreateProductReceiptContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const prcs = data.map((item: FormValues) => ({
    quantity: item.quantity,
    expiry_date: item.expiry_date
  }));

  const productName = (product: FormValues['product']) => {
    const element = productData?.find(item => item.product_id === product);
    return element?.name !== undefined ? element.name : '---';
  };

  const productVariantName = (
    productVariant: FormValues['product_variant']
  ) => {
    const element = productData?.find(
      item => item.product_id === data[0].product
    );
    if (element && element.variants && element.variants.length > 0) {
      const foundVariant = element.variants.find(
        variant => variant.product_variant_id === productVariant
      );
      return foundVariant?.currency;
    }
    return '---';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'inherit',
        width: '29rem',
        margin: '0 auto',
        marginTop: '1.5rem'
      }}
    >
      <Box
        sx={{
          border: '0.063rem solid #F5F6F7',
          borderRadius: '.75rem',
          mb: '1.12rem',
          background: '#fff'
        }}
      >
        <Stack
          display="flex"
          flexDirection="column"
          sx={{
            padding: '1rem',
            paddingTop: '1.25rem',
            borderBottom: '.063rem solid #F5F6F7',
            gap: '.25rem'
          }}
        >
          <Typography variant="h6Bold" color="#475467">
            Review Details
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Ensure product receipt details are accurate
          </Typography>
        </Stack>

        <Box padding="1rem">
          <Stack
            direction="row"
            justifyContent="space-between"
            paddingBottom={isOpen ? '.75rem' : '0rem'}
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Typography variant="bodyMediumSemibold" color="#667085">
              Primary Details
            </Typography>

            <SvgWrapper
              icon={isOpen ? ChevronUp : ChevronDown}
              width="1.25rem"
              height="1.25rem"
              styleOverrides={{
                fill: 'none',
                stroke: '#667085'
              }}
            />
          </Stack>

          {isOpen ? (
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              sx={{
                pb: '0rem'
              }}
            >
              <Stack
                direction="column"
                width="inherit"
                justifyContent="space-between"
              >
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Customer
                  </Typography>
                  <Typography
                    variant="bodyLargeSemibold"
                    color="#475467"
                    width="55%"
                  >
                    {_.startCase(data[0].customer_name)}
                  </Typography>
                </Stack>
                {selectedIssueType === ProductReceiptIssueType.INTERMEDIARY && (
                  <Stack
                    direction="row"
                    width="inherit"
                    justifyContent="space-between"
                    sx={{
                      borderBottom: '.063rem solid #FCFCFD',
                      padding: '1rem 0rem'
                    }}
                  >
                    <Typography variant="bodyMediumMedium" color="#667085">
                      Lender
                    </Typography>
                    <Typography
                      variant="bodyLargeSemibold"
                      color="#475467"
                      width="55%"
                    >
                      {_.startCase(data[0].bank)}
                    </Typography>
                  </Stack>
                )}
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Issuance Method
                  </Typography>
                  <Typography
                    variant="bodyLargeSemibold"
                    color="#475467"
                    width="55%"
                  >
                    {selectedIssueType !== null && selectedIssueType}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Product
                  </Typography>
                  <Typography
                    variant="bodyLargeSemibold"
                    color="#475467"
                    width="55%"
                  >
                    {_.startCase(productName(data[0].product))}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Product Option
                  </Typography>
                  <Typography
                    variant="bodyLargeSemibold"
                    color="#475467"
                    width="55%"
                  >
                    {_.startCase(
                      `${productName(data[0].product)} ${productVariantName(data[0].product_variant)}`
                    )}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Unit
                  </Typography>
                  <Typography
                    variant="bodyLargeSemibold"
                    color="#475467"
                    width="55%"
                  >
                    {data[0].unit}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    Price
                  </Typography>
                  <Typography
                    variant="bodyLargeSemibold"
                    color="#475467"
                    width="55%"
                  >
                    {formattedNumber(data[0].amount, true) || '₦0'}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  width="inherit"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: '.063rem solid #FCFCFD',
                    padding: '1rem 0rem'
                  }}
                >
                  <Typography variant="bodyMediumMedium" color="#667085">
                    No of Product Receipts
                  </Typography>
                  <Typography
                    variant="bodyLargeSemibold"
                    color="#475467"
                    width="55%"
                  >
                    {data.length}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box
        padding="1rem .75rem"
        sx={{
          border: '0.063rem solid #F5F6F7',
          borderRadius: '.75rem',
          background: '#fff',
          width: '29rem',
          pb: '0rem'
        }}
      >
        <Box
          sx={{
            maxHeight: '25rem',
            overflowY: 'auto'
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            sx={{
              borderRadius: '0.5rem',
              border: '0.0625rem solid #F5F6F7',
              background: 'rgba(102, 112, 133, 0.02)',
              pb: '0rem',
              mb: '1rem'
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderBottom: '1px solid #F5F6F7',
                width: '100%',
                padding: '1rem .75rem'
              }}
            >
              <Typography variant="bodyMediumSemibold" color="#475467">
                Product Receipt {currentIndex + 1}
              </Typography>
            </Stack>

            <Stack
              direction="column"
              width="inherit"
              justifyContent="space-between"
              padding="0rem .75rem"
            >
              <Stack
                direction="row"
                width="inherit"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Quantity
                </Typography>
                <Typography
                  variant="bodyLargeSemibold"
                  color="#475467"
                  width="55%"
                >
                  {formattedNumber(prcs[currentIndex].quantity) || '---'}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                width="inherit"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Expiry Date
                </Typography>
                <Typography
                  variant="bodyLargeSemibold"
                  color="#475467"
                  width="55%"
                >
                  {formattedDate(
                    `${prcs[currentIndex].expiry_date}`,
                    'Do MMM, YYYY'
                  ) || '---'}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>

        <Stack
          display="flex"
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            padding: '.75rem 0rem',
            borderTop: '.063rem solid #F5F6F7'
          }}
        >
          <Typography
            variant="bodyMediumSemibold"
            color="#98A2B3"
            marginRight="1rem"
          >
            {currentIndex + 1}/{prcs.length}
          </Typography>

          <Box gap="1rem">
            {currentIndex !== 0 && (
              <MUIButton
                variant="outlined"
                sx={{
                  border: 'none',
                  stroke: '#3E7DF8',
                  '&:hover': {
                    border: 'none'
                  },
                  '&:active': {
                    border: 'none'
                  },
                  '&:disabled': {
                    border: 'none',
                    background: 'grey'
                  }
                }}
                onClick={() => {
                  setCurrentIndex(currentIndex - 1);
                }}
              >
                <Typography variant="bodyMediumSemibold" color="#3E7DF8">
                  Back
                </Typography>
              </MUIButton>
            )}

            <MUIButton
              variant="outlined"
              sx={{
                border: 'none',
                stroke: '#3E7DF8',
                '&:hover': {
                  border: 'none'
                },
                '&:active': {
                  border: 'none'
                },
                '&:disabled': {
                  border: 'none',
                  display: 'none'
                }
              }}
              endIcon={<ArrowLeft />}
              onClick={() => {
                setCurrentIndex(currentIndex + 1);
              }}
              disabled={prcs.length - 1 === currentIndex}
            >
              <Typography variant="bodyMediumSemibold" color="#3E7DF8">
                Next
              </Typography>
            </MUIButton>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default ReviewBatchProductReceipts;
