/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import * as am5 from '@amcharts/amcharts5';
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5geodata_nigeriaLow from '@amcharts/amcharts5-geodata/nigeriaLow';
import { IComponentDataItem } from '@amcharts/amcharts5/.internal/core/render/Component';

type MapProps = {
  mapData: { id: string; value: number; formatted_value: string }[];
  onClickState: (mapData: am5.DataItem<IComponentDataItem>) => void;
};

const MapChart = ({ mapData, onClickState }: MapProps) => {
  const mapChartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const root = am5.Root.new('mapchart');

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panY: 'none',
        panX: 'none',
        projection: am5map.geoMercator(),
        layout: root.horizontalLayout
      })
    );

    const polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        calculateAggregates: true,
        valueField: 'value',
        geoJSON: am5geodata_nigeriaLow
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: '{name}: {formatted_value}',
      interactive: true,
      stroke: am5.color(0xffffff),
      strokeWidth: 1,
      fill: am5.color('#ECF2FE')
    });

    polygonSeries.events.on('datavalidated', () => {
      polygonSeries.mapPolygons.each(polygon => {
        polygon.animate({
          key: 'opacity',
          to: 1,
          from: 0,
          duration: 1000,
          easing: am5.ease.out(am5.ease.cubic)
        });
        polygon.animate({
          key: 'scale',
          to: 1,
          from: 0.5,
          duration: 1000,
          easing: am5.ease.out(am5.ease.cubic)
        });
      });
    });

    let selectedPolygon: (am5.Sprite & am5map.MapPolygon) | null = null;

    polygonSeries.mapPolygons.template.events.on('click', function (ev) {
      const polygon = ev.target;

      if (polygon === selectedPolygon) {
        polygon.set('fill', (polygon as any).originalFill);
        selectedPolygon = null;
      } else {
        if (selectedPolygon) {
          selectedPolygon.set('fill', (selectedPolygon as any).originalFill);
        }

        (polygon as any).originalFill = polygon.get('fill');
        polygon.set('fill', am5.color('#F1A2A7'));
        selectedPolygon = polygon;

        ev.target.dataItem && onClickState(ev.target.dataItem);
      }
    });

    polygonSeries.data.setAll(mapData);

    polygonSeries.set('heatRules', [
      {
        target: polygonSeries.mapPolygons.template,
        dataField: 'value',
        min: am5.color('#A6C3FC'),
        max: am5.color('#6597F9'),
        key: 'fill'
      }
    ]);

    const legend = chart.children.push(
      am5.Legend.new(root, {
        x: am5.percent(100),
        centerX: am5.percent(100),
        y: am5.percent(100),
        centerY: am5.percent(100),
        nameField: 'name',
        fillField: 'color',
        strokeField: 'color',
        layout: root.verticalLayout
      })
    );

    legend.data.setAll([
      {
        name: 'Highest Volume',
        color: am5.color('#6597F9'),
        font: 8
      },
      {
        name: 'Lowest Volume',
        color: am5.color('#A6C3FC')
      }
    ]);

    legend.labels.template.setAll({
      fontSize: 10,
      fontWeight: '600',
      fill: am5.color('#98A2B3')
    });

    return () => root.dispose();
  }, [mapData]);

  return (
    <Box
      id="mapchart"
      style={{ width: '100%', height: 'inherit' }}
      ref={mapChartRef}
    />
  );
};

export default MapChart;
