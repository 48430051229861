import React, { useContext } from 'react';
import {
  Avatar,
  Stack,
  Toolbar,
  Typography,
  Button as MUIButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import chevronLeft from 'assets/svg/chevronLeft.svg';
import { Button } from 'components/button';
import {
  CreateProductReceiptContext,
  FormValues,
  ProductReceiptIssueType
} from '../context/createProductReceiptContext';
import ShowInfo from 'components/info/showInfo';
import {
  ProductReceiptData,
  useCreateProductReceiptMutation
} from 'services/api/productReceiptApi';
import { useGetDiscoverableProfilesQuery } from 'services/api/supplyChainApi';
import { removeItem } from 'utilities/helperFunc/localStore';

const CreateProductReceiptBottomNav = () => {
  const {
    activeStep,
    data,
    formState,
    steps,
    handleNext,
    handlePrev,
    selectedType,
    selectedIssueType
  } = useContext(CreateProductReceiptContext);
  const [createProductReceipt, { isLoading }] =
    useCreateProductReceiptMutation();
  const { data: bankData } = useGetDiscoverableProfilesQuery({
    params: { organization_type: 'lender' }
  });
  const { data: customerData } = useGetDiscoverableProfilesQuery({
    params: { organization_type: 'distributor,end_user' }
  });

  const navigate = useNavigate();

  const bankId = (bankName: string) => {
    if (bankData) {
      return (
        Object.values(bankData).filter(item => item.name === bankName)[0]
          .organization_id || bankName
      );
    }
  };

  const customerId = (customerName: string) => {
    if (customerData) {
      return (
        Object.values(customerData).filter(
          item => item.name === customerName
        )[0].organization_id || customerName
      );
    }
  };

  const onSubmit = async (entry: FormValues[]) => {
    const ProductReceiptData: ProductReceiptData[] = [];
    entry.forEach(entry => {
      ProductReceiptData.push({
        total_quantity: Number(entry.quantity),
        quoted_price_expiry: entry.expiry_date
      });
    });

    await createProductReceipt({
      product_variant: entry[0].product_variant,
      holders: [
        ...(selectedIssueType === ProductReceiptIssueType.INTERMEDIARY
          ? [bankId(entry[0].bank) || entry[0].bank]
          : []),
        customerId(entry[0].customer_name) || entry[0].customer_name
      ],
      product_receipt_data: ProductReceiptData
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Product Receipt Created',
          subText: 'Product Receipt has been added to your vault',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
        navigate('/product-receipts');
        removeItem('createProductReceiptFormData');
        removeItem('createProductReceiptStep');
        removeItem('createProductReceiptSelectedType');
        removeItem('createProductReceiptSelectedIssueType');
      })
      .catch(() => {
        ShowInfo({
          message: 'Product Receipt Created',
          subText: 'Product Receipt has not been created!',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  return (
    <Toolbar
      sx={{
        width: 'inherit',
        padding: '0.75rem 3rem',
        height: '100%',
        borderTop: '0.063rem solid #F5F6F7',
        background: '#fff'
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MUIButton
          variant="text"
          startIcon={
            <Avatar
              src={chevronLeft}
              sx={{ width: '1.25rem', height: '1.25rem' }}
            />
          }
          onClick={() => {
            navigate('/product-receipts');
            removeItem('createProductReceiptFormData');
            removeItem('createProductReceiptStep');
            removeItem('createProductReceiptSelectedType');
            removeItem('createProductReceiptSelectedIssueType');
          }}
        >
          <Typography variant="bodyMediumSemibold" color="#344054">
            Exit Product Receipt Creation
          </Typography>
        </MUIButton>

        <Stack direction="row" gap=".75rem">
          {activeStep !== 0 && (
            <Button
              color="grey"
              transparent
              size="md"
              text="Back"
              styleOverrides={{
                width: '10rem',
                padding: '.75rem',
                borderRadius: '.5rem'
              }}
              onClick={handlePrev}
            />
          )}

          <Button
            color="primary"
            size="md"
            text={
              activeStep === steps.length - 1
                ? 'Create Product Receipt'
                : activeStep === 2
                  ? 'Review'
                  : 'Next'
            }
            styleOverrides={{
              minWidth: '10rem',
              padding: '.75rem',
              borderRadius: '.5rem'
            }}
            onClick={() => {
              if (activeStep === steps.length - 1) {
                onSubmit(data);
              } else {
                handleNext();
              }
            }}
            disabled={
              (selectedType === null && activeStep === 0) ||
              (activeStep === 2 && formState) ||
              (selectedIssueType === null && activeStep === 1)
            }
            loadingIcon={isLoading}
          />
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default CreateProductReceiptBottomNav;
