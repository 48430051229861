import React, { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { ReactComponent as plusIcon } from 'assets/svg/plus.svg';

import { TabContainer } from 'components/tabs';
import { Button } from 'components/button';

import AddBranchModal from './branches/addBranchModal';
import BranchesTable from './branches/branchesTable';
import BranchesContextWrapper from './branches/branchesContext';

import CreateTeamModal from './teams/createTeamModal';
import TeamsTable from './teams/teamsTable';
import TeamsContextWrapper from './teams/teamsContext';
const tabs = [
  {
    labelText: 'Branches',
    content: <BranchesTable />
  },
  {
    labelText: 'Teams',
    content: <TeamsTable />
  }
];

const BranchesAndTeamsPage = () => {
  const [createTeamModalOpen, setCreateTeamModalOpen] =
    useState<boolean>(false);
  const [addBranchOpen, setAddBranchOpen] = useState<boolean>(false);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1.75rem'
        }}
      >
        <Stack gap=".25rem" width="inherit">
          <Typography
            variant="h6Bold"
            color="#475467"
            sx={{ width: '10.75rem' }}
          >
            Branches & Teams
          </Typography>
          <Typography
            variant="bodyMediumMedium"
            color="#98A2B3"
            sx={{ width: '23rem' }}
          >
            View all your teams and make changes and edits to them
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
          <Button
            color="grey"
            transparent
            size="sm"
            onClick={() => {
              setCreateTeamModalOpen(!createTeamModalOpen);
            }}
            icon={plusIcon}
            iconPosition="start"
            text="New Team"
            styleOverrides={{
              padding: '0.5rem 0.75rem',
              marginRight: '0.5rem'
            }}
          />
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setAddBranchOpen(!addBranchOpen);
            }}
            icon={plusIcon}
            iconPosition="start"
            text="New Branch"
            styleOverrides={{ padding: '0.5rem 0.75rem' }}
          />
        </Box>
      </Box>

      <TabContainer tabs={tabs} />
      <TeamsContextWrapper setCreateTeamModalOpen={setCreateTeamModalOpen}>
        <CreateTeamModal
          open={createTeamModalOpen}
          onClose={() => {
            setCreateTeamModalOpen(!createTeamModalOpen);
          }}
        />
      </TeamsContextWrapper>
      <BranchesContextWrapper setAddBranchOpen={setAddBranchOpen}>
        <AddBranchModal
          open={addBranchOpen}
          onClose={() => {
            setAddBranchOpen(!addBranchOpen);
          }}
        />
      </BranchesContextWrapper>
    </Box>
  );
};

export default BranchesAndTeamsPage;
