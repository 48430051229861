import React, { useEffect, useMemo } from 'react';
import {
  Box,
  Stack,
  Typography,
  Divider,
  Dialog,
  DialogContent
} from '@mui/material';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import { ReactComponent as Home } from 'assets/svg/home.svg';
import { Button } from 'components/button';
import { FormSelect, FormSelectOption } from 'components/form';
import { SvgWrapper } from 'components/svg';
import { getItem, setItem } from 'utilities/helperFunc/localStore';
import { useGetProductsQuery } from 'services/api/productApi';
import { useAppSelector } from 'services/hook';

type ChangeWorkflowOptionsModalProps = {
  open: boolean;
  onClose: () => void;
  merchantOptions: {
    title: string;
    value: string;
  }[];
};

type FormValues = {
  workflowUseCase: string;
  workflowType: 'standard' | 'custom' | null;
  merchant: string;
  product: string;
};

type MetaDataOptions = {
  merchant: string;
  product: string;
};

const ChangeWorkflowOptionsModal: React.FC<ChangeWorkflowOptionsModalProps> = ({
  open,
  onClose,
  merchantOptions
}) => {
  const { supplyChainProfileId, organizationType } = useAppSelector(
    state => state.auth
  );
  const metadataOptions: MetaDataOptions = getItem('customWorkflowOptions');
  const { control, watch } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      merchant: metadataOptions?.merchant,
      product: metadataOptions?.product
    }
  });
  const merchantValue = watch('merchant');
  const productValue = watch('product');

  const { data: productData } = useGetProductsQuery(
    organizationType === 'merchant' ? supplyChainProfileId : merchantValue
  );

  const productOptions = useMemo(() => {
    const options: FormSelectOption[] = [];
    productData?.forEach(product => {
      options.push({
        title: product.name,
        value: product.product_id
      });
    });
    return options;
  }, [productData]);

  useEffect(() => {
    setItem('customWorkflowOptions', {
      merchant: merchantValue,
      product: productValue
    });
  }, [merchantValue, productValue]);

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '27.75rem',
            borderRadius: '0.75rem',
            backgroundColor: '#FCFCFD'
          }
        }
      }}
      open={open}
      onClose={onClose}
    >
      <DialogContent
        sx={{ borderRadius: '.75rem .75rem 0rem 0rem', padding: 0 }}
      >
        <form noValidate>
          <Stack
            sx={{
              flexDirection: 'row',
              background: '#FCFCFD',
              padding: '1.25rem',
              pb: '1rem'
            }}
          >
            <Box display="flex" alignItems="flex-start">
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    borderRadius: '6.25rem',
                    border: 'none',
                    background: '#F8FBFE',
                    padding: '.5rem',
                    boxShadow: '0px 0px 0px 4px rgba(195, 215, 253, 0.20)',
                    marginBottom: '.75rem',
                    width: '2rem',
                    height: '2rem'
                  }}
                >
                  <SvgWrapper
                    icon={Home}
                    width="1rem"
                    height="1rem"
                    styleOverrides={{ fill: '#A6C3FC', stroke: 'none' }}
                  />
                </Box>
                <Typography
                  variant="h7Semibold"
                  color="#344054"
                  style={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    lineHeight: '1.375rem',
                    marginBottom: '.125rem'
                  }}
                >
                  Custom Workflow
                </Typography>
                <Typography
                  variant="bodyMediumRegular"
                  style={{
                    color: '#667085'
                  }}
                >
                  Select merchant and/or product for this workflow
                </Typography>
              </Stack>
            </Box>
          </Stack>

          <Divider />

          <Stack sx={{ margin: '2.25rem 1.25rem' }}>
            {metadataOptions?.merchant && (
              <FormSelect<FormValues>
                name="merchant"
                options={merchantOptions}
                label={'MERCHANT'}
                control={control}
                renderSelected={(value: string) => _.startCase(value)}
              />
            )}
            {metadataOptions?.product && (
              <FormSelect<FormValues>
                name="product"
                options={productOptions}
                label={'PRODUCT'}
                control={control}
                renderSelected={(value: string) => _.startCase(value)}
              />
            )}
          </Stack>

          <Divider />

          <Stack
            sx={{
              display: 'flex',
              padding: '1.25rem 1.5rem',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '.75rem',
              alignSelf: 'stretch',
              flexDirection: 'row'
            }}
          >
            <Button
              color="grey"
              transparent
              size="lg"
              text="Cancel"
              styleOverrides={{
                borderRadius: '.5rem',
                padding: '0.75rem',
                border: '.094rem solid #F2F4F7',
                color: '#475467',
                flex: '1 0 0'
              }}
              onClick={onClose}
            />

            <Button
              color="primary"
              size="lg"
              text="Change"
              styleOverrides={{
                borderRadius: '.5rem',
                padding: '0.75rem',
                flex: '1 0 0'
              }}
              onClick={() => {
                setItem('customWorkflowOptions', {
                  merchant: watch('merchant'),
                  product: watch('product')
                });
                onClose();
              }}
            />
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeWorkflowOptionsModal;
