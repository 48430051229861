import React, { useContext } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { ReactComponent as BankIcon } from 'assets/svg/bank.svg';
import { FormInput } from 'components/form';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';

import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { addBranchFormValidator } from '../../branchesValidators';
import { BranchesContext } from '../../branchesContext';

type FormValues = {
  name: string;
  street_address_1: string;
  street_address_2: string;
  state: string;
  locality: string;
  country: string;
};

const AddBranchStep = () => {
  const { data, handleNextStep, setAddBranchOpen } =
    useContext(BranchesContext);

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      name: data.name,
      street_address_1: data.street_address_1,
      street_address_2: data.street_address_2,
      country: data.country,
      state: data.state,
      locality: data.locality
    },
    resolver: yupResolver(
      addBranchFormValidator
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    handleNextStep(data);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1.25rem',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '.125rem solid #F5F6F7',
          width: '24.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2.25rem',
            width: '2.25rem',
            borderRadius: '6.25rem',
            backgroundColor: '#E5EEFF',
            padding: '0.5rem',
            boxShadow: '0px 0px 0px 4px rgba(200, 219, 255, 0.20)',
            marginBottom: '1rem'
          }}
        >
          <SvgWrapper
            icon={BankIcon}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              opacity: '0.8rem'
            }}
            color="#C1D6FF"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h7Semibold" color="#344054">
            Create Branch
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Streamline product receipt workflows with branches by assigning
            product receipts to created branches
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          margin: '1rem .75rem',
          padding: '1.25rem',
          overflowY: 'auto',
          backgroundColor: 'rgba(102, 112, 133, 0.02)',
          borderRadius: '0.5rem',
          border: '.063rem solid #F5F6F7',
          width: '24.5rem'
        }}
      >
        <FormInput<FormValues>
          control={control}
          name="name"
          label="NAME OF BRANCH"
        />
        <FormInput<FormValues>
          control={control}
          name="street_address_1"
          label="ADDRESS LINE 1"
        />
        <FormInput<FormValues>
          control={control}
          name="street_address_2"
          label="ADDRESS LINE 2 (OPTIONAL)"
        />
        <FormInput<FormValues>
          control={control}
          name="country"
          label="COUNTRY"
        />
        <Stack width="100%" gap=".75rem" direction="row">
          <FormInput<FormValues> control={control} name="state" label="STATE" />
          <FormInput<FormValues>
            control={control}
            name="locality"
            label="LOCALITY/AREA"
          />
        </Stack>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '.063rem solid #F5F6F7',
            padding: '1.25rem 1.5rem',
            marginBottom: '0.25rem'
          }}
        >
          <Button
            color="grey"
            transparent
            size="lg"
            onClick={() => {
              setAddBranchOpen(false);
            }}
            text="Back"
            width="48%"
          />
          <Button
            submit
            color="primary"
            size="lg"
            disabled={!isValid}
            text="Next"
            width="48%"
          />
        </Box>
      </form>
    </>
  );
};

export default AddBranchStep;
