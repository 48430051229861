import React, { useContext } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ProductApiData } from 'services/types/product';
import { formattedDate, formattedNumber } from 'utilities/helperFunc/formatter';
import {
  CreateProductReceiptContext,
  FormValues,
  ProductReceiptIssueType
} from '../../context/createProductReceiptContext';
import _ from 'lodash';

type ReviewSinglePRCProps = {
  productData: ProductApiData[] | undefined;
};

const ReviewSingleProductReceipts = ({ productData }: ReviewSinglePRCProps) => {
  const { data, selectedIssueType } = useContext(CreateProductReceiptContext);

  const productName = (product: FormValues['product']) => {
    const element = productData?.find(item => item.product_id === product);
    return element?.name !== undefined ? element.name : '---';
  };

  const productVariantName = (
    productVariant: FormValues['product_variant']
  ) => {
    const element = productData?.find(
      item => item.product_id === data[0].product
    );
    if (element && element.variants && element.variants.length > 0) {
      const foundVariant = element.variants.find(
        variant => variant.product_variant_id === productVariant
      );
      return foundVariant?.currency;
    }
    return '---';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'inherit',
        width: '29rem',
        margin: '0 auto',
        border: '0.063rem solid #F5F6F7',
        borderRadius: '.75rem',
        background: '#fff',
        marginTop: '1.5rem',
        mb: '1.5rem'
      }}
    >
      <Stack
        display="flex"
        flexDirection="column"
        sx={{
          padding: '1rem',
          paddingTop: '1.25rem',
          borderBottom: '.063rem solid #F5F6F7',
          gap: '.25rem'
        }}
      >
        <Typography variant="h6Bold" color="#475467">
          Review Details
        </Typography>
        <Typography variant="bodyMediumRegular" color="#667085">
          Ensure product receipt details are accurate
        </Typography>
      </Stack>

      <Box padding=".5rem 0rem">
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          padding=".5rem .75rem"
        >
          <Stack
            direction="column"
            width="inherit"
            justifyContent="space-between"
            padding="0rem .75rem"
          >
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Customer
              </Typography>
              <Typography
                variant="bodyLargeSemibold"
                color="#475467"
                width="55%"
              >
                {_.startCase(data[0].customer_name)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Issuance Method
              </Typography>
              <Typography
                variant="bodyLargeSemibold"
                color="#475467"
                width="55%"
              >
                {selectedIssueType !== null && selectedIssueType}
              </Typography>
            </Stack>
            {selectedIssueType === ProductReceiptIssueType.INTERMEDIARY && (
              <Stack
                direction="row"
                width="inherit"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #FCFCFD',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Bank
                </Typography>
                <Typography
                  variant="bodyLargeSemibold"
                  color="#475467"
                  width="55%"
                >
                  {_.startCase(data[0].bank)}
                </Typography>
              </Stack>
            )}
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Product
              </Typography>
              <Typography
                variant="bodyLargeSemibold"
                color="#475467"
                width="55%"
              >
                {_.startCase(`${productName(`${data[0].product}`)}`)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Product Option
              </Typography>
              <Typography
                variant="bodyLargeSemibold"
                color="#475467"
                width="55%"
              >
                {_.startCase(
                  `${productName(data[0].product)} ${productVariantName(data[0].product_variant)}`
                )}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Unit
              </Typography>
              <Typography
                variant="bodyLargeSemibold"
                color="#475467"
                width="55%"
              >
                {data[0].unit || '---'}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Price
              </Typography>
              <Typography
                variant="bodyLargeSemibold"
                color="#475467"
                width="55%"
              >
                {formattedNumber(data[0].amount, true) || '₦0'}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Quantity
              </Typography>
              <Typography
                variant="bodyLargeSemibold"
                color="#475467"
                width="55%"
              >
                {formattedNumber(data[0].quantity) || '---'}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              width="inherit"
              justifyContent="space-between"
              sx={{
                borderBottom: '.063rem solid #FCFCFD',
                padding: '1rem 0rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Expiry Date
              </Typography>
              <Typography
                variant="bodyLargeSemibold"
                color="#475467"
                width="55%"
              >
                {formattedDate(`${data[0].expiry_date}`, 'Do MMMM, YYYY') ||
                  '---'}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewSingleProductReceipts;
