import React, { useMemo, useState } from 'react';
import { Box, Paper, Stack, Typography, Checkbox } from '@mui/material';
import DateRangePickerComponent from 'components/date-range-picker/dateRangePicker';
import SearchableContainer from 'components/search/searchableContainer';
import {
  DiscoverableProfileApiData,
  useGetDiscoverableProfilesQuery
} from 'services/api/supplyChainApi';
import { OrganizationType } from 'services/enums/organization';
import { useAppSelector } from 'services/hook';
import OrgCard from './cards/historyOrgCard';
import ProductReceiptHistoryTable from './tables/productReceiptHistoryTable';
import { Checked, UnChecked } from 'components/form/formCheckBox';
import { formattedDate } from 'utilities/helperFunc/formatter';
import _ from 'lodash';
import { historyPageText } from 'copy/history';

interface DateRange {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string;
}

const ProductReceiptHistoryPage = () => {
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>(
    []
  );
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection'
  });
  const [onlyClosedPrcs, setOnlyClosedPrcs] = useState<boolean>(false);
  const { organizationType } = useAppSelector(state => state.auth);
  const {
    data: merchantData,
    isLoading,
    isFetching
  } = useGetDiscoverableProfilesQuery({
    params: {
      organization_type:
        organizationType === OrganizationType.MERCHANT
          ? 'distributor'
          : 'merchant'
    }
  });

  const orgData = useMemo(() => {
    const emptyMerchantData: DiscoverableProfileApiData[] = [];
    if (merchantData) {
      return Object.values(merchantData);
    }
    return emptyMerchantData;
  }, [merchantData]);

  const onSelectOrganization = (organization_id: string) => {
    if (selectedOrganizations.includes(organization_id)) {
      const newSelectedOrgs: string[] = [];
      selectedOrganizations.forEach(orgId => {
        if (orgId !== organization_id) {
          newSelectedOrgs.push(orgId);
        }
      });
      setSelectedOrganizations(newSelectedOrgs);
    } else {
      setSelectedOrganizations([...selectedOrganizations, organization_id]);
    }
  };

  const renderOrgData = (item: DiscoverableProfileApiData) => {
    return (
      <OrgCard
        name={_.startCase(item.name)}
        logo={item.logo}
        isActive={selectedOrganizations.includes(item.organization_id)}
        onClick={() => onSelectOrganization(item.organization_id)}
      />
    );
  };

  const handleClosedPrcsClick = () => {
    setOnlyClosedPrcs(previousValue => !previousValue);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: '#fff',
          borderBottom: '.063rem solid #F5F6F7',
          mb: '1rem',
          pb: '1.25rem'
        }}
      >
        <Stack width="inherit">
          <Typography variant="h7Bold" color="#475467">
            Product Receipt History
          </Typography>
          <Typography variant="bodyMediumMedium" color="#98A2B3">
            {historyPageText[organizationType]?.pageSubtitle}
          </Typography>
        </Stack>

        <DateRangePickerComponent
          dateRange={dateRange}
          setDateRange={setDateRange}
          btnText="Select date"
        />
      </Box>

      <Paper
        elevation={0}
        sx={{
          padding: '1.25rem',
          borderRadius: '0.75rem',
          backgroundColor: '#FFF',
          position: 'relative',
          height: '100%',
          border: '.0625rem solid #F5F6F7',
          mb: '1.3rem'
        }}
      >
        <SearchableContainer<DiscoverableProfileApiData>
          searchPlaceholderText={
            historyPageText[organizationType]?.searchPlaceholder ?? ''
          }
          data={orgData}
          renderDataList={renderOrgData}
          searchField={['name', 'organization_id']}
          containerStyles={{
            width: '100%',
            borderTop: 1,
            borderColor: '#F5F6F7'
          }}
          listStylesOverride={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: {
              md: '2rem 1.25rem',
              xl: '3rem'
            },
            padding: {
              md: '1.25rem 0.75rem',
              xl: '2rem 2.5rem'
            },
            overflowX: 'auto',
            borderRadius: '0.75rem',
            backgroundColor: '#FCFCFD',
            mt: '1.5rem'
          }}
          loading={isFetching || isLoading}
          enableEmptyData
          headerButtonPosition="end"
          headerButton={
            <Stack direction="row">
              <Box
                sx={{
                  ml: '0.75rem',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderRadius: '.75rem',
                  border: '.094rem solid #EAECF0',
                  padding: '.5rem .75rem',
                  cursor: 'pointer'
                }}
                onClick={handleClosedPrcsClick}
              >
                <Checkbox
                  size="medium"
                  sx={{
                    width: '1.5rem',
                    height: '1.5rem',
                    borderRadius: '0.25rem',
                    marginRight: '0.25rem'
                  }}
                  checkedIcon={<Checked />}
                  icon={<UnChecked />}
                  checked={onlyClosedPrcs}
                />
                <Typography variant="bodyMediumSemibold" color="#3872E2">
                  Closed Receipts Only
                </Typography>
              </Box>
            </Stack>
          }
        />
      </Paper>

      <ProductReceiptHistoryTable
        start={
          dateRange.startDate
            ? formattedDate(dateRange.startDate, 'YYYY-MM-DD')
            : undefined
        }
        end={
          dateRange.endDate
            ? formattedDate(dateRange.endDate, 'YYYY-MM-DD')
            : undefined
        }
        holders={
          organizationType === OrganizationType.MERCHANT
            ? selectedOrganizations
            : undefined
        }
        merchants={
          organizationType !== OrganizationType.MERCHANT
            ? selectedOrganizations
            : undefined
        }
        closed={onlyClosedPrcs ? 'True' : 'False'}
      />
    </Box>
  );
};

export default ProductReceiptHistoryPage;
