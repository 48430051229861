import React from 'react';
import { Box, Stack } from '@mui/material';
import ProfileSettings from './profileSettings';
import AddressSettings from './addressSettings';

const profilePage = () => {
  return (
    <Box width="inherit" sx={{ mt: '2rem' }}>
      <Stack width="100%" direction="row" gap="1.5rem">
        <ProfileSettings />
        <AddressSettings />
      </Stack>
    </Box>
  );
};

export default profilePage;
