import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as OrganizationIcon } from 'assets/svg/organization.svg';
import { ReactComponent as IdIcon } from 'assets/svg/id.svg';
import { ReactComponent as UsersIcon } from 'assets/svg/users.svg';
import { Button } from 'components/button';

import _ from 'lodash';
import { SvgWrapper } from 'components/svg';
interface PendingActionT {
  title: string;
  subtitle: string;
  status: string;
  link: string;
  buttonText: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
}

const pendingActionsData: PendingActionT[] = [
  {
    title: 'ID verification',
    subtitle:
      'Verify your identity to enable you have full access to the dashboard',
    status: 'completed',
    link: '',
    buttonText: '',
    icon: IdIcon
  },
  {
    title: 'Create branches',
    subtitle: 'Create branches that mirror your organizational structure',
    status: 'pending',
    link: '',
    buttonText: 'Create Branches',
    icon: UsersIcon
  },
  {
    title: 'Create teams',
    subtitle: 'Create teams and assign invited team members to teams',
    status: 'pending',
    link: '',
    buttonText: 'Create Team',
    icon: UsersIcon
  }
];

export const PendingActions = () => {
  const getStatusOrLink = (action: PendingActionT) => {
    if (action.status === 'completed') {
      return (
        <Box
          sx={{
            height: '2rem',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            color="success"
            size="sm"
            text={_.upperFirst(action.status)}
          />
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            height: '2rem',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button color="grey" transparent size="sm" text={action.buttonText} />
        </Box>
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginY: '1.75rem',
        border: '.063rem solid #F2F4F7',
        borderRadius: '.5rem',
        backgroundColor: '#FFF'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'stretch',
          width: '100%',
          height: '3.25rem',
          paddingY: '.375rem',
          paddingX: '.94rem',
          backgroundColor: '#FCFCFD',
          borderRadius: '.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: '.5rem',
            alignItems: 'flex-start',
            borderRadius: '.5rem',
            backgroundColor: '#F2F4F7',
            marginRight: '.375rem'
          }}
        >
          <SvgWrapper
            icon={OrganizationIcon}
            width="1rem"
            height="1rem"
            styleOverrides={{ fill: '#667085' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography variant="captionXSmall" component="div">
            Organization Setup
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#FFF',
            width: '7.5rem',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="bodyMediumSemibold"
            component="div"
            color={'#3E7DF8'}
          >
            1 completed
          </Typography>
          <Typography
            variant="bodyMediumSemibold"
            component="div"
            color={'#323232'}
          >
            &nbsp;/ 3
          </Typography>
        </Box>
      </Box>
      {pendingActionsData.map(action => (
        <Box
          key={`pending-action-${action.title}`}
          sx={{
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'stretch',
            width: '100%',
            height: '6.25rem',
            paddingY: '1.75rem',
            paddingX: '.94rem',
            border: '0.031rem #F2F4F7',
            borderStyle: 'solid none',
            '&:last-child': {
              borderStyle: 'solid none none none'
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: '.5rem',
              alignItems: 'flex-start',
              borderRadius: '.5rem',
              backgroundColor: '#F2F4F7',
              border: '.063rem solid #F2F4F7',
              marginRight: '.375rem'
            }}
          >
            <SvgWrapper
              icon={action.icon}
              width="1rem"
              height="1rem"
              styleOverrides={{ fill: '#98A2B3' }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center'
            }}
          >
            <Typography
              variant="bodyMediumSemibold"
              component="div"
              color={'#475467'}
            >
              {action.title}
            </Typography>
            <Typography
              variant="bodyMediumMedium"
              component="div"
              color={'#98A2B3'}
            >
              {action.subtitle}
            </Typography>
          </Box>
          {getStatusOrLink(action)}
        </Box>
      ))}
    </Box>
  );
};
