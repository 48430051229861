import { createBrowserRouter } from 'react-router-dom';
import { getRoutesForReactRouter } from './siteMaps';
import { Auth } from 'services/slices/authSlice';
import { ConfigState } from 'services/slices/config';
export { getRoutesForNav } from './siteMaps';

const getRouter = (authState: Auth, flags: ConfigState) => {
  return createBrowserRouter(getRoutesForReactRouter(authState, flags));
};

export default getRouter;
