import React from 'react';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions, TooltipItem } from 'chart.js';
import { Box, Typography } from '@mui/material';
import { colorTheme } from 'themes/colors';
import {
  formatLargeAmount,
  formattedDate,
  formattedNumber
} from 'utilities/helperFunc/formatter';

interface LineChartProps {
  labels: string[];
  datasets: {
    data: number[];
    label: string;
    backgroundColor: string;
    borderColor: string;
  }[];
}

const getLineChartColors = () => ({
  backgroundColor: colorTheme.grey200,
  borderColor: [
    colorTheme.warning200,
    colorTheme.primary400,
    colorTheme.primary100
  ]
});

const LineChart: React.FC<LineChartProps> = ({ labels, datasets }) => {
  const { backgroundColor, borderColor } = getLineChartColors();

  const chartData: ChartData<'line'> = {
    labels,
    datasets: datasets.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data,
      backgroundColor: dataset.backgroundColor || backgroundColor,
      borderColor:
        dataset.borderColor || borderColor[index % borderColor.length],
      borderWidth: 2,
      tension: 0.5,
      pointStyle: 'circle',
      pointRadius: 0.5,
      fill: false
    }))
  };

  const lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false
    },
    elements: {
      line: { tension: 0.4 },
      point: {
        radius: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        hoverBackgroundColor: colorTheme.primary400,
        hoverRadius: 2
      }
    },
    scales: {
      x: {
        beginAtZero: false,
        grid: { display: false },
        border: {
          display: true,
          color: colorTheme.grey200
        },
        ticks: {
          callback: function (value, index) {
            return formattedDate(labels[index], 'MMM D');
          }
        }
      },
      y: {
        beginAtZero: false,
        grid: {
          drawTicks: true,
          tickLength: 10,
          tickWidth: 0,
          tickColor: '#667085'
        },
        border: {
          dash: [6],
          display: false,
          color: colorTheme.grey200
        },
        ticks: {
          callback: function (value) {
            return formatLargeAmount(value, true, true);
          }
        }
      }
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        displayColors: false,
        footerAlign: 'center',
        titleAlign: 'center',
        titleFont: {
          weight: 500
        },
        callbacks: {
          title: (tooltipItems: TooltipItem<'line'>[]) => {
            let sum = 0;
            tooltipItems.forEach(tooltipItem => {
              sum += tooltipItem.parsed.y;
            });
            return formattedNumber(sum, true);
          }
        }
      }
    }
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '80%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        {datasets.map((dataset, index) => (
          <Box
            key={index}
            sx={{ display: 'flex', alignItems: 'center', mr: 3, mb: 1 }}
          >
            <Box
              sx={{
                display: 'inline-block',
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor:
                  dataset.borderColor ||
                  borderColor[index % borderColor.length],
                mr: 1
              }}
            />
            <Typography variant="bodyMediumRegular">{dataset.label}</Typography>
          </Box>
        ))}
      </Box>
      <Line data={chartData} options={lineChartOptions} />
    </Box>
  );
};

export default LineChart;
