import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  Typography,
  Chip as MUIChip,
  Box,
  Divider
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ReactComponent as NotificationsIcon } from 'assets/svg/bell.svg';
import { SvgWrapper } from 'components/svg';
import NotificationsDrawer from 'pages/notifications/notificationsDrawer';
import { NavRouteProps } from './types';

const NavRoutes = ({ routes }: NavRouteProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <Box>
      <List
        sx={{
          width: 'inherit',
          padding: {
            md: '0rem'
          }
        }}
      >
        {routes.map((route, index) => (
          <React.Fragment key={route.link}>
            {route.position === 'top' && (
              <NavLink
                to={route.link}
                style={{ textDecoration: 'none' }}
                key={`nav-route-${index}`}
              >
                {({ isActive }) => (
                  <ListItem
                    sx={{
                      width: 'inherit',
                      backgroundColor: isActive ? '#F8FBFE' : '#FCFCFC'
                    }}
                    disablePadding
                  >
                    <ListItemButton
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.75rem',
                        padding: '0.75rem 0.5rem',
                        borderRadius: '0.5rem'
                      }}
                      disableRipple
                    >
                      <SvgWrapper
                        icon={route.icon}
                        width="1.25rem"
                        height="1.25rem"
                        styleOverrides={{
                          fill: isActive ? '#3E7DF8' : '#CDD1DA'
                        }}
                      />
                      <Typography
                        variant={'bodyMediumMedium'}
                        sx={{ color: isActive ? '#3E7DF8' : '#667085' }}
                      >
                        {route.text}
                      </Typography>
                      {route.showChip && (
                        <MUIChip
                          label="3"
                          size="small"
                          sx={{
                            backgroundColor: '#F4F9FF',
                            marginLeft: 'auto'
                          }}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                )}
              </NavLink>
            )}
          </React.Fragment>
        ))}
      </List>

      <Divider
        sx={{
          color: '#F5F6F7',
          height: '.0625rem',
          width: 'inherit',
          opacity: 0.4,
          marginY: '0.75rem'
        }}
      />

      <List
        sx={{
          padding: {
            md: '0rem'
          },
          '&:hover': {
            backgroundColor: '#F8FBFE'
          }
        }}
      >
        <ListItem
          sx={{
            width: 'inherit',
            backgroundColor: '#FCFCFC'
          }}
          disablePadding
        >
          <ListItemButton
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.75rem',
              padding: '0.75rem 0.5rem',
              borderRadius: '0.5rem'
            }}
            onClick={() => setIsDrawerOpen(true)}
            disableRipple
          >
            <SvgWrapper
              icon={NotificationsIcon}
              width="1.25rem"
              height="1.25rem"
              styleOverrides={{
                fill: '#CDD1DA'
              }}
            />
            <Typography variant="bodyMediumMedium" sx={{ color: '#667085' }}>
              Notifications
            </Typography>
          </ListItemButton>
        </ListItem>

        {routes.map((route, index) => {
          if (route.position === 'bottom') {
            return (
              <NavLink
                end={true}
                to={route.link}
                key={`sidebar-li-1-${index}`}
                style={{ textDecoration: 'none', transition: '0s' }}
              >
                {({ isActive }) => (
                  <ListItem
                    sx={{
                      width: 'inherit',
                      backgroundColor: isActive ? '#F8FBFE' : '#FCFCFC'
                    }}
                    disablePadding
                  >
                    <ListItemButton
                      sx={{
                        display: 'flex',
                        padding: '0.75rem 1.5rem 0.75rem 0.75rem',
                        alignItems: 'center',
                        height: '2.75rem',
                        gap: '0.75rem',
                        borderRadius: '.5rem'
                      }}
                      disableRipple
                    >
                      <SvgWrapper
                        icon={route.icon}
                        width="1.25rem"
                        height="1.25rem"
                        styleOverrides={{
                          fill: isActive ? '#3E7DF8' : '#CDD1DA'
                        }}
                      />
                      <Typography
                        variant={'bodyMediumMedium'}
                        sx={{ color: isActive ? '#3E7DF8' : '#667085' }}
                      >
                        {route.text}
                      </Typography>
                      {route.showDot ? (
                        <Box
                          sx={{
                            width: '0.5rem',
                            height: '0.5rem',
                            borderRadius: '0.5rem',
                            border: '.094rem solid #FFF',
                            backgroundColor: '#1CA477',
                            marginLeft: 'auto'
                          }}
                        />
                      ) : null}
                    </ListItemButton>
                  </ListItem>
                )}
              </NavLink>
            );
          }
        })}
      </List>

      <NotificationsDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </Box>
  );
};
export default NavRoutes;
