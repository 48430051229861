import { OrganizationType } from 'services/enums/organization';

export const cardText: {
  [key in OrganizationType]?: { title: string; tooltipText: string }[];
} = {
  merchant: [
    {
      title: 'Total Product Receipt Value',
      tooltipText: 'The total monetary value of all product receipts issued.'
    },
    {
      title: 'Product Receipts Issued',
      tooltipText:
        'The number of product receipts generated and issued to date.'
    },
    {
      title: 'Pickup Rate',
      tooltipText:
        'Percentage of issued product receipts that have been picked up by distributors.'
    }
  ],
  lender: [
    {
      title: 'Total Product Receipt Value',
      tooltipText: 'The total monetary value of all product receipts received.'
    },
    {
      title: 'Product Receipts Received',
      tooltipText: 'The number of product receipts received from merchants.'
    },
    {
      title: 'Pickup Rate',
      tooltipText:
        'Percentage of received product receipts that have been picked up by distributors.'
    }
  ],
  distributor: [
    {
      title: 'Total Product Receipt Value',
      tooltipText: 'The total monetary value of all product receipts issued.'
    },
    {
      title: 'Product Receipts Received',
      tooltipText:
        'The total number of product receipts received from merchants.'
    },
    {
      title: 'Pickup Rate',
      tooltipText:
        'Percentage of issued product receipts that have been picked up.'
    }
  ]
};
