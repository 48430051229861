import React, { useState } from 'react';
import {
  Popover,
  Button as MUIButton,
  Typography,
  styled
} from '@mui/material';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { ReactComponent as CalendarIcon } from 'assets/svg/calendar-minus.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/x.svg';
import { colorTheme } from 'themes/colors';
import { formattedDate } from 'utilities/helperFunc/formatter';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateRange {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string; // this property provides a way to uniquely identify and manage that specific range in the array
}

type DateRangePickerTypes = {
  dateRange: DateRange;
  defaultDateRange?: DateRange;
  setDateRange: React.Dispatch<React.SetStateAction<DateRange>>;
  btnText: string;
  preferredEndIcon?: React.ReactNode;
  hideStartIcon?: boolean;
};

const StyledCalendar = styled(DateRangePicker)<{ isRangeSelected: boolean }>`
  .rdrDay {
    color: #101828;
    border-radius: 0.625rem;
    border: 0.06rem solid #f5f6f7;
    margin: 0.2rem;
    width: 2.3rem;
    height: 2.3rem;
  }
  .rdrDay:hover {
    background-color: #ecf2fe;
    border-radius: 0.625rem;
    border: 0.06rem solid #c3d7fd;
    padding: 0.5rem;
  }
  .rdrDayActive {
    background-color: #ecf2fe;
    padding: 0.5rem;
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 0.625rem;
  }
  .rdrDateDisplayWrapper {
    background-color: transparent;
  }
  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: #1d2429;
  }
  .rdrInRange:not(.rdrStartEdge):not(.rdrEndEdge) {
    background-color: ${({ isRangeSelected }) =>
      isRangeSelected ? '#ecf2fe' : 'transparent'};
    border: ${({ isRangeSelected }) =>
      isRangeSelected ? '0.06rem solid #c3d7fd' : 'none'};
    border-radius: 0.625rem;
    height: inherit;
    top: 0rem;
    bottom: 0rem;
  }
  .rdrInRange:hover {
    background-color: red;
    border: none;
    height: inherit;
    top: 0rem;
    bottom: 0rem;
  }
  .rdrEndEdge {
    background-color: #ecf2fe;
    border: 0.06rem solid #c3d7fd;
    border-radius: 0.625rem;
    right: 0rem;
    top: 0rem;
    bottom: 0rem;
    height: inherit;
  }
  .rdrStartEdge {
    background-color: #ecf2fe;
    border: 0.06rem solid #c3d7fd;
    border-radius: 0.625rem;
    left: 0rem;
    top: 0rem;
    bottom: 0rem;
    height: inherit;
  }
  .rdrDayNumber span {
    color: #1d2429 !important;
  }
  .rdrDayEndOfWeek .rdrInRange {
    right: 0rem;
  }
  .rdrDayStartOfWeek .rdrInRange {
    left: 0rem;
  }
  .rdrDayStartOfWeek .rdrEndEdge {
    left: 0rem;
  }
  .rdrDayStartOfWeek .rdrStartEdge {
    right: 0rem;
  }
`;

const DateRangePickerComponent = ({
  dateRange,
  defaultDateRange,
  setDateRange,
  hideStartIcon,
  preferredEndIcon = <></>,
  btnText
}: DateRangePickerTypes) => {
  const isRangeSelected = !!dateRange.startDate || !!dateRange.endDate;
  const [dateRangeAnchorEl, setDateRangeAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [clickedStartDate, setClickedStartDate] = useState<boolean>(false);

  const handleSelect = (ranges: RangeKeyDict) => {
    const { selection } = ranges;

    setDateRange({
      startDate: selection.startDate,
      endDate: selection.endDate,
      key: selection.key ? selection.key : ''
    });
    if (clickedStartDate) {
      setDateRangeAnchorEl(null);
      setClickedStartDate(false);
    } else if (
      selection.startDate &&
      selection.endDate &&
      selection.startDate.getTime() !== selection.endDate.getTime()
    ) {
      setDateRangeAnchorEl(null);
      setClickedStartDate(false);
    } else {
      setClickedStartDate(true);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDateRangeAnchorEl(event.currentTarget);
  };

  const handleCancelClick = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setDateRange(
      defaultDateRange || {
        startDate: undefined,
        endDate: undefined,
        key: 'selection'
      }
    );
    setClickedStartDate(false);
  };

  return (
    <>
      <MUIButton
        variant="outlined"
        onClick={e => handleClick(e)}
        startIcon={
          hideStartIcon ? undefined : (
            <CalendarIcon style={{ stroke: '#475467' }} />
          )
        }
        endIcon={
          dateRange.startDate && dateRange.endDate ? (
            <CloseIcon onClick={e => handleCancelClick(e)} />
          ) : (
            preferredEndIcon
          )
        }
        sx={{
          '&.MuiButton-outlined': {
            border: '.094rem solid #F2F4F7',
            borderRadius: '.5rem',
            color: '#fff',
            maxWidth: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '.75rem',
            height: '2.75rem',
            boxShadow: '0px 1.5px 4px -1px rgba(182, 182, 182, 0.07)',
            '& .MuiButton-endIcon': {
              ml: 0
            },
            '&:hover': {
              background: colorTheme['grey50']
            }
          }
        }}
      >
        <Typography variant="bodyMediumSemibold" color="#475467">
          {dateRange.startDate && dateRange.endDate
            ? `${formattedDate(dateRange.startDate.toDateString(), 'MMM Do, YYYY')} - ${formattedDate(dateRange.endDate.toDateString(), 'MMM Do, YYYY')}`
            : btnText}
        </Typography>
      </MUIButton>

      <Popover
        open={Boolean(dateRangeAnchorEl)}
        anchorEl={dateRangeAnchorEl}
        onClose={() =>
          dateRange.startDate && dateRange.endDate && setDateRangeAnchorEl(null)
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        slotProps={{
          paper: {
            sx: { boxShadow: '0rem 4px 4px 0rem rgba(158, 158, 158, 0.10)' }
          }
        }}
      >
        <StyledCalendar
          isRangeSelected={isRangeSelected}
          ranges={[dateRange]}
          onChange={handleSelect}
          maxDate={new Date(2025, 0, 1)}
          moveRangeOnFirstSelection={false}
          startDatePlaceholder="Start date"
          endDatePlaceholder="End date"
          rangeColors={['transparent', 'none', 'none']}
          minDate={new Date(2024, 0, 1)}
          weekStartsOn={1}
          showDateDisplay={false}
        />
      </Popover>
    </>
  );
};

export default DateRangePickerComponent;
