import React from 'react';

import { Box, Paper, Typography } from '@mui/material';
import { theme } from 'themes/theme';
import NotificationGroup from './notificationGroup';
import NotificationsSettings from './notificationSettings';

const NotificationsPage = () => {
  return (
    <Box width="inherit" sx={{ mt: '2rem' }}>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: `${theme.palette.common.white}`,
          border: `0.063rem solid ${theme.palette.common.cardBorder}`,
          borderRadius: '0.75rem',
          gap: '1.5rem',
          padding: '1.5rem',
          width: '100%'
        }}
      >
        <Box gap="2.1875rem">
          <Box
            width="inherit"
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              mb: '0.25rem',
              flexDirection: 'column',
              padding: '0.75rem 0rem',
              borderBottom: '0.125rem solid #F5F6F7'
            }}
          >
            <Typography
              variant="bodyLargeSemibold"
              color={`${theme.palette.common.heading}`}
            >
              Notification Settings
            </Typography>
            <Typography
              variant="bodyMediumRegular"
              color={`${theme.palette.common.labelColor}`}
            >
              Get notified on status and progress of core activities on your
              dashboard
            </Typography>
          </Box>

          <Box padding="1.6875rem 0rem">
            <NotificationGroup
              label="General"
              description="All notifications on your flux dashboard pertaining to important
                activities"
            >
              <NotificationsSettings
                label="New User joined"
                description="Notifications for all new product receipts received from merchants"
              />
              <NotificationsSettings
                label="Bulkagent applications"
                description="Notifications for all new product receipts received from merchants"
              />
            </NotificationGroup>

            <NotificationGroup
              label="Workflows"
              description="All comments and updates pertaining to workflows"
            >
              <NotificationsSettings
                label="Workflow updates"
                description="Notifications for all new product receipts received from merchants"
              />
              <NotificationsSettings
                label="Workflow creation"
                description="Notifications for all new product receipts received from merchants"
              />
            </NotificationGroup>

            <NotificationGroup
              label="Product receipts"
              description="Stay on top of all product receipt related activities"
            >
              <NotificationsSettings
                label="New Product receipt Received"
                description="Notifications for all new product receipts received from merchants"
              />
              <NotificationsSettings
                label="Product receipts endorsed"
                description="Notifications for all new product receipts received from merchants"
              />
            </NotificationGroup>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default NotificationsPage;
