import React, { useContext, useEffect, useMemo } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as InfoIcon } from 'assets/svg/infoPrimary.svg';
import {
  FormAutocomplete,
  FormDateInput,
  FormInput,
  FormSelect,
  FormSelectOption
} from 'components/form';
import { SvgWrapper } from 'components/svg';
import { ProductApiData, ProductApiVariantsData } from 'services/types/product';
import {
  CreateProductReceiptContext,
  FormValues,
  ProductReceiptIssueType,
  RequestDetails
} from '../../context/createProductReceiptContext';
import { createProductReceiptValidator } from '../../validator/createProductReceiptValidator';

type SinglePRCProps = {
  request: RequestDetails | null;
  bankOptions: {
    title: string;
    value: string;
  }[];
  customerOptions: {
    title: string;
    value: string;
  }[];
  productData: ProductApiData[] | undefined;
};

const SingleProductReceiptDetails = ({
  request,
  bankOptions,
  customerOptions,
  productData
}: SinglePRCProps) => {
  const { data, setData, setFormState, selectedIssueType } = useContext(
    CreateProductReceiptContext
  );

  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { isValid, touchedFields }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      customer_name: data[0].customer_name,
      bank: data[0].bank,
      product: data[0].product,
      product_variant: data[0].product_variant,
      unit: data[0].unit,
      quantity: data[0].quantity,
      amount: data[0].amount,
      expiry_date: data[0].expiry_date
    },
    resolver: yupResolver(
      createProductReceiptValidator(selectedIssueType)
    ) as unknown as Resolver<FormValues>
  });

  const values = watch();
  const selectedProduct = watch('product');
  const selectedProductVariant = watch('product_variant');

  const selectedProductObj = productData?.find(
    product => product.product_id === selectedProduct
  );

  const selectedVariantObj = selectedProductObj?.variants?.find(
    (variant: ProductApiVariantsData) =>
      variant.product_variant_id === selectedProductVariant
  );

  const productOptions = useMemo(() => {
    const options: FormSelectOption[] = [];
    productData?.forEach(product => {
      options.push({
        title: product.name,
        value: product.product_id
      });
    });
    return options;
  }, [productData]);

  const productVariantOptions = useMemo(() => {
    const options: FormSelectOption[] = [];
    if (selectedProductObj) {
      if (
        selectedProductObj.variants &&
        selectedProductObj.variants.length > 0
      ) {
        selectedProductObj.variants.forEach(variant => {
          options.push({
            title: `${selectedProductObj.name} ${variant.currency}`,
            value: variant.product_variant_id
          });
        });
      }
    }
    return options;
  }, [selectedProductObj]);

  useEffect(() => {
    if (selectedProductObj) {
      setValue('unit', selectedVariantObj?.unit || '');
      setValue('amount', selectedVariantObj?.price || '');
    }
    if (!selectedProductVariant) {
      if (touchedFields.unit || touchedFields.amount) {
        setError('unit', {
          type: 'manual',
          message: 'Please select a product option first.'
        });
        setError('amount', {
          type: 'manual',
          message: 'Please select a product option first.'
        });
      }
    } else {
      clearErrors('unit');
      clearErrors('amount');
    }
  }, [selectedProductVariant, selectedProductObj]);

  useEffect(() => {
    setData([
      {
        customer_name: values.customer_name,
        bank: values.bank,
        product: values.product,
        product_variant: values.product_variant,
        quantity: values.quantity.replace(/,/g, ''),
        unit: values.unit,
        amount: values.amount.replace(/[,₦]/g, ''),
        expiry_date: values.expiry_date
      }
    ]);
    setFormState(!isValid);
  }, [values]);

  useEffect(() => {
    if (touchedFields.product_variant) {
      trigger('product_variant');
    }
  }, [selectedProduct, trigger, touchedFields.product_variant]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 'inherit',
        width: '29rem',
        margin: '0 auto',
        border: '0.063rem solid #F5F6F7',
        borderRadius: '.75rem',
        background: '#fff',
        marginTop: '1.5rem',
        mb: '1.5rem'
      }}
    >
      <Stack
        display="flex"
        flexDirection="column"
        sx={{
          padding: '1rem',
          paddingTop: '1.25rem',
          borderBottom: '.063rem solid #F5F6F7',
          gap: '.25rem'
        }}
      >
        <Typography variant="h6Bold" color="#475467">
          Single Product Receipt
        </Typography>
        <Typography variant="bodyMediumRegular" color="#667085">
          Enter details
        </Typography>
      </Stack>

      <Box padding=".75rem">
        {request !== null && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap=".75rem"
            sx={{
              borderRadius: '0.5rem',
              border: '.063rem solid #F2F4F7',
              mb: '1rem',
              padding: '.75rem 0rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '1.5rem',
                width: '1.5rem',
                borderRadius: '.5rem',
                backgroundColor: '#ECF2FE',
                padding: '0.375rem'
              }}
            >
              <SvgWrapper
                icon={InfoIcon}
                width=".75rem"
                height=".75rem"
                color="#3E7DF8"
              />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Typography variant="bodySmallSemibold" color="#344054">
                This product receipt is created by request
              </Typography>
              <Typography variant="bodySmallSemibold" color="#667085">
                Product Receipt was requested by Globus bank on behalf of
                Hamalaya Group
              </Typography>
            </Box>
          </Stack>
        )}

        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          sx={{
            borderRadius: '0.5rem',
            border: '0.0625rem solid #F5F6F7',
            background: 'rgba(102, 112, 133, 0.02)',
            pb: '0rem',
            maxHeight: {
              md: '28rem',
              lg: '35rem',
              xl: 'fit-content'
            },
            overflow: 'auto'
          }}
        >
          <Stack direction="column" width="inherit">
            <Stack direction="column" padding=".75rem" pb="0rem">
              <FormAutocomplete<FormValues>
                control={control}
                multiple={false}
                name="customer_name"
                label={
                  selectedIssueType === ProductReceiptIssueType.INTERMEDIARY
                    ? 'CUSTOMER'
                    : 'DISTRIBUTOR'
                }
                options={customerOptions.map(option => option.title)}
              />

              {selectedIssueType === ProductReceiptIssueType.INTERMEDIARY && (
                <FormAutocomplete<FormValues>
                  control={control}
                  multiple={false}
                  name="bank"
                  label="BANK"
                  options={bankOptions.map(option => option.title)}
                />
              )}

              <FormDateInput<FormValues>
                control={control}
                name="expiry_date"
                label="EXPIRY DATE"
                format="YYYY-MM-DD"
                disablePast
              />
            </Stack>

            <Box padding=".75rem 1.25rem" pt="0rem" width="inherit">
              <Typography variant="bodyLargeSemibold" color="#667085">
                Product Information
              </Typography>
            </Box>

            <Divider
              sx={{
                color: '#F2F4F7',
                border: '.06rem solid',
                mb: '1.5rem'
              }}
            />

            <Stack padding="0rem .75rem">
              <FormSelect<FormValues>
                name="product"
                options={productOptions}
                label="PRODUCT"
                control={control}
                renderSelected={(value: string) => value}
              />

              <FormSelect<FormValues>
                name="product_variant"
                options={productVariantOptions}
                label="PRODUCT OPTION"
                control={control}
                renderSelected={(value: string) => value}
              />

              <Stack direction="row" gap=".75rem">
                <FormInput<FormValues>
                  control={control}
                  name="unit"
                  label="UNIT"
                  readonly={true}
                />
                <FormInput<FormValues>
                  control={control}
                  name="amount"
                  label="PRICE"
                  readonly={true}
                />
              </Stack>

              <FormInput<FormValues>
                control={control}
                name="quantity"
                label="QUANTITY"
              />
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleProductReceiptDetails;
