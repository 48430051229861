import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import React, { useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Tabs as MUITabs,
  Tab as MUITab
} from '@mui/material';
import AnalyticsPage from './analytics/analyticsPage';
import DateRangePickerComponent from 'components/date-range-picker/dateRangePicker';
import ReportsPage from './reports/reportsPage';
import _ from 'lodash';

interface DateRange {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key: string;
}

const tabList = ['analytics', 'reports'];

const AnalyticsAndReportsPage = () => {
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection'
  });
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const isActiveTab = (tabId: string) => {
    return tabId === tabList[currentTabIndex];
  };

  const handleChange = (newTabIndex: number) => {
    setCurrentTabIndex(newTabIndex);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '1rem'
        }}
      >
        <Stack direction="column" gap={0.5}>
          <Typography variant="h6Bold" color="#475467">
            Analytics & Reports
          </Typography>
          <Typography variant="bodyMediumMedium" color="#98A2B3">
            See data analytics for all key actions
          </Typography>
        </Stack>

        {currentTabIndex === 1 && (
          <DateRangePickerComponent
            dateRange={dateRange}
            setDateRange={setDateRange}
            btnText="This Month"
            preferredEndIcon={
              <ChevronDown
                style={{
                  fill: '#fff',
                  stroke: '#475467',
                  width: '1rem',
                  height: '1rem',
                  marginLeft: '.25rem'
                }}
              />
            }
          />
        )}
      </Box>

      <MUITabs
        orientation="horizontal"
        value={Math.max(0, currentTabIndex)}
        onChange={(e, value) => handleChange(value)}
        sx={{
          borderBottom: 1,
          borderColor: '#F5F6F7',
          marginBottom: '1.75rem',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        {tabList.map(tabId => (
          <MUITab
            key={tabId}
            label={
              <Typography variant="bodyMediumMedium">
                {_.startCase(tabId.replace('-', ' '))}
              </Typography>
            }
          />
        ))}
      </MUITabs>

      <Box
        role="tabpanel"
        hidden={!isActiveTab('analytics')}
        sx={{ width: '100%' }}
      >
        <AnalyticsPage />
      </Box>
      <Box
        role="tabpanel"
        hidden={!isActiveTab('reports')}
        sx={{ width: '100%' }}
      >
        <ReportsPage
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
        />
      </Box>
    </Box>
  );
};

export default AnalyticsAndReportsPage;
