import React from 'react';
import { Box, Link, Paper, Stack, Typography } from '@mui/material';
import ellipse from 'assets/svg/ellipse.svg';
import { ReactComponent as FileType } from 'assets/svg/file type.svg';
import { ReactComponent as Download } from 'assets/svg/download1.svg';
import { ReactComponent as Trash } from 'assets/svg/trashSolid.svg';
import { SvgWrapper } from 'components/svg';
import { formattedDate } from 'utilities/helperFunc/formatter';
import { millisecsToMinsHr } from 'utilities/helperFunc/timeDifference';
import _ from 'lodash';

interface ReportsDocumentCardData {
  file: string;
  name: string;
  status: string;
  generated_by: string;
  generated_on: string;
  generation_time: number;
  onDelete: (name: string) => void;
  isLast?: boolean;
  isFirst?: boolean;
}

const ReportsDocumentCard = ({
  file,
  name,
  generated_by,
  generated_on,
  generation_time,
  onDelete,
  isLast,
  isFirst,
  status
}: ReportsDocumentCardData) => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: '1rem 1.5rem',
        width: '100%',
        height: '5.5rem',
        borderRadius: '0.75rem',
        border: '.0625rem solid #F2F4F7',
        mt: isFirst ? '.75rem' : '0rem',
        mb: isLast ? '.75rem' : '0rem'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="1rem"
        sx={{
          height: '100%',
          width: '100%'
        }}
      >
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            width: {
              md: '95%',
              lg: '95%%'
            }
          }}
        >
          <Box>
            <SvgWrapper
              icon={FileType}
              width="2.5rem"
              height="2.5rem"
              styleOverrides={{ fill: '#D0D5DD' }}
            />
          </Box>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              ml: {
                md: '.5rem',
                lg: '1rem'
              }
            }}
          >
            <Typography
              variant="bodyMediumSemibold"
              color="#1D2939"
              sx={{
                width: '100%',
                mb: '.25rem'
              }}
            >
              {_.startCase(name)}
            </Typography>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: '100%'
              }}
            >
              <Box display="flex" flexDirection="row" gap={0.5}>
                <Typography variant="bodyMediumRegular" color="#98A2B3">
                  {status !== 'completed' ? 'Initiated by' : 'Generated by'}
                </Typography>
                <Typography variant="bodyMediumMedium" color="#98A2B3">
                  {generated_by}
                </Typography>
              </Box>
              <Box
                component="img"
                src={ellipse}
                sx={{
                  margin: { md: '0rem .25rem', lg: '0rem .5rem' }
                }}
              />
              <Box display="flex" flexDirection="row" gap={1}>
                <Typography variant="bodyMediumRegular" color="#98A2B3">
                  {formattedDate(generated_on, 'Do MMM, YYYY')}
                </Typography>

                {status === 'completed' && (
                  <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="row" gap={0.5}>
                      <Typography variant="bodyMediumRegular" color="#667085">
                        Run Time/
                      </Typography>
                      <Typography variant="bodyMediumRegular" color="#98A2B3">
                        {millisecsToMinsHr(generation_time * 1000)}
                      </Typography>
                    </Box>
                    <Box
                      component="img"
                      src={ellipse}
                      sx={{ margin: { md: '0rem .25rem', lg: '0rem .5rem' } }}
                    />
                    <Typography variant="bodyMediumRegular" color="#98A2B3">
                      13MB
                    </Typography>
                  </Box>
                )}
              </Box>
            </Stack>
          </Stack>
        </Stack>

        {status === 'completed' && (
          <Stack
            direction="row"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              width: {
                md: '12%',
                xl: '10%'
              },
              gap: 1
            }}
          >
            <Link href={file} rel="noreferrer">
              <Box
                sx={{
                  borderRadius: '6.25rem',
                  background: '#F5F6F7',
                  padding: '.5rem',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <SvgWrapper
                  icon={Download}
                  width="1.25rem"
                  height="1.25rem"
                  styleOverrides={{ fill: '#98A2B3', stroke: 'none' }}
                />
              </Box>
            </Link>

            <Box
              sx={{
                borderRadius: '6.25rem',
                background: '#F5F6F7',
                padding: '.5rem',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center'
              }}
              onClick={() => onDelete(name)}
            >
              <SvgWrapper
                icon={Trash}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{ fill: '#98A2B3' }}
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export default ReportsDocumentCard;
