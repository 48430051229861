import { BranchApiData, BranchMemberApiData } from 'services/types/branches';
import { api } from './baseApi';
import { providesObjectWithIds } from './utils';

type CreateBranchQueryArgs = {
  data: {
    name: string;
    is_headquarters: boolean;
    location: {
      street_address: string;
      premise?: string;
      locality: string;
      country: string;
      administrative_area: string;
      postal_code?: string;
      sub_administrative_area?: string;
    };
  };
};

type UpdateBranchMemberBranchQueryArgs = {
  branchId: string;
  data: {
    members: string[];
  };
};

export const branchApi = api.injectEndpoints({
  endpoints: builder => ({
    getBranches: builder.query<BranchApiData[], unknown>({
      query: () => ({
        url: '{organizationId}/branches/',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: result => {
        if (result) {
          return providesObjectWithIds(Object.keys(result), 'branches');
        }
        return [];
      }
    }),
    addBranch: builder.mutation<BranchApiData, CreateBranchQueryArgs>({
      query: ({ data }) => ({
        url: '{organizationId}/branches/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: [{ type: 'branches', id: 'LIST' }]
    }),
    getBranchMembers: builder.query<BranchMemberApiData, { branchId: string }>({
      query: branchId => ({
        url: `{organizationId}/branches/${branchId}/members/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    addBranchMember: builder.mutation<
      BranchMemberApiData,
      UpdateBranchMemberBranchQueryArgs
    >({
      query: ({ branchId, data }) => ({
        url: `{organizationId}/branches/${branchId}/members/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
    })
  }),
  overrideExisting: false
});

export const {
  useGetBranchesQuery,
  useAddBranchMemberMutation,
  useAddBranchMutation,
  useGetBranchMembersQuery,
  usePrefetch
} = branchApi;
