import React from 'react';
import { theme, fontFamily2 } from 'themes/theme';
import { passwordValidator } from 'pages/auth/validators/authFormValidators';
import { Typography, Grid, Box, Stack, Link } from '@mui/material';
import { useForm, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormPassword } from 'components/form';
import { Button } from 'components/button';

type FormValues = {
  password: string;
};

const RegisterMember = () => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      password: ''
    },
    resolver: yupResolver(passwordValidator) as unknown as Resolver<FormValues>
  });

  const onSubmit = (data: unknown) => console.log(data);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: '100vh'
      }}
    >
      <Grid item md={7}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '31rem',
            gap: '1.5rem',
            mt: '2rem',
            padding: {
              md: 0,
              lg: '2.5rem 1.5rem'
            }
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Box
              width="inherit"
              sx={{
                mb: '2.5rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem'
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <Typography
                  variant="h4Bold"
                  sx={{
                    color: `${theme.palette.common.heading}`
                  }}
                >
                  Hello, Ademola Oyebade
                </Typography>
              </Box>

              <Box
                sx={{
                  textAlign: 'center',
                  marginBottom: '0.2rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="bodyLargeSemibold"
                  sx={{
                    color: `${theme.palette.common.textColor}`,
                    fontWeight: '500',
                    margin: 0,
                    marginBottom: '0.1rem'
                  }}
                >
                  Welcome to the Scotia Bank team on TafiCasa
                </Typography>

                <Typography
                  variant="bodyLargeSemibold"
                  sx={{
                    color: `${theme.palette.common.textColor}`,
                    fontWeight: '500',
                    margin: 0
                  }}
                >
                  Create a password you can remember to secure your account
                </Typography>
              </Box>
            </Box>

            <Box width="inherit" sx={{ mb: '1.75rem' }}>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormPassword<FormValues>
                  control={control}
                  label="PASSWORD"
                  name="password"
                  checkPassword
                />

                <Button
                  color="primary"
                  size="lg"
                  text="Go to Dashboard"
                  styleOverrides={{
                    padding: '0.75rem 1rem',
                    width: '100%'
                  }}
                  disabled={!isDirty || !isValid}
                  loadingIcon={isSubmitting}
                  submit
                />
              </form>
            </Box>

            <Stack
              direction="row"
              width="inherit"
              sx={{
                color: `${theme.palette.common.textColor}`,
                fontFamily: fontFamily2,
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.225rem ',
                letterSpacing: '-0.01313rem',
                display: 'flex',
                flexWrap: 'wrap'
              }}
            >
              <Typography component="span">
                By clicking sign up, I agree to TafiCasa{' '}
                <Link
                  href="/"
                  sx={{
                    color: `${theme.palette.common.btnColor}`,
                    textDecoration: 'none'
                  }}
                >
                  {' '}
                  Terms of use
                </Link>{' '}
                &{' '}
                <Link
                  href="/"
                  sx={{
                    color: `${theme.palette.common.btnColor}`,
                    textDecoration: 'none'
                  }}
                >
                  {' '}
                  Privacy Policy
                </Link>
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RegisterMember;
