import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PermissionType } from 'services/enums/permissions';
import { OrganizationType } from 'services/enums/organization';

export type Auth = {
  expiry: string;
  userId: string;
  branch: {
    branchId: string;
    branchName: string;
  };
  organizationId: string;
  organizationType: OrganizationType;
  supplyChainProfileId: string;
  membershipType: string;
  isAuthenticated: boolean;
  permissions: PermissionType[];
  devAuthToken: string | null;
};

interface LoginActionPayload {
  isAuthenticated: boolean;
  userId: string;
  organizationId: string;
  organizationType: OrganizationType;
  supplyChainProfileId: string;
  membershipType: string;
  devAuthToken: string | null;
  branch: {
    branchId: string;
    branchName: string;
  };
}

interface PermissionActionPayload {
  permissions: PermissionType[];
}

interface BranchActionPayload {
  branchId: string;
  branchName: string;
}

const initialState: Auth = {
  expiry: '',
  userId: '',
  organizationId: '',
  organizationType: OrganizationType.NONE,
  supplyChainProfileId: '',
  membershipType: '',
  isAuthenticated: false,
  permissions: [],
  devAuthToken: null,
  branch: {
    branchId: '',
    branchName: ''
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPermissions: (
      state: Auth,
      action: PayloadAction<PermissionActionPayload>
    ) => {
      return {
        ...state,
        permissions: action.payload.permissions
      };
    },
    setUserLogin(state: Auth, action: PayloadAction<LoginActionPayload>) {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        userId: action.payload.userId,
        organizationId: action.payload.organizationId,
        membershipType: action.payload.membershipType,
        organizationType: action.payload.organizationType,
        supplyChainProfileId: action.payload.supplyChainProfileId,
        devAuthToken: action.payload.devAuthToken,
        branch: action.payload.branch
      };
    },
    setUserLogout(state) {
      return {
        ...initialState,
        organizationType: state.organizationType,
        membershipType: state.membershipType,
        permissions: state.permissions
      };
    },
    setBranch: (state: Auth, action: PayloadAction<BranchActionPayload>) => {
      return {
        ...state,
        branch: action.payload
      };
    }
  }
});

export const { setUserLogin, setUserLogout, setPermissions, setBranch } =
  authSlice.actions;
export default authSlice;
