import React, { useState } from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { useAppSelector } from 'services/hook';
import { Box } from '@mui/material';
import { useGetProductReceiptsSummaryQuery } from 'services/api/analyticsApi';
import IndicatorCard from './cards/indicatorCard';
import IndicatorSkeleton from 'components/skeleton/indicatorCardLoader';
import {
  TopProductsChart,
  TopDealerLocationsChart,
  TopDealersChart,
  TotalPrcIssuedChart,
  TotalPrcValueChart
} from './charts';
import {
  formatLargeAmount,
  formattedDate
} from 'utilities/helperFunc/formatter';
import { DateRangeType, Nav } from './charts/chartsNav';
import { getDateRange } from 'utilities/helperFunc/getDateRange';
import { cardText } from 'copy/analytics';
import { OrganizationType } from 'services/enums/organization';

Chart.register(CategoryScale);

const defaultDateRange = getDateRange('This Month');
const AnalyticsPage = () => {
  const { organizationType } = useAppSelector(state => state.auth);
  const [dateRange, setDateRange] = useState<DateRangeType>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
    granularity: 'daily'
  });

  const { data: productReceiptSummary } = useGetProductReceiptsSummaryQuery({
    params: {
      start: formattedDate(
        dateRange.startDate || defaultDateRange.start,
        'YYYY-MM-DD'
      ),
      end: formattedDate(
        dateRange.endDate || defaultDateRange.end,
        'YYYY-MM-DD'
      )
    }
  });

  const indicatorItems = productReceiptSummary
    ? [
        {
          title: cardText[organizationType]?.[0].title,
          value: formatLargeAmount(
            productReceiptSummary.total_value,
            true,
            true,
            1
          ),
          tooltipText: cardText[organizationType]?.[0].tooltipText
        },
        {
          title: cardText[organizationType]?.[1].title,
          value: formatLargeAmount(
            productReceiptSummary.total_count,
            true,
            false
          ),
          tooltipText: cardText[organizationType]?.[1].tooltipText
        },
        {
          title: cardText[organizationType]?.[2].title,
          value: `${productReceiptSummary.pickup_rate}%`,
          tooltipText: cardText[organizationType]?.[2].tooltipText
        }
      ]
    : null;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 'inherit'
        }}
      >
        <Nav header="Analytics Summary" setDateRange={setDateRange} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1.375rem',
            width: 'inherit'
          }}
        >
          {!indicatorItems
            ? [1, 2, 3].map(index => <IndicatorSkeleton key={index} />)
            : indicatorItems.map((item, index) => (
                <IndicatorCard
                  key={index}
                  title={item.title ?? ''}
                  value={item.value}
                  tooltip={item.tooltipText}
                />
              ))}
        </Box>
      </Box>
      {(organizationType === OrganizationType.MERCHANT ||
        organizationType === OrganizationType.LENDER) && (
        <>
          <TopDealersChart />
          <TopDealerLocationsChart />
        </>
      )}

      <Box
        sx={{
          marginTop: '2rem',
          display: 'flex',
          gap: '1.75rem',
          width: '100%',
          height: '30rem',
          mb: 7,
          boxShadow: '0px 1.5px 4px -1px rgba(16, 25, 40, 0.03)'
        }}
      >
        <TotalPrcValueChart />
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '1.75rem',
          width: '100%',
          justifyContent: 'space-between',
          boxShadow: '0px 1.5px 4px -1px rgba(16, 25, 40, 0.03)'
        }}
      >
        <TotalPrcIssuedChart />
        <TopProductsChart />
      </Box>
    </Box>
  );
};

export default AnalyticsPage;
