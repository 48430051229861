import React, { useState } from 'react';

import { Avatar, Box, Stack, Typography } from '@mui/material';
import { Drawer } from 'components/drawer';
import { Distributor, Merchant } from 'pages/distributorManagement/types';
import { SearchBar } from 'components/search';
import { Button } from 'components/button';
import { Chip } from 'components/chip';
import { SvgWrapper } from 'components/svg';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import distributor from 'assets/png/distributor.png';
import distributor2 from 'assets/png/distributor2.png';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  merchant: Merchant;
}

const dummyDistributors: Distributor[] = [
  {
    distributor: {
      public_id: '',
      name: '',
      logo: ''
    },
    public_id: 'SDYT-5RT5',
    registration_code: '',
    name: 'Tiles and Co',
    address: '1234 Distributor St, City, Country',
    logo: distributor,
    registered: true
  },
  {
    distributor: {
      public_id: '',
      name: '',
      logo: ''
    },
    public_id: 'AF5T-78HN',
    registration_code: '',
    name: 'Emeka and Sons',
    address: '5678 Distributor Ave, City, Country',
    logo: distributor2,
    registered: true
  }
];

const MerchantDistributors: React.FC<DrawerProps> = ({
  open,
  onClose,
  merchant
}) => {
  const [distributor, setDistributor] = useState<Distributor | null>(null);
  const [currentSearchText, setCurrentSearchText] = useState('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchCompleted, setSearchCompleted] = useState<boolean>(false);

  const handleSearch = () => {
    setIsSearching(true);
    setSearchCompleted(false);
    setTimeout(() => {
      setIsSearching(false);
      setSearchCompleted(true);

      const regex = /^[A-Za-z0-9]{4}-[A-Za-z0-9]{4}$/;
      const isIdValid = regex.test(currentSearchText);

      if (isIdValid) {
        if (Math.random() > 0.2) {
          const randomDistributor =
            dummyDistributors[
              Math.floor(Math.random() * dummyDistributors.length)
            ];
          randomDistributor.public_id = currentSearchText.toUpperCase();
          setDistributor(randomDistributor);
        } else {
          setDistributor(null);
        }
      } else {
        setDistributor(null);
      }
      setCurrentSearchText('');
    }, 2000);
  };

  const renderData = (item: Distributor) => {
    return (
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        sx={{
          padding: '1.5rem',
          borderRadius: '0.75rem',
          background: '#FCFCFD'
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            borderBottom: '0.063rem solid #F5F6F7'
          }}
        >
          <Stack
            display="flex"
            flexDirection="row"
            flexGrow={1}
            padding="0.5rem 0rem 0.75rem 0rem"
            alignItems="center"
          >
            <Avatar
              src={item.logo}
              sx={{
                width: '2rem',
                height: '2rem',
                mr: '0.75rem',
                justifyContent: 'center',
                borderRadius: '12.5rem'
              }}
            />
            <Stack
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="bodyMediumMedium" color="#344054">
                {item.name}
              </Typography>
              <Typography variant="bodyMediumRegular" color="#667085">
                {item.public_id}
              </Typography>
            </Stack>
          </Stack>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            width="50%"
          >
            <Chip label="Registered" size="sm" color="success" icon />
          </Box>
        </Stack>

        <Stack
          display="flex"
          flexDirection="column"
          justifyContent="center"
          paddingTop="1rem"
          gap={0.5}
        >
          <Typography variant="captionSmall" color="#98A2B3">
            Address
          </Typography>
          <Typography variant="bodyMediumMedium" color="#667085">
            {item.address}
          </Typography>
        </Stack>
      </Box>
    );
  };

  const renderDrawerHeader = () => {
    return (
      <Box>
        <Stack
          display="flex"
          flexDirection="row"
          padding="1.25rem 1.25rem 1.25rem 0rem"
        >
          <Avatar
            src={merchant.merchant.logo}
            sx={{
              width: '3.5rem',
              height: '3.5rem',
              mr: '1.3rem',
              justifyContent: 'center',
              borderRadius: '12.5rem'
            }}
          />
          <Stack
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={0.5}
          >
            <Typography variant="h6Bold" color="#344054">
              {merchant.merchant.name}
            </Typography>
            <Typography variant="bodyMediumMedium" color="#667085">
              Search for distributors under this merchant
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  };

  return (
    <Drawer open={open} onClose={onClose} header={renderDrawerHeader()}>
      <Box padding="1.5rem 1.25rem">
        <Box display="flex" flexDirection="column" width="inherit" gap={2}>
          <SearchBar
            size="sm"
            id="search"
            options={[]}
            onChange={value => {
              setCurrentSearchText(value);
            }}
            placeholderText="Search using name or code"
          />
          <Button
            color="primary"
            size="sm"
            iconPosition="start"
            loadingIcon={isSearching}
            text={isSearching ? 'Search in Progress..' : 'Search'}
            width="inherit"
            disabled={currentSearchText.length === 0 || isSearching}
            onClick={handleSearch}
          />

          {searchCompleted && (
            <>
              {distributor ? (
                <>{renderData(distributor)}</>
              ) : (
                !isSearching && (
                  <Stack
                    direction="column"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ background: '#FCFCFD', padding: '1.25rem' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '3.25rem',
                        height: '3.25rem',
                        padding: '0.125rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '1.25rem',
                        boxShadow: '0px 1.5px 4px -1px rgba(20, 28, 40, 0.05)'
                      }}
                    >
                      <SvgWrapper
                        icon={Close}
                        width="1.5rem"
                        height="3rem"
                        styleOverrides={{
                          fill: '#A0252D'
                        }}
                      />
                    </Box>

                    <Stack
                      direction="column"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt=".5rem"
                    >
                      <Stack
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        textAlign="center"
                      >
                        <Typography variant="bodyLargeSemibold" color="#344054">
                          Record Not Found
                        </Typography>
                        <Typography variant="bodySmallMedium" color="#667085">
                          Records for this distributor could not be found under
                          merchant
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                )
              )}
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default MerchantDistributors;
