import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getRouter from 'routes';
import { Theme, useMediaQuery, Box } from '@mui/material';
import DesktopOnlyPage from 'pages/app/desktopOnlyPage';
import { theme } from 'themes/theme';
import { useAppSelector } from 'services/hook';

function App() {
  const authState = useAppSelector(state => state.auth);
  const flags = useAppSelector(state => state.featureFlags);
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <>
      {matches && <DesktopOnlyPage />}
      <Box
        width="100%"
        height="100%"
        sx={{
          [theme.breakpoints.down('md')]: {
            display: `${matches ? 'none' : 'block'}`
          }
        }}
      >
        <RouterProvider router={getRouter(authState, flags)} />
        <ToastContainer
          position="top-right"
          newestOnTop={false}
          closeOnClick
          rtl={false}
          closeButton={false}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          draggable
          theme="colored"
          toastStyle={{
            borderRadius: '0.25rem',
            borderTop: '0.063rem solid #E4E7EC',
            borderRight: '0.063rem solid #E4E7EC',
            borderBottom: '0.063rem solid #E4E7EC'
          }}
        />
      </Box>
    </>
  );
}

export default App;
