import React, { useContext, useMemo, useState } from 'react';
import { Stack, Box, Divider, Typography } from '@mui/material';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import _ from 'lodash';

import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import { ReactComponent as plusIcon } from 'assets/svg/plus.svg';
import { StrictModeDroppable as Droppable } from 'components/beautiful-dnd';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import { useGetDiscoverableProfilesQuery } from 'services/api/supplyChainApi';
import { useAppSelector } from 'services/hook';
import { WorkflowBuilderContext } from '../context/builderContext';
import ChangeWorkflowOptionsModal from '../customWorkflowOptionsModal';
import WorkflowBuilderStepCard from '../workflowBuilderStepCard';
import { getItem } from 'utilities/helperFunc/localStore';
import { FormSelectOption } from 'components/form';
import { useGetProductsQuery } from 'services/api/productApi';

type MetaDataOptions = {
  merchant: string;
  product: string;
};

const WorkflowBuilderSideBar: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [customWorkflowOptionsModalOpen, setCustomWorkflowOptionsModalOpen] =
    useState(false);
  const { FEATURE_FLAG_ENABLE_CUSTOM_WORKFLOWS } = useAppSelector(
    state => state.featureFlags
  );
  const { supplyChainProfileId, organizationType } = useAppSelector(
    state => state.auth
  );

  const {
    workflowSteps,
    setWorkflowSteps,
    addNewStep,
    setResetVersionOpen: setDeleteVersionOpen,
    currentWorkflowVersion
  } = useContext(WorkflowBuilderContext);
  const { data: merchantData } = useGetDiscoverableProfilesQuery({
    params: {
      organization_type: 'merchant'
    }
  });
  const metadataOptions: MetaDataOptions = getItem('customWorkflowOptions');
  const { data: productData } = useGetProductsQuery(
    organizationType === 'merchant'
      ? supplyChainProfileId
      : metadataOptions?.merchant
  );

  const customWorkflow: { merchant: string; product: string } = getItem(
    'customWorkflowOptions'
  );

  const onDragEnd = (result: DropResult) => {
    const { destination, draggableId } = result;
    if (destination && destination.index != _.toInteger(draggableId)) {
      const currentSteps = _.cloneDeep(workflowSteps);
      // Remove the dragged step from its place
      const currentDraggedStep = currentSteps.splice(
        _.toInteger(draggableId),
        1
      );

      // Add the removed step to its new place in the array
      currentSteps.splice(destination.index, 0, currentDraggedStep[0]);

      // Set new steps
      setWorkflowSteps(currentSteps);
    }
  };

  const merchantOptions = useMemo(() => {
    return (
      (merchantData &&
        Object.values(merchantData).map(merchant => ({
          title: merchant.name,
          value: merchant.profile_id
        }))) ||
      []
    );
  }, [merchantData]);

  const productOptions = useMemo(() => {
    const options: FormSelectOption[] = [];
    productData?.forEach(product => {
      options.push({
        title: product.name,
        value: product.product_id
      });
    });
    return options;
  }, [productData]);

  const merchantName = (merchant: string) => {
    const element = merchantOptions.find(item => item.value === merchant);
    return element?.title !== undefined ? element.title : '---';
  };

  const productName = (product: string) => {
    const element = productOptions.find(item => item.value === product);
    return element?.title !== undefined ? element.title : '---';
  };

  const checkCustomWorkflow = () => {
    if (customWorkflow) {
      return (
        Object.values(customWorkflow).every(value => value !== '') &&
        Object.values(customWorkflow).length !== 0
      );
    } else return false;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        padding: '1rem 0.75rem'
      }}
    >
      <Box
        sx={{
          padding: '1rem 0.75rem 0.75rem .5rem',
          borderBottom: '0.0625rem solid #F5F6F7'
        }}
      >
        <Typography variant="captionLarge" color="#667085">
          Version {currentWorkflowVersion}
        </Typography>
      </Box>

      {FEATURE_FLAG_ENABLE_CUSTOM_WORKFLOWS && checkCustomWorkflow() && (
        <Stack
          sx={{
            padding: '.5rem .5rem 1rem 0.5rem'
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              pt: '1rem',
              cursor: 'pointer'
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              Description
            </Typography>

            <SvgWrapper
              icon={isOpen ? ChevronDown : ChevronRight}
              width="1.25rem"
              height="1.25rem"
              styleOverrides={{ fill: 'none', stroke: '#98A2B3' }}
            />
          </Stack>

          {isOpen && (
            <Stack
              direction="column"
              paddingTop=".75rem 0rem"
              alignItems="center"
              mt=".75rem"
            >
              <Stack
                direction="column"
                paddingTop="0.75rem 0rem"
                alignItems="center"
                width="100%"
              >
                {metadataOptions?.merchant && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding=".75rem 0rem"
                    width="inherit"
                  >
                    <Typography variant="bodyMediumMedium" color="#667085">
                      Merchant
                    </Typography>
                    <Typography variant="bodyMediumMedium" color="#667085">
                      {_.truncate(merchantName(metadataOptions.merchant), {
                        length: 25,
                        omission: '...'
                      })}
                    </Typography>
                  </Stack>
                )}
                {metadataOptions?.product && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding=".75rem 0rem"
                    width="inherit"
                  >
                    <Typography variant="bodyMediumMedium" color="#475467">
                      Product
                    </Typography>
                    <Typography variant="bodyMediumMedium" color="#475467">
                      {_.truncate(productName(metadataOptions.product), {
                        length: 25,
                        omission: '...'
                      })}
                    </Typography>
                  </Stack>
                )}
              </Stack>

              <Button
                text="Change"
                color="grey"
                transparent
                size="md"
                styleOverrides={{
                  width: '100%',
                  mt: '1rem'
                }}
                onClick={() => setCustomWorkflowOptionsModalOpen(true)}
              />
            </Stack>
          )}
        </Stack>
      )}

      <Divider
        sx={{
          borderColor: '#F5F6F7'
        }}
      />

      <Stack
        justifyContent="center"
        alignItems="start"
        gap="0.25rem"
        sx={{
          padding: '1.5rem 1rem 1rem 0.5rem',
          borderBottom: '0.0625rem solid #F5F6F7'
        }}
      >
        <Typography variant="bodyLargeSemibold" color="#475467">
          All Steps
        </Typography>
        <Typography variant="bodyMediumRegular" color="#667085">
          Rearrange and add new actions here
        </Typography>
      </Stack>
      <Box sx={{ overflowY: 'auto', width: '100%' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-container-workflow-builder-sidebar">
            {provided => (
              <Box
                sx={{ width: '100%' }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {workflowSteps.map((step, index) => {
                  const { title, description } = step;
                  return (
                    <Draggable
                      key={`draggable-step-card-${index}`}
                      draggableId={_.toString(index)}
                      index={index}
                    >
                      {provided => (
                        <WorkflowBuilderStepCard
                          title={title}
                          description={description}
                          draggableProps={provided.draggableProps}
                          forwardedRef={provided.innerRef}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Stack
        alignItems="center"
        sx={{ flexShrink: 1, margin: 'auto 1rem 1rem 0.75rem' }}
      >
        <Button
          color="primary"
          size="md"
          text="Add new action"
          width="100%"
          styleOverrides={{
            margin: '1.25rem 1rem'
          }}
          onClick={() => {
            addNewStep();
          }}
          icon={plusIcon}
          iconPosition="start"
        />
        <Button
          color="grey"
          transparent
          size="md"
          text="Reset Workflow"
          width="100%"
          onClick={() => {
            setDeleteVersionOpen(true);
          }}
        />
      </Stack>

      <ChangeWorkflowOptionsModal
        open={customWorkflowOptionsModalOpen}
        onClose={() => setCustomWorkflowOptionsModalOpen(false)}
        merchantOptions={merchantOptions}
      />
    </Box>
  );
};
export default WorkflowBuilderSideBar;
