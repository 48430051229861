import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactComponent as FileLocked } from 'assets/svg/file-locked.svg';
import { SvgWrapper } from 'components/svg';
import { Chip } from 'components/chip';
import { Table } from 'components/table';
import { MiniProductReceiptData } from 'services/types/productReceipt';
import { formattedNumber, formattedDate } from 'utilities/helperFunc/formatter';
import _ from 'lodash';

const BatchProductReceiptDetailsTable = ({
  data,
  productUnit
}: {
  data: MiniProductReceiptData[];
  productUnit: string;
}) => {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<MiniProductReceiptData>();
  const columns = [
    columnHelper.accessor('product_receipt_id', {
      header: 'PRC',
      cell: info => {
        return (
          <Stack
            direction="row"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '2.25rem',
                width: '2.25rem',
                borderRadius: '.5rem',
                backgroundColor: '#FBFCFE',
                padding: '0.5rem'
              }}
            >
              <SvgWrapper
                icon={FileLocked}
                width="1.25rem"
                height="1.25rem"
                styleOverrides={{ opacity: 0.8 }}
                color="#CDD2DB"
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography variant="bodyMediumMedium" color="#475467">
                {info.getValue()}
              </Typography>
            </Box>
          </Stack>
        );
      },
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('quoted_price', {
      header: 'TOTAL VALUE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {formattedNumber(info.getValue(), true)}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('total_quantity', {
      header: 'QUANTITY',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {`${formattedNumber(info.getValue(), false)} (${productUnit})`}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('quoted_price_expiry', {
      header: 'issue_date',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {formattedDate(`${info.getValue()}`, 'Do MMM, YYYY')}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('status', {
      header: 'status',
      cell: info =>
        chipData.map((chip, index) => {
          return (
            <Box key={index}>
              {info.getValue() === chip.text && (
                <Chip
                  label={_.startCase(chip.text)}
                  size="sm"
                  color={chip.color}
                  icon
                />
              )}
            </Box>
          );
        }),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    })
  ] as ColumnDef<MiniProductReceiptData>[];
  // Cast is a workaround due to issues with tansatck table
  // See https://github.com/TanStack/table/issues/4302

  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 auto',
        mt: '1rem'
      }}
    >
      <Table<MiniProductReceiptData>
        hover
        columns={columns}
        data={data}
        enableFilter={false}
        onClickRow={(rowData: MiniProductReceiptData) => {
          if (rowData !== undefined) {
            navigate(`/product-receipts/${rowData.product_receipt_id}/details`);
          }
        }}
        searchPlaceholderText="Search using code, quantity or value"
        title={
          data.length === 0
            ? undefined
            : `Batch No/ ${data.length === 0 ? undefined : data[0].batch_id}`
        }
        subtitle={
          data.length === 0 ? undefined : 'All product receipts in this batch'
        }
        hideTableHead
        selectedRowsActionText="Start Workflow"
        selectedRowsClick={(rowData: MiniProductReceiptData[]) => {
          if (rowData !== undefined && rowData.length >= 1) {
            navigate(`/product-receipts/${rowData[0].batch_id}/workflow`);
          }
        }}
      />
    </Box>
  );
};

export default BatchProductReceiptDetailsTable;

const chipData: {
  id: number;
  text: string;
  color: 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'error';
}[] = [
  {
    id: 0,
    text: 'pending',
    color: 'default'
  },
  {
    id: 1,
    text: 'endorsed',
    color: 'success'
  },
  {
    id: 2,
    text: 'completed',
    color: 'success'
  },
  {
    id: 3,
    text: 'in_progress',
    color: 'warning'
  },
  {
    id: 4,
    text: 'issued',
    color: 'success'
  },
  {
    id: 5,
    text: 'closed',
    color: 'primary'
  }
];
