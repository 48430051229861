import React from 'react';
import { Avatar, Box, Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';

interface CardDataProps {
  name: string;
  logo: string;
  isActive: boolean;
  onClick: () => void;
}

const OrgCard = ({ name, logo, isActive, onClick }: CardDataProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: '.75rem',
        height: '3.75rem',
        width: '16rem',
        borderRadius: '.75rem',
        border: isActive ? '.125rem solid #C3D7FD' : '.125rem solid #F5F6F7',
        cursor: 'pointer',
        color: '#667085',
        boxShadow: '0px 1.5px 4px -1px rgba(20, 28, 40, 0.05)'
      }}
      onClick={onClick}
    >
      <Stack
        display="flex"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap=".75rem"
        sx={{
          height: '100%',
          width: 'auto'
        }}
      >
        <Box
          sx={{
            width: '2.25rem',
            height: '2.25rem',
            borderRadius: '.75rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#FCFCFD',
            padding: '.25rem'
          }}
        >
          <Avatar
            src={logo}
            sx={{
              width: '1.75rem',
              height: '1.75rem',
              borderRadius: '1.75rem'
            }}
          />
        </Box>

        <Typography variant="bodyMediumMedium" color="#667085">
          {_.truncate(`${_.startCase(name)}`, {
            length: 25,
            omission: '...'
          })}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default OrgCard;
