import React from 'react';
import {
  Typography,
  Box,
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps
} from '@mui/material';
import { colorTheme } from 'themes/colors';

// TODO Task 1047 Readjust Tooltip props logic for icon handling and easy usage

export interface TooltipProps {
  header?: string;
  subText?: string;
  color?: string;
  placement?: MUITooltipProps['placement'];
  children: React.ReactNode;
  bgColor?: string;
  showArrow?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({
  header,
  subText,
  color = colorTheme.grey700,
  placement = 'top',
  children,
  bgColor = colorTheme.grey50,
  showArrow = true
}) => {
  return (
    <MUITooltip
      title={
        <Box
          sx={{
            borderRadius: '0.25rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '0.25rem'
          }}
        >
          <Typography variant="h7Semibold" color={color}>
            {header}
          </Typography>

          {subText && (
            <Typography variant="bodySmallRegular" color={colorTheme.grey600}>
              {subText}
            </Typography>
          )}
        </Box>
      }
      arrow={showArrow}
      placement={placement}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: bgColor,
            borderRadius: '0.25rem',
            padding: '0.75rem',
            boxShadow: '0px 2px 6px -1px rgba(113, 120, 132, 0.3)'
          },
          '& .MuiTooltip-arrow': {
            color: bgColor
          }
        }
      }}
    >
      <Box component="span" sx={{ cursor: 'pointer' }}>
        {children}
      </Box>
    </MUITooltip>
  );
};

export default Tooltip;
