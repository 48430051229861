import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import ProductReceiptsTable from './tables/productReceiptsTable';
import { useAppSelector } from 'services/hook';
import { MiniProductReceiptApiData } from 'services/types/productReceipt';
import { useGetCentralVaultProductReceiptsQuery } from 'services/api/vaultApi';

const ProductReceiptCentralVaultPage = () => {
  const { organizationType } = useAppSelector(state => state.auth);
  const { distributorId } = useParams();
  const { data, isLoading } = useGetCentralVaultProductReceiptsQuery({
    distributorId: distributorId
  });

  const centralVaultData = useMemo(() => {
    const emptyCentralVaultData: MiniProductReceiptApiData[] = [];
    if (data) {
      return Object.values(data.results);
    }
    return emptyCentralVaultData;
  }, [data]);

  return (
    <Box>
      <ProductReceiptsTable
        data={centralVaultData}
        organizationType={organizationType}
        loading={isLoading}
      />
    </Box>
  );
};

export default ProductReceiptCentralVaultPage;
