import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { ProductReceiptType } from 'services/types/productReceipt';
import { getItem, setItem } from 'utilities/helperFunc/localStore';

export enum ProductReceiptIssueType {
  DIRECT = 'Direct Issue',
  INTERMEDIARY = 'Issue via Intermediary'
}

export type RequestDetails = {
  id: number;
};

export interface FormValues {
  customer_name: string;
  bank: string;
  product: string;
  product_variant: string;
  quantity: string;
  unit: string;
  amount: string;
  expiry_date: string;
}

const initialValues: FormValues[] = [
  {
    customer_name: '',
    bank: '',
    product: '',
    product_variant: '',
    quantity: '',
    unit: '',
    amount: '',
    expiry_date: ''
  }
];

type CreateProductReceiptContextTypes = {
  data: FormValues[];
  setData: React.Dispatch<React.SetStateAction<FormValues[]>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  request: RequestDetails | null;
  setRequest: React.Dispatch<React.SetStateAction<RequestDetails | null>>;
  selectedType: ProductReceiptType | null;
  setSelectedType: React.Dispatch<
    React.SetStateAction<ProductReceiptType | null>
  >;
  selectedIssueType: ProductReceiptIssueType | null;
  setSelectedIssueType: React.Dispatch<
    React.SetStateAction<ProductReceiptIssueType | null>
  >;
  formState: boolean;
  setFormState: React.Dispatch<React.SetStateAction<boolean>>;
  steps: { label: string }[];
  handleNext: (newData?: FormValues) => void;
  handlePrev: () => void;
};

const CreateProductReceiptContextDefaults: CreateProductReceiptContextTypes = {
  data: initialValues,
  setData: () => undefined,
  activeStep: 0,
  setActiveStep: () => undefined,
  request: null,
  setRequest: () => undefined,
  selectedType: null,
  setSelectedType: () => undefined,
  selectedIssueType: null,
  setSelectedIssueType: () => undefined,
  formState: false,
  setFormState: () => undefined,
  steps: [],
  handleNext: () => undefined,
  handlePrev: () => undefined
};

export const CreateProductReceiptContext =
  createContext<CreateProductReceiptContextTypes>(
    CreateProductReceiptContextDefaults
  );

const CreateProductReceiptContextWrapper = ({
  children
}: PropsWithChildren) => {
  const [formState, setFormState] = useState<boolean>(false);
  const [request, setRequest] = useState<RequestDetails | null>(null);
  const [data, setData] = useState<FormValues[]>(
    getItem<FormValues[]>('createProductReceiptFormData', initialValues)
  );
  const [activeStep, setActiveStep] = useState<number>(() =>
    getItem<number>('createProductReceiptStep', 0)
  );
  const [selectedType, setSelectedType] = useState<ProductReceiptType | null>(
    () => getItem('createProductReceiptSelectedType', null)
  );
  const [selectedIssueType, setSelectedIssueType] =
    useState<ProductReceiptIssueType | null>(() =>
      getItem('createProductReceiptSelectedIssueType', null)
    );

  const [searchParams, setSearchParams] = useSearchParams();

  const requestIdParam = searchParams.get('requestId');
  const requestId = requestIdParam === 'null' ? null : requestIdParam;

  const steps = [
    { label: 'Select Type' },
    { label: 'Issue Method' },
    { label: 'Product Receipt Details' },
    { label: 'Review Product Receipt' }
  ];

  const handleNext = () => {
    const nextStep = activeStep + 1;
    if (nextStep < steps.length) {
      setActiveStep(nextStep);
    }
  };

  const handlePrev = () => {
    const prevStep = activeStep - 1;
    if (prevStep >= 0) {
      setActiveStep(prevStep);
    }
  };

  useEffect(() => {
    setItem('createProductReceiptStep', activeStep);
    setItem('createProductReceiptSelectedType', selectedType);
    setItem('createProductReceiptSelectedIssueType', selectedIssueType);
    setItem('createProductReceiptFormData', data);
  }, [activeStep, selectedType, selectedIssueType, data]);

  useEffect(() => {
    if (requestId !== null) {
      setRequest({ id: 1 });
      setActiveStep(2);
    } else {
      setRequest(null);
    }
  }, [requestId]);

  useEffect(() => {
    if (request) {
      setSearchParams({ requestId: `${request.id}` });
    }
  }, [request]);

  const values = {
    handleNext,
    handlePrev,
    steps,
    activeStep,
    setActiveStep,
    request,
    setRequest,
    selectedType,
    setSelectedType,
    selectedIssueType,
    setSelectedIssueType,
    data,
    setData,
    formState,
    setFormState
  };

  return (
    <CreateProductReceiptContext.Provider value={values}>
      {children}
    </CreateProductReceiptContext.Provider>
  );
};

export default CreateProductReceiptContextWrapper;
