import React from 'react';
import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { Chip } from 'components/chip';
import { formattedNumber } from 'utilities/helperFunc/formatter';
import _ from 'lodash';

interface CardDataProps {
  holders_name?: string;
  product_name: string;
  total_quantity: number;
  unit: string;
  quoted_price: number;
  status: string;
}

const ProductReceiptDetailsCard = ({
  holders_name,
  quoted_price,
  product_name,
  total_quantity,
  unit,
  status
}: CardDataProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: '1.25rem',
        gap: '1rem',
        width: 'auto'
      }}
    >
      <Stack
        width="30rem"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack direction="column" display="flex" justifyContent="space-between">
          <Typography variant="bodyLargeSemibold" color="#475467">
            {holders_name}
          </Typography>

          <Stack
            width="auto"
            direction="row"
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="bodyMediumRegular" color="#98A2B3">
              {product_name}
            </Typography>

            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                borderRadius: '0.125rem',
                borderLeft: '0.0625rem solid #EAECF0',
                borderRight: '0.0625rem solid #EAECF0',
                margin: '0rem 0.5rem',
                height: '1.187rem'
              }}
            />

            <Typography variant="bodyMediumRegular" color="#98A2B3">
              {formattedNumber(total_quantity, false)} {unit}
            </Typography>
          </Stack>

          <Typography
            variant="h7Semibold"
            color="#475467"
            paddingBottom="0.25rem"
            textAlign="left"
            marginTop="1rem"
          >
            {formattedNumber(quoted_price * total_quantity, true)}
          </Typography>
        </Stack>

        <Box>
          {chipData.map((chip, index) => {
            return (
              <Box key={index}>
                {status === chip.text && (
                  <Chip
                    label={_.startCase(chip.text)}
                    size="sm"
                    color={chip.color}
                    icon
                  />
                )}
              </Box>
            );
          })}
        </Box>
      </Stack>
    </Paper>
  );
};

export default ProductReceiptDetailsCard;

const chipData: {
  id: number;
  text: string;
  color: 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'error';
}[] = [
  {
    id: 0,
    text: 'pending',
    color: 'default'
  },
  {
    id: 1,
    text: 'endorsed',
    color: 'success'
  },
  {
    id: 2,
    text: 'completed',
    color: 'success'
  },
  {
    id: 3,
    text: 'in_progress',
    color: 'warning'
  },
  {
    id: 4,
    text: 'issued',
    color: 'success'
  },
  {
    id: 5,
    text: 'closed',
    color: 'primary'
  }
];
