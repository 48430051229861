import { api } from './baseApi';

export const distributorManagementApi = api.injectEndpoints({
  endpoints: builder => ({
    getMerchantsRegisteredToDistributor: builder.query({
      query: (profileId: string) => ({
        url: `distributor_management/distributor/${profileId}/merchants/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getDistributorsRegisteredToMerchant: builder.query({
      query: (profileId: string) => ({
        url: `distributor_management/merchant/${profileId}/distributors/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    })
  }),
  overrideExisting: false
});

export const {
  useGetMerchantsRegisteredToDistributorQuery,
  useGetDistributorsRegisteredToMerchantQuery
} = distributorManagementApi;
