import dayjs from 'dayjs';

const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

export const kbFormatter = (bytes: string) => {
  let l = 0,
    n = parseInt(bytes, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export const formattedDate = (
  date: dayjs.Dayjs | string | number | Date,
  formatString: string
) => {
  if (!date || date === '') {
    return '---';
  }
  return dayjs(date).locale('en').format(formatString);
};

export const formattedNumber = (
  value: number | string,
  isCurrency = false,
  locale = 'en-NG'
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: isCurrency ? 'currency' : 'decimal',
    currency: isCurrency ? 'NGN' : undefined
  });

  if (typeof value === 'string') {
    return formatter.format(Number(value));
  } else return formatter.format(value);
};

export const formatLargeAmount = (
  value: number | string,
  isLargeNumber = false,
  isCurrency = false,
  toFixed = 0,
  locale = 'en-NG'
) => {
  const num = typeof value === 'string' ? Number(value) : value;

  let valueString;
  if (isLargeNumber) {
    if (num >= 1000000000000) {
      valueString = (num / 1000000000000).toFixed(toFixed) + 'T';
    } else if (num >= 1000000000) {
      valueString = (num / 1000000000).toFixed(toFixed) + 'B';
    } else if (num >= 1000000) {
      valueString = (num / 1000000).toFixed(toFixed) + 'M';
    } else if (num >= 100000) {
      valueString = (num / 1000).toFixed(toFixed) + 'K';
    } else {
      valueString = String(num.toFixed(toFixed));
    }
  } else {
    valueString = String(num.toFixed(toFixed));
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: isCurrency ? 'currency' : 'decimal',
    currency: isCurrency ? 'NGN' : undefined,
    minimumFractionDigits: toFixed,
    maximumFractionDigits: toFixed
  });

  if (isCurrency) {
    return (
      formatter.format(Number(valueString.replace(/[KMBT]/g, ''))) +
      (isLargeNumber && num > 100000 ? valueString.slice(-1) : '')
    );
  } else {
    if (isLargeNumber) {
      return (
        formatter.format(Number(valueString.replace(/[KMBT]/g, ''))) +
        (isLargeNumber && num > 100000 ? valueString.slice(-1) : '')
      );
    }
    return formatter.format(Number(valueString));
  }
};

export const formattedQuantity = (value: string) => {
  const [numberPart, unitPart] = value.split(' ');
  const number = Number(numberPart).toFixed(2);
  const formattedNumberPart = formattedNumber(Number(number), false);
  return `${formattedNumberPart} ${unitPart}`;
};
