import React, { useEffect } from 'react';
import { Stack, Typography, TextField } from '@mui/material';
import { theme } from 'themes/theme';

export default function FilterNumberInput({
  value: initialValue,
  label,
  type,
  placeholder,
  onChange,
  debounce = 1000,
  ...props
}: {
  value: string | number;
  label: string;
  placeholder: string;
  type: 'number' | 'text';
  onChange: (value: string | number) => void;
  debounce?: number;
}) {
  const [value, setValue] = React.useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Stack width="100%" gap=".25rem" direction="column">
      <Typography variant="inputLabel" color="#667085">
        {label}
      </Typography>
      <TextField
        {...props}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={e => setValue(e.target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '2.25rem',
            borderRadius: '0.5rem',
            border: '.094rem solid #EAECF0',
            background: '#FEFFFF',
            boxShadow: '0px 4px 4px 0px rgba(220, 220, 220, 0.02)',
            '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent'
            },
            '&.Mui-focused fieldset': {
              borderColor: `${theme.palette.common.btnColor}`,
              boxShadow: `${theme.palette.common.boxShadow}`
            }
          }
        }}
      />
    </Stack>
  );
}
