import { createSlice } from '@reduxjs/toolkit';
import { featureFlagsApi } from 'services/api/config';
import { FeatureFlag } from 'services/enums/config';

export type ConfigState = {
  [key in FeatureFlag]: boolean;
};

const initialState: ConfigState = {
  // TEAMS AND BRANCHES
  [FeatureFlag.FEATURE_FLAG_ENABLE_TEAMS]: false,
  [FeatureFlag.FEATURE_FLAG_ENABLE_BRANCH]: false,
  // VAULT
  [FeatureFlag.FEATURE_FLAG_ENABLE_BRANCH_VAULT]: false,
  // WORKFLOWS
  [FeatureFlag.FEATURE_FLAG_ENABLE_WORKFLOW_COMMENTS]: false,
  [FeatureFlag.FEATURE_FLAG_ENABLE_CUSTOM_WORKFLOWS]: false,
  [FeatureFlag.FEATURE_FLAG_ENABLE_ASSIGN_TEAM_TO_WORKFLOW_STEP]: false,
  [FeatureFlag.FEATURE_FLAG_ENABLE_UPLOAD_DOCUMENT_TO_WORKFLOW_STEP]: false,
  // NOTIFICATIONS
  [FeatureFlag.FEATURE_FLAG_ENABLE_NOTIFICATIONS_EXTRA]: false
};

const featureFlagSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      featureFlagsApi.endpoints.getFeatureFlags.matchFulfilled,
      (state, { payload }) => {
        state.FEATURE_FLAG_ENABLE_TEAMS = payload.ENABLE_TEAMS;
        state.FEATURE_FLAG_ENABLE_BRANCH = payload.ENABLE_BRANCH;
        state.FEATURE_FLAG_ENABLE_BRANCH_VAULT = payload.ENABLE_BRANCH_VAULT;
        state.FEATURE_FLAG_ENABLE_WORKFLOW_COMMENTS =
          payload.ENABLE_WORKFLOW_COMMENTS;
        state.FEATURE_FLAG_ENABLE_CUSTOM_WORKFLOWS =
          payload.ENABLE_CUSTOM_WORKFLOWS;
        state.FEATURE_FLAG_ENABLE_ASSIGN_TEAM_TO_WORKFLOW_STEP =
          payload.ENABLE_ASSIGN_TEAM_TO_WORKFLOW_STEP;
        state.FEATURE_FLAG_ENABLE_UPLOAD_DOCUMENT_TO_WORKFLOW_STEP =
          payload.ENABLE_UPLOAD_DOCUMENT_TO_WORKFLOW_STEP;
        state.FEATURE_FLAG_ENABLE_NOTIFICATIONS_EXTRA =
          payload.ENABLE_NOTIFICATIONS_EXTRA;
      }
    );
  }
});

export default featureFlagSlice;
