import React from 'react';
import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as Dots } from 'assets/svg/dots-vertical.svg';
import { Chip } from 'components/chip';
import { SvgWrapper } from 'components/svg';
import _ from 'lodash';

interface AnalyticsDataTableProps {
  heading: string;
  subHeading: string;
  tableData: {
    id?: string;
    logo: string;
    name: string;
    value: string;
    quantity?: string;
    unit?: string;
  }[];
  optionsButton?: boolean;
}

const AnalyticsDataTable = ({
  heading,
  subHeading,
  tableData,
  optionsButton
}: AnalyticsDataTableProps) => {
  return (
    <Box
      sx={{
        border: '.064rem solid #F5F6F7',
        borderRadius: '.5rem',
        height: '100%',
        width: '100%',
        background: '#FFFFFF'
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: '1.25rem 1rem',
          borderBottom: '.064rem solid #F5F6F7'
        }}
      >
        <Stack direction="column">
          <Typography variant="bodyLargeSemibold" color="#4B535B">
            {heading}
          </Typography>
          <Typography variant="bodyMediumMedium" color="#98A2B3">
            {subHeading}
          </Typography>
        </Stack>

        {optionsButton && (
          <IconButton
            sx={{
              p: 0
            }}
          >
            <SvgWrapper
              height="1.5rem"
              width="1.5rem"
              icon={Dots}
              styleOverrides={{
                stroke: '#475467'
              }}
            />
          </IconButton>
        )}
      </Stack>

      <Stack
        direction="column"
        sx={{
          height: 'inherit',
          overflowY: 'auto',
          maxHeight: '24rem',
          mb: '1rem'
        }}
      >
        {tableData.map((element, index) => {
          const { logo, unit, quantity, value, name } = element;
          return (
            <Stack
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                borderBottom:
                  index === tableData.length - 1
                    ? 'none'
                    : '.064rem solid #F5F6F7',
                padding: '1rem .5rem'
              }}
            >
              <Stack direction="row" alignItems="center" gap=".5rem">
                <Avatar
                  src={logo}
                  sx={{
                    width: '1.75rem',
                    height: '1.75rem',
                    borderRadius: '1.75rem'
                  }}
                />
                <Stack direction="column">
                  <Typography variant="bodyMediumSemibold" color="#4B535B">
                    {_.truncate(name, { length: 25, omission: '...' })}
                  </Typography>
                  <Typography variant="bodyMediumMedium" color="#98A2B3">
                    {value}
                  </Typography>
                </Stack>
              </Stack>

              <Typography variant="bodyMediumSemibold" color="#4B535B">
                {unit}
              </Typography>

              {quantity && (
                <Chip label={`${quantity}`} size="sm" color="primary" icon />
              )}
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

export default AnalyticsDataTable;
