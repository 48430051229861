import React from 'react';
import { Box } from '@mui/material';

import WorkflowsBuilderNavbar from '../nav/workflowBuilderNavbar';
import WorkflowBuilderSideBar from '../nav/workflowsBuilderSidebar';
import WorkflowBuilderContextWrapper from '../context/builderContext';

import dotted from 'assets/png/dotted2.png';
import { removeItem } from 'utilities/helperFunc/localStore';

const WorkflowsBuilderLayout: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  window.onpopstate = () => {
    removeItem('customWorkflowOptions');
  };
  return (
    <WorkflowBuilderContextWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            top: '0',
            left: 0,
            width: '100%',
            height: '6%',
            zIndex: 2
          }}
        >
          <WorkflowsBuilderNavbar />
        </Box>
        <Box
          sx={{
            position: 'fixed',
            top: '6%',
            left: 0,
            width: '20%',
            borderRight: '0.094rem solid #F2F4F7',
            background: '#FFF',
            height: '94%'
          }}
        >
          <WorkflowBuilderSideBar />
        </Box>
        <Box
          sx={{
            display: 'flex',
            position: 'fixed',
            top: '6%',
            left: '20%',
            width: '80%',
            height: '94%',
            backgroundImage: `url(${dotted})`,
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'repeat',
            backgroundAttachment: 'fixed',
            backgroundSize: '175%'
          }}
        >
          {children}
        </Box>
      </Box>
    </WorkflowBuilderContextWrapper>
  );
};

export default WorkflowsBuilderLayout;
