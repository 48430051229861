import React from 'react';
import { Box } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { formattedNumber } from 'utilities/helperFunc/formatter';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

const Chart = () => {
  const data: ChartData<'line'> = {
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    datasets: [
      {
        label: 'This Product Receipts',
        data: [205, 215, 203, 300, 315, 396, 255, 412, 350, 512, 130, 810],
        borderColor: '#E75D65',
        borderWidth: 2,
        pointRadius: 0,
        pointStyle: 'circle',
        tension: 0.5
      },
      {
        label: 'Customer Average',
        data: [215, 255, 263, 270, 279, 226, 255, 445, 420, 590, 200, 780],
        borderColor: '#1671D9',
        borderWidth: 2,
        tension: 0.5,
        pointRadius: 0,
        pointStyle: 'circle'
      }
    ]
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
      axis: 'x'
    },
    plugins: {
      legend: {
        display: true,
        align: 'end',
        labels: {
          color: '#667085',
          boxHeight: 2,
          padding: 15,
          pointStyle: 'circle',
          usePointStyle: true,
          pointStyleWidth: 3,
          font: {
            family: 'Inter',
            size: 14,
            weight: 400
          }
        }
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        footerAlign: 'center',
        titleAlign: 'center',
        titleFont: {
          weight: 500
        },
        callbacks: {
          title: tooltipItems => {
            let sum = 0;
            tooltipItems.forEach(function (tooltipItem) {
              sum += tooltipItem.parsed.y;
            });
            return formattedNumber(sum, true);
          }
        }
      },
      title: {
        display: true,
        color: '#667085',
        text: 'Average Loan Repayment Time',
        font: {
          family: 'Inter',
          size: 16,
          weight: 600
        },
        align: 'start',
        padding: {
          top: 12,
          bottom: 14
        }
      }
    },
    scales: {
      x: {
        border: {
          display: false
        },
        grid: {
          display: false,
          tickColor: '#f3f3f3',
          tickLength: 10,
          tickWidth: 0
        },
        ticks: {
          padding: 3,
          maxTicksLimit: 12,
          stepSize: 1,
          font: {
            family: 'Inter',
            size: 12,
            weight: 400
          },
          align: 'center'
        },
        beginAtZero: true
      },
      y: {
        grid: {
          drawTicks: true,
          tickLength: 10,
          tickWidth: 0,
          tickColor: '#667085'
        },
        border: {
          dash: [6],
          display: false,
          color: '#EAECF0'
        },
        ticks: {
          padding: 0.5,
          color: '#667085',
          maxTicksLimit: 1000,
          stepSize: 200,
          callback: val => {
            return val;
          },
          align: 'center',
          font: {
            family: 'Inter',
            size: 12,
            weight: 400
          }
        }
      }
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%'
      }}
    >
      <Line options={options} data={data} />
    </Box>
  );
};

export default Chart;
