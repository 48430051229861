import { useState } from 'react';

interface SelectedFilter {
  id: string;
  value: string[];
}

const useFilterSelection = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);

  const handleFilterSelection = (id: string, value: string) => {
    setSelectedFilters(prev => {
      const existingFilter = prev.find(filter => filter.id === id);
      if (existingFilter) {
        const newValues = existingFilter.value.includes(value)
          ? existingFilter.value.filter(v => v !== value)
          : [...existingFilter.value, value];

        return prev.map(filter =>
          filter.id === id ? { ...filter, value: newValues } : filter
        );
      } else {
        return [...prev, { id, value: [value] }];
      }
    });
  };

  return {
    selectedFilters,
    handleFilterSelection
  };
};

export default useFilterSelection;
