import React, { PropsWithChildren, ReactNode } from 'react';
import { Box, Dialog, Stack, Typography } from '@mui/material';
import type { SxProps } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/svg/x.svg';
import { SvgWrapper } from 'components/svg';
import { Button } from 'components/button';
import type { ButtonProps } from 'components/button';
import _ from 'lodash';

interface ModalProps {
  text: string;
  subText: string;
  src: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  onClick: () => void;
  btnProps: Partial<ButtonProps>;
  open: boolean;
  onClose: () => void;
  modalOverrideProps?: SxProps;
  children?: ReactNode;
  cancelText?: string;
  stroke?: string;
  fill?: string;
  svgColor?: string;
}

const Modal = ({
  text,
  subText,
  src,
  onClick,
  btnProps,
  open,
  onClose,
  modalOverrideProps,
  children,
  cancelText,
  stroke,
  fill,
  svgColor
}: PropsWithChildren<ModalProps>) => {
  const modalStyle = {
    width: '100%',
    maxWidth: '24.5rem',
    borderRadius: '0.75rem',
    border: '.125rem solid #F5F6F7',
    backgroundColor: '#FCFCFD'
  };
  // If style overrides are passed then use to override existing styles
  if (modalOverrideProps) {
    _.assign(modalStyle, modalOverrideProps);
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': modalStyle
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1.25rem',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '.063rem solid #F5F6F7'
        }}
      >
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '2.25rem',
              width: '2.25rem',
              borderRadius: '6.25rem',
              backgroundColor: svgColor,
              padding: '0.5rem',
              marginBottom: '1rem'
            }}
          >
            <SvgWrapper
              icon={src}
              width="1.25rem"
              height="1.25rem"
              styleOverrides={{
                fill: fill,
                stroke: stroke
              }}
              color={svgColor}
            />
          </Box>
          <Box onClick={() => onClose()}>
            <SvgWrapper icon={CloseIcon} width="1.5rem" height="1.5rem" />
          </Box>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          <Typography variant="bodyLargeSemibold" color="#344054">
            {text}
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            {subText}
          </Typography>
        </Box>
      </Box>

      {children}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: '.063rem solid #F5F6F7',
          padding: '1.25rem 1.5rem',
          marginBottom: '0.25rem'
        }}
      >
        <Button
          color="grey"
          transparent
          size="lg"
          text={cancelText || 'Cancel'}
          width="48%"
          onClick={() => onClose()}
        />
        <Button
          submit
          color="primary"
          // This order is important because we want to
          // allow the color to be override
          {...btnProps}
          size="lg"
          width="48%"
          onClick={() => onClick()}
        />
      </Box>
    </Dialog>
  );
};

export default Modal;
