import { OrganizationType } from 'services/enums/organization';

export const vaultPageText: {
  [key in OrganizationType]?: {
    pageTitle: string;
    pageSubtitle: string;
    centralVaultSubtitle: string;
    branchVaultSubtitle: string;
    branchesViewSubtitle: string;
    searchPlaceholder: string;
  };
} = {
  merchant: {
    pageTitle: 'All Distributors',
    pageSubtitle: 'View all Distributors associated with your organization',
    centralVaultSubtitle:
      'See all Distributors with pending Product Receipts in your central vault.',
    branchVaultSubtitle:
      'See all Distributors with pending Product Receipts assigned to branch.',
    branchesViewSubtitle:
      'View all branches where this Distributor has pending Product Receipts.',
    searchPlaceholder: 'Search with Distributor name'
  },
  lender: {
    pageTitle: 'All Merchants',
    pageSubtitle: 'View all Merchants associated with your organization',
    centralVaultSubtitle:
      'See all Merchants with pending Product Receipts in your central vault.',
    branchVaultSubtitle:
      'See all Merchants with pending Product Receipts assigned to branch.',
    branchesViewSubtitle:
      'View all branches where this Merchant has pending Product Receipts.',
    searchPlaceholder: 'Search with Merchant name'
  },
  distributor: {
    pageTitle: 'All Merchants',
    pageSubtitle: 'View all Merchants associated with your organization',
    centralVaultSubtitle:
      'See all Merchants with pending Product Receipts in your central vault.',
    branchVaultSubtitle:
      'See all Merchants with pending Product Receipts assigned to branch.',
    branchesViewSubtitle:
      'View all branches where this Merchant has pending Product Receipts.',
    searchPlaceholder: 'Search with Merchant name'
  }
};
