import React from 'react';
import { theme } from 'themes/theme';
import { Stack, Toolbar, Typography } from '@mui/material';

const ErrorNav = () => {
  return (
    <Toolbar
      sx={{
        width: 'inherit',
        backgroundColor: `${theme.palette.common.appBackground}`,
        height: '4.25rem',
        borderBottom: `.063rem solid ${theme.palette.common.cardBorder}`,
        padding: {
          md: '.75rem 3rem'
        }
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: '0.75rem 1.8rem'
        }}
      >
        <Typography
          component="div"
          sx={{
            color: `${theme.palette.common.logoColor}`,
            fontSize: '1.25rem',
            fontWeight: '700',
            lineHeight: '1.5rem',
            letterSpacing: '-0.01875rem'
          }}
        >
          Flux
        </Typography>
      </Stack>
    </Toolbar>
  );
};

export default ErrorNav;
