import * as Yup from 'yup';

export const workflowTypeValidator = (isFormValid: string[]) =>
  Yup.object({
    workflowType: Yup.string().required('This field is required'),
    workflowUseCase: Yup.string().required('This field is required'),
    merchant: Yup.string().when('workflowType', {
      is: (workflowType: string) =>
        workflowType === 'custom' && isFormValid.includes('merchant'),
      then: schema =>
        schema.required('Please fill in at least one of the options')
    }),
    product: Yup.string().when('workflowType', {
      is: (workflowType: string) =>
        workflowType === 'custom' && isFormValid.includes('product'),
      then: schema =>
        schema.required('Please fill in at least one of the options')
    })
  });
