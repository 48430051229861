import React, { useState } from 'react';
import { fontFamily2, theme } from 'themes/theme';
import {
  passwordStrength,
  passwordStrengthValue
} from 'utilities/helperFunc/passwordStrength';
import {
  FormControl,
  FormLabel,
  TextField,
  Box,
  LinearProgress,
  Typography,
  IconButton,
  InputAdornment
} from '@mui/material';
import { useFormState, Controller } from 'react-hook-form';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import type { FormPasswordProps } from 'components/form/common/formTypes';
import type { FieldValues } from 'react-hook-form';

import _ from 'lodash';

export default function FormPassword<FormValueT extends FieldValues>(
  props: FormPasswordProps<FormValueT>
) {
  const [score, setScore] = useState('');
  const [progress, setProgress] = useState(0);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { label, name, control, checkPassword, ...rest } = props;

  const { errors } = useFormState({ control });

  let passwordError;
  if ('password' in errors) {
    passwordError = errors['password'];
  }

  const handlePasswordChange = (value: string) => {
    if (checkPassword) {
      const { validConditions } = passwordStrength(value);
      setProgress(validConditions);
      setScore(passwordStrengthValue(validConditions));
    }
  };

  return (
    <FormControl
      fullWidth
      sx={{
        paddingBottom: { xs: 3 },
        '& .css-13nvzlm-MuiFormHelperText-root': {
          fontSize: '.813rem',
          ml: 0
        }
      }}
    >
      <FormLabel
        htmlFor={name}
        sx={{
          fontFamily: fontFamily2,
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: '1.05rem',
          letterSpacing: '-0.015rem',
          color: `${theme.palette.common.labelColor}`,
          mb: '0.25rem'
        }}
      >
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { isTouched, invalid } }) => (
          <TextField
            error={invalid && isTouched}
            id={name}
            type={!showPassword ? 'password' : 'text'}
            InputProps={{
              autoComplete: 'off',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
            {...field}
            {...rest}
            onChange={e => {
              field.onChange(e);
              handlePasswordChange(e.target.value);
            }}
            FormHelperTextProps={{
              sx: {
                ml: '0rem',
                mr: '0rem',
                fontFamily: fontFamily2,
                fontSize: '.875rem',
                fontWeight: 400,
                lineHeight: '1.188rem',
                letterSpacing: '-0.025em',
                width: '100%',
                textAlign: 'left',
                color: `${theme.palette.common.error}`
              }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                // height: '2.75rem',
                height: '3.2rem',
                padding: '0.75rem',
                borderRadius: '0.5rem',
                border: '.094rem solid #EAECF0',
                background: '#FEFFFF',
                boxShadow: '0px 4px 4px 0px rgba(220, 220, 220, 0.02)',
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  borderColor: 'transparent'
                },
                '&.Mui-focused fieldset': {
                  borderColor: `${theme.palette.common.btnColor}`,
                  boxShadow: `${theme.palette.common.boxShadow}`
                }
              }
            }}
          />
        )}
      />

      {checkPassword && !!passwordError && (
        <Box sx={{ mt: '.5rem' }}>
          <LinearProgress
            variant="determinate"
            value={25 * progress}
            sx={{
              height: '.75rem',
              borderRadius: '1.25rem',
              mb: '.5rem',
              backgroundColor: `${theme.palette.common.progressBackground}`,
              '.MuiLinearProgress-bar1Determinate': {
                backgroundColor: `${theme.palette.common.Blue}`
              }
            }}
          />
          <Typography
            sx={{
              color: '#475467',
              fontFamily: fontFamily2,
              fontSize: '0.75rem',
              fontWeight: 600,
              lineHeight: '1.08rem',
              letterSpacing: '-0.01125rem'
            }}
          >
            Password strength: {score || 'Weak.'}{' '}
            <span style={{ fontWeight: 400 }}>
              {_.toString(passwordError?.message)}
            </span>
          </Typography>
        </Box>
      )}
    </FormControl>
  );
}
