import React from 'react';
import waves from 'assets/svg/waves.svg';
import Industry from 'assets/png/Gradientindustry.png';
import defaultLogo from 'assets/custom-svg/logo.svg';
import { theme } from 'themes/theme';
import { Grid, Box, Typography } from '@mui/material';
import { useMatch } from 'react-router-dom';
import AuthNav from 'pages/auth/common/authNav';

const AuthLayout: React.FunctionComponent<React.PropsWithChildren> = ({
  children
}) => {
  const isCompleteRegistration = useMatch('/complete-registration');
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        backgroundImage: `url(${waves})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        background: `${theme.palette.common.authBackground}`
      }}
    >
      <Box
        sx={{
          width: '25%',
          height: '100vh',
          backgroundImage: `url(${Industry})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          color: 'white',
          padding: '2rem 2rem 3rem 1.6rem',
          textAlign: 'center',
          position: 'relative'
        }}
      >
        <Box
          component="img"
          src={defaultLogo}
          sx={{
            position: 'absolute',
            top: '2.4375rem',
            left: '1.5rem',
            width: '2.8125rem',
            height: 'auto'
          }}
        />

        <Typography
          variant="display02Bold"
          sx={{
            textAlign: 'left',
            marginTop: 'auto'
          }}
        >
          Powering the Future of African Businesses
        </Typography>

        <Typography
          variant="bodyOnboardingsmall"
          sx={{
            textAlign: 'left'
          }}
        >
          Building innovative systems that empower Nigerian and African
          Businesses to scale
        </Typography>
      </Box>

      <Box
        sx={{
          width: '75%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '2rem'
        }}
      >
        {isCompleteRegistration && <AuthNav />}
        {children}
      </Box>
    </Grid>
  );
};

export default AuthLayout;
