import React from 'react';
import {
  TableHead as MUITableHead,
  TableRow,
  TableCell,
  Typography,
  Box,
  Avatar
} from '@mui/material';
import { Table, flexRender } from '@tanstack/react-table';
import chevronUpIcon from 'assets/svg/chevron-up.svg';
import chevronDownIcon from 'assets/svg/chevron-down.svg';
import chevronUpDownIcon from 'assets/svg/chevron-up-down.svg';
import { IndeterminateCheckbox } from './tableButtons';

interface TableHeadProps<TableDataT> {
  table: Table<TableDataT>;
  optionsButton?: boolean;
}

export default function TableHeader<TableDataT>({
  table,
  optionsButton
}: TableHeadProps<TableDataT>) {
  const getSortingDisplay = (state: boolean | string) => {
    if (state === 'asc') {
      return (
        <Avatar
          src={chevronUpIcon}
          sx={{ width: '1rem', height: '1rem', color: '#667085' }}
        />
      );
    } else if (state === 'desc') {
      return (
        <Avatar
          src={chevronDownIcon}
          sx={{ width: '1rem', height: '1rem', color: '#667085' }}
        />
      );
    } else {
      return (
        <Avatar
          src={chevronUpDownIcon}
          sx={{ width: '1rem', height: '1rem', color: '#667085' }}
        />
      );
    }
  };

  return (
    <MUITableHead
      sx={{
        backgroundColor: '#FCFCFD',
        borderBottom: '.063rem solid #F2F4F7',
        height: '2.75rem'
      }}
    >
      {table.getHeaderGroups().map(headerGroup => (
        <TableRow key={headerGroup.id}>
          <TableCell
            sx={{
              paddingY: '0rem',
              color: '#98A2B3',
              borderBottom: 'none',
              width: '2.75rem',
              left: '0rem',
              opacity: 1,
              position: 'sticky',
              zIndex: 1,
              backgroundColor: '#FCFCFD'
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler()
              }}
            />
          </TableCell>
          {headerGroup.headers.map(header => (
            <TableCell
              key={header.id}
              sx={{
                paddingY: '0rem',
                color: '#98A2B3',
                borderBottom: '.063rem solid #F2F4F7',
                width: `${header.getSize() / 16}rem`,
                zIndex: 0,
                position: 'relative',
                ...(header.column.columnDef.enablePinning && {
                  left: '2.5rem',
                  opacity: 1,
                  position: 'sticky',
                  zIndex: 1,
                  backgroundColor: '#FCFCFD',
                  boxShadow: '-2px 0 2px -2px lightgray inset'
                })
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: 'inherit',
                  alignItems: 'center'
                }}
              >
                <Typography
                  color="#98A2B3"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    fontStyle: 'normal',
                    lineHeight: '1.0875rem',
                    letterSpacing: '-0.0225rem',
                    textTransform: 'uppercase'
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Typography>
                <Box
                  onClick={header.column.getToggleSortingHandler()}
                  sx={{ marginLeft: '.188rem', cursor: 'pointer' }}
                >
                  {getSortingDisplay(header.column.getIsSorted())}
                </Box>
              </Box>
            </TableCell>
          ))}
          {optionsButton && (
            <TableCell
              sx={{
                paddingY: '0rem',
                color: '#98A2B3',
                borderBottom: '.063rem solid #F2F4F7',
                right: '0rem',
                opacity: 0.95,
                position: 'sticky',
                width: '3.125rem',
                zIndex: 1
              }}
            />
          )}
        </TableRow>
      ))}
    </MUITableHead>
  );
}
