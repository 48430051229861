import React, { useContext } from 'react';
import {
  Box,
  Breadcrumbs,
  Typography,
  SvgIcon,
  Toolbar,
  Stack,
  Button as MUIButton
} from '@mui/material';
import arrow from 'assets/svg/chevron-right.svg';
import { theme } from 'themes/theme';
import { OnboardingContext } from 'pages/onboarding/context/onboardingContext';
import logo from 'assets/custom-svg/flux-logo.svg';

const OnboardingNav = () => {
  const { currentStep, goToStep } = useContext(OnboardingContext);

  const showChecked = (
    isCurrent: boolean,
    isPrev: boolean,
    isNext: boolean,
    element: CrumbProps,
    index: number
  ) => {
    switch (isCurrent || isPrev || isNext) {
      case isCurrent:
        return (
          <MUIButton
            key={index}
            variant="text"
            sx={{
              textTransform: 'none',
              backgroundColor: `${isCurrent ? '#E9F0FE' : '#ffffff'}`,
              border: '.094rem solid #ffffff'
            }}
            onClick={() => {
              isNext ? null : goToStep(element.id);
            }}
          >
            <Typography
              variant="bodyMediumMedium"
              sx={{
                color: `${isCurrent ? '#3E7DF8' : '#98A2B3'}`
              }}
            >
              {element.text}
            </Typography>
          </MUIButton>
        );
      case isPrev:
        return (
          <MUIButton
            key={index}
            variant="text"
            sx={{
              textTransform: 'none',
              backgroundColor: '#ffffff',
              border: '.094rem solid #ffffff'
            }}
            onClick={() => {
              isNext ? null : goToStep(element.id);
            }}
            startIcon={
              <SvgIcon>
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="0.5" width="16" height="16" rx="8" fill="#1671D9" />
                  <g clipPath="url(#clip0_2441_467)">
                    <path
                      d="M12.0556 5.33301L7.16666 10.2219L4.94444 7.99967"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2441_467">
                      <rect
                        width="10.6667"
                        height="10.6667"
                        fill="white"
                        transform="translate(3.16667 2.6665)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </SvgIcon>
            }
          >
            <Typography
              variant="bodyMediumMedium"
              sx={{
                color: '#98A2B3'
              }}
            >
              {element.text}
            </Typography>
          </MUIButton>
        );
      case isNext:
        return (
          <Typography
            key={index}
            variant="bodyMediumMedium"
            sx={{
              color: '#98A2B3'
            }}
          >
            {element.text}
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Toolbar
      sx={{
        width: 'inherit',
        backgroundColor: `${theme.palette.common.white}`,
        height: '3.75rem',
        borderBottom: '.063rem solid #FCFCFD'
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: '0.75rem 1.8rem'
        }}
      >
        <Stack
          direction="row"
          gap={0.5}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            component="img"
            src={logo}
            sx={{ width: '1.8rem', height: '1.8rem' }}
          />
          <Typography
            component="div"
            sx={{
              color: `${theme.palette.common.logoColor}`,
              fontSize: '1.25rem',
              fontWeight: '700',
              lineHeight: '1.5rem',
              letterSpacing: '-0.01875rem'
            }}
          >
            Flux
          </Typography>
        </Stack>

        <Breadcrumbs
          sx={{
            mr: {
              md: 12,
              lg: 40
            }
          }}
          separator={<Box component="img" src={arrow} />}
          aria-label="breadcrumb"
        >
          {crumbs.map((element, index) => {
            const isCurrent = currentStep === element.id;
            const isPrev = index === element.id - 1 && index < currentStep;
            const isNext = index > currentStep;
            return showChecked(isCurrent, isPrev, isNext, element, index);
          })}
        </Breadcrumbs>
      </Stack>
    </Toolbar>
  );
};

export default OnboardingNav;

type CrumbProps = {
  id: number;
  text: string;
};

const crumbs: CrumbProps[] = [
  {
    id: 1,
    text: 'Product Information'
  },
  {
    id: 2,
    text: 'ID Verification'
  },
  {
    id: 3,
    text: 'Setup Organization'
  },
  {
    id: 4,

    text: 'Invite to organization'
  }
];
