import React, { useState } from 'react';
import { Box, MenuItem, Checkbox, Stack, Typography } from '@mui/material';
import { Column, ColumnFilter, Table } from '@tanstack/react-table';
import _ from 'lodash';

import { Button } from 'components/button';
import SearchableContainer from 'components/search/searchableContainer';
import { Checked, UnChecked } from '../subComponents/tableButtons';
import useFilterSelection from './hooks/useFilterSelection';
import FilterNumberInput from './subComponents/filterNumberInput';

interface FilterProps<TableDataT> {
  setAnchorFilterEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  setFilterPopup: React.Dispatch<React.SetStateAction<boolean>>;
  filterTabs?: string[];
  table: Table<TableDataT>;
}

export default function FilterModal<TableDataT>({
  setFilterPopup,
  setAnchorFilterEl,
  table,
  filterTabs = []
}: FilterProps<TableDataT>) {
  const [filterId, setFilterId] = useState<string>(filterTabs[0] ?? '');
  const { selectedFilters, handleFilterSelection } = useFilterSelection();

  const tableData = table.getPreFilteredRowModel().flatRows;

  // To check typeof row first value
  const stringDataType = () => {
    if (filterId && tableData.length > 0) {
      const value = tableData[0].getValue(filterId);
      if (typeof value === 'object' && value !== null && 'name' in value) {
        return typeof value.name === 'string';
      }

      return typeof value === 'string';
    }
  };

  const dataForSearchableContainer = tableData
    .map(item => {
      const value = item.original[filterId as keyof TableDataT];
      if (typeof value === 'object' && value !== null && 'name' in value) {
        return value.name;
      }

      if (typeof value === 'string') {
        return value;
      }

      return undefined;
    })
    .filter((value): value is string => value !== undefined);

  const renderData = (item: string) => {
    const isSelected = selectedFilters.some(
      filter => filter.id === filterId && filter.value.includes(item)
    );
    return (
      <Box
        sx={{
          backgroundColor: '#FCFCFD',
          borderRadius: '0.75rem',
          border: '0.0625rem solid #F5F6F',
          width: '100%',
          display: 'flex',
          padding: '0.75rem',
          alignItems: 'center',
          gap: '0.75rem',
          mb: '1rem'
        }}
        onClick={() => handleFilterSelection(filterId, item)}
      >
        <Checkbox
          sx={{
            width: '1.25rem',
            height: '1.25rem'
          }}
          checked={isSelected}
          checkedIcon={<Checked />}
          icon={<UnChecked />}
        />
        {item}
      </Box>
    );
  };

  const applyFilter = () => {
    const filtersToApply: ColumnFilter[] = [];
    selectedFilters.forEach(filter => {
      filter.value.forEach(value => {
        filtersToApply.push({ id: filter.id, value });
      });
    });

    selectedFilters.map(el => {
      return table.getColumn(el.id)?.setFilterValue(el.value);
    });

    setFilterPopup(false);
    setAnchorFilterEl(null);
  };

  return (
    <Box
      width="30rem"
      height="25rem"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Stack
        direction="column"
        sx={{
          width: '30%',
          borderRight: '.0625rem solid #F5F6F7',
          maxHeight: 'inherit',
          overflowY: 'auto',
          padding: '0.75rem'
        }}
      >
        {filterTabs &&
          filterTabs.map((element: string, index: number) => {
            const isActiveTab = filterId === filterTabs[index];
            return (
              <MenuItem
                key={index}
                sx={{
                  padding: '.5rem .75rem',
                  height: '2.25rem',
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '.5rem'
                }}
                onClick={() => setFilterId(element)}
              >
                <Typography
                  variant="bodyMediumMedium"
                  color={`${isActiveTab ? '#344054' : '#98A2B3'}`}
                >
                  {_.startCase(`${element as string}`.replace(/_/g, ' '))}
                </Typography>
              </MenuItem>
            );
          })}
      </Stack>

      <Stack
        direction="column"
        display="flex"
        justifyContent="space-between"
        sx={{
          padding: '0.75rem',
          width: '70%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%'
          }}
        >
          {stringDataType() ? (
            <SearchableContainer<string>
              searchPlaceholderText="Search"
              data={Array.from(new Set(dataForSearchableContainer))}
              renderDataList={renderData}
              searchField={[filterId]}
              containerStyles={{
                maxHeight: '15rem',
                overflowY: 'auto',
                mt: '1.5rem'
              }}
            />
          ) : (
            <Stack width="100%" direction="column">
              <Stack width="100%" gap=".75rem" direction="row">
                <Box
                  width="100%"
                  gap=".75rem"
                  display="flex"
                  flexDirection="row"
                  mb="1.5rem"
                >
                  <FilterNumberInput
                    type="number"
                    label="from"
                    placeholder={`Min ${
                      table
                        .getColumn(filterId as keyof Column<TableDataT>)
                        ?.getFacetedMinMaxValues()?.[1]
                        ? `(${
                            table
                              .getColumn(filterId as keyof Column<TableDataT>)
                              ?.getFacetedMinMaxValues()?.[1]
                          })`
                        : ''
                    }`}
                    value={
                      (
                        table
                          .getColumn(filterId as keyof Column<TableDataT>)
                          ?.getFilterValue() as [number, number]
                      )?.[0] ?? ''
                    }
                    onChange={value =>
                      table
                        .getColumn(filterId as keyof Column<TableDataT>)
                        ?.setFilterValue((old: [number, number]) => [
                          value,
                          old?.[1]
                        ])
                    }
                  />
                  <FilterNumberInput
                    type="number"
                    label="to"
                    placeholder={`Max ${
                      table
                        .getColumn(filterId as keyof Column<TableDataT>)
                        ?.getFacetedMinMaxValues()?.[1]
                        ? `(${
                            table
                              .getColumn(filterId as keyof Column<TableDataT>)
                              ?.getFacetedMinMaxValues()?.[1]
                          })`
                        : ''
                    }`}
                    value={
                      (
                        table
                          .getColumn(filterId as keyof Column<TableDataT>)
                          ?.getFilterValue() as [number, number]
                      )?.[0] ?? ''
                    }
                    onChange={value =>
                      table
                        .getColumn(filterId as keyof Column<TableDataT>)
                        ?.setFilterValue((old: [number, number]) => [
                          value,
                          old?.[1]
                        ])
                    }
                  />
                </Box>
              </Stack>
              <Box
                sx={{
                  maxHeight: '15rem',
                  overflowY: 'auto',
                  mt: '1.5rem'
                }}
              ></Box>
            </Stack>
          )}
        </Box>

        <Box width="100%" mt="1.5rem">
          <Button
            color="primary"
            size="lg"
            onClick={applyFilter}
            text="Apply Filter"
            width="inherit"
            disabled={selectedFilters.length === 0}
          />
        </Box>
      </Stack>
    </Box>
  );
}
