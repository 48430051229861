import React, { useMemo, useState } from 'react';
import { Paper, Stack } from '@mui/material';
import { Chip } from 'components/chip';
import SearchableContainer from 'components/search/searchableContainer';
import { useGetAllReportsQuery } from 'services/api/analyticsApi';
import { ReportsApiData } from 'services/types/analytics';
import { formattedDate } from 'utilities/helperFunc/formatter';
import ReportsDocumentCard from './documentCard';

type ReportsPageProps = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

const ReportsPage = ({ startDate, endDate }: ReportsPageProps) => {
  const [activeChip, setActiveChip] = useState<'completed' | 'in_progress'>(
    'completed'
  );

  const { data: reportsData } = useGetAllReportsQuery({
    params: {
      start:
        startDate !== undefined
          ? formattedDate(startDate, 'YYYY-MM-DD')
          : undefined,
      end:
        endDate !== undefined ? formattedDate(endDate, 'YYYY-MM-DD') : undefined
    }
  });

  const filteredReportsData = useMemo(() => {
    return (
      (reportsData && reportsData.filter(item => item.status === activeChip)) ||
      []
    );
  }, [reportsData, activeChip]);

  const renderDocumentData = (item: ReportsApiData, index?: number) => {
    return (
      <ReportsDocumentCard
        name={item.name}
        file={item.file}
        status={item.status}
        generated_by={item.generated_by}
        generated_on={item.generated_on}
        generation_time={item.generation_time}
        onDelete={(name: string) => console.log(name)}
        isLast={index === filteredReportsData.length - 1}
        isFirst={index == 0}
      />
    );
  };

  const handleChipClick = (status: 'completed' | 'in_progress') => {
    setActiveChip(status);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '1.25rem',
        borderRadius: '0.75rem',
        backgroundColor: '#FFF',
        position: 'relative',
        height: '100%',
        border: '.0625rem solid #F5F6F7'
      }}
    >
      <SearchableContainer<ReportsApiData>
        searchPlaceholderText={'Search reports using name'}
        data={filteredReportsData}
        renderDataList={renderDocumentData}
        searchField={['name']}
        containerStyles={{
          maxHeight: '60svh',
          overflowY: 'auto',
          width: '100%',
          borderTop: 1,
          borderColor: '#F5F6F7'
        }}
        listStylesOverride={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '.75rem',
          padding: {
            md: '0rem 0.75rem',
            xl: '0rem 1.5rem'
          },
          borderRadius: '0.75rem',
          backgroundColor: '#FCFCFD',
          mt: '1.5rem'
        }}
        headerButtonPosition="start"
        headerButton={
          <Stack direction="row" gap="1rem">
            <Chip
              label="Complete"
              color={activeChip === 'completed' ? 'primary' : 'default'}
              size="md"
              onClick={() => handleChipClick('completed')}
            />
            <Chip
              label="In progress"
              color={activeChip === 'in_progress' ? 'primary' : 'default'}
              size="md"
              onClick={() => handleChipClick('in_progress')}
            />
          </Stack>
        }
        enableEmptyData
      />
    </Paper>
  );
};

export default ReportsPage;
