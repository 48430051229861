import { OrganizationType } from 'services/enums/organization';

export const historyPageText: {
  [key in OrganizationType]?: {
    pageSubtitle: string;
    searchPlaceholder: string;
  };
} = {
  merchant: {
    pageSubtitle:
      'See history of all Product Receipts issued by your organization',
    searchPlaceholder: 'Search with Distributor name'
  },
  lender: {
    pageSubtitle:
      'See history of all Product Receipts received by your organization',
    searchPlaceholder: 'Search with Merchant name'
  },
  distributor: {
    pageSubtitle:
      'See history of all Product Receipts received by your organization',
    searchPlaceholder: 'Search with Merchant name'
  }
};
