import React, { useCallback, useState, useEffect } from 'react';
import { Box, Typography, Avatar, LinearProgress } from '@mui/material';
import fileupload from 'assets/svg/fileupload.svg';
import fileType from 'assets/svg/file.svg';
import error from 'assets/svg/error.svg';
import { ReactComponent as RedoIcon } from 'assets/svg/redo.svg';
import { FileRejection, useDropzone } from 'react-dropzone';
import type { Accept } from 'react-dropzone';
import { fontFamily2, theme } from 'themes/theme';
import Divider from './divider';
import { Button } from 'components/button';

interface FileProps {
  maxFiles: number;
  maxSize: number;
  supportedFiles: Accept;
  showBtn?: boolean;
  onFileUpload: (files: File[]) => void;
}

const FileUpload = ({
  onFileUpload,
  maxFiles,
  maxSize,
  supportedFiles,
  showBtn
}: FileProps) => {
  const [progress, setProgress] = useState<number>(0);
  const [uploadSucceeded, setUploadSucceeded] = useState<boolean>(false);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  useEffect(() => {
    if (progress < 100) {
      const progressCount = setInterval(() => {
        setProgress(prev => prev + 10);
      }, 200);

      return () => {
        clearInterval(progressCount);
      };
    } else {
      setProgress(0);
    }
  }, [progress]);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length !== 0) {
        setUploadSucceeded(false);
        setRejectedFiles(rejectedFiles);
      }
      if (acceptedFiles.length > 0) {
        // TODO Task #437
        onFileUpload(acceptedFiles);
      }
      setUploadSucceeded(true);
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: supportedFiles,
    multiple: true,
    maxSize: maxSize,
    maxFiles: maxFiles
  });

  return (
    <div
      {...getRootProps({
        style: {
          display: 'flex',
          height: 'inherit',
          width: 'inherit',
          padding: '1.75rem 1.5rem',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.75rem',
          borderRadius: '1rem',
          border: '.094rem dashed',
          borderColor: `${
            isDragActive ? '#0E68BB' : uploadSucceeded ? '#E4E8EF' : '#E26E6A'
          }`,
          backgroundColor: `${isDragActive ? '#F7FBFF' : '#FEFFFF'}`
        }
      })}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: `${fontFamily2}`,
            width: '100%',
            height: 'inherit',
            padding: '3.5rem 0rem',
            textAlign: 'center'
          }}
        >
          <Box
            component={'img'}
            src={fileType}
            sx={{ width: '2.8rem', height: '2.8rem' }}
          />
          <Typography
            variant="bodyMediumMedium"
            color="text.secondary"
          >{`${Math.round(progress)}%`}</Typography>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: '.75rem',
              borderRadius: '1.25rem',
              mb: '.5rem',
              width: 'inherit',
              backgroundColor: `${theme.palette.common.progressBackground}`,
              '.MuiLinearProgress-bar1Determinate': {
                backgroundColor: '#0E68BB'
              }
            }}
          />
          <Typography variant="subtitle2" color="text.secondary">
            Uploading document...
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: `${fontFamily2}`,
            width: '100%'
          }}
        >
          {uploadSucceeded ? (
            <Avatar
              src={fileupload}
              sx={{ width: '3.5rem', height: '3.5rem' }}
            />
          ) : (
            <Avatar src={error} sx={{ width: '3.5rem', height: '3.5rem' }} />
          )}

          <Box sx={{ mt: '1rem', textAlign: 'center' }}>
            <Typography variant="bodyMediumMedium">
              {uploadSucceeded ? (
                <>
                  <span
                    style={{
                      color: '#3E7DF8',
                      fontWeight: 600,
                      lineHeight: '1.26rem',
                      letterSpacing: '-0.01313rem'
                    }}
                  >
                    Click to Upload{' '}
                  </span>{' '}
                  <span
                    style={{
                      color: '#475467',
                      fontWeight: 400,
                      lineHeight: '1.2075rem',
                      letterSpacing: '-0.02188rem'
                    }}
                  >
                    or drag and drop
                  </span>{' '}
                </>
              ) : (
                'Failed to upload'
              )}
            </Typography>

            <Typography
              style={{
                color: '#98A2B3',
                fontSize: '0.75rem',
                fontWeight: 400,
                lineHeight: '1.05rem',
                letterSpacing: '-0.01125rem'
              }}
            >
              {uploadSucceeded && rejectedFiles
                ? `Supported files are ${supportedFiles}`
                : 'Error uploading files'}
            </Typography>
          </Box>

          {showBtn && uploadSucceeded && (
            <Divider
              mt={'1rem'}
              width={'inherit'}
              fontWeight={600}
              fontSize={'0.75rem'}
              lineHeight={'1.0875rem'}
            />
          )}

          {showBtn &&
            (uploadSucceeded ? (
              <Button
                color="primary"
                transparent
                size="md"
                iconPosition="start"
                text="Browse Files"
              />
            ) : (
              <Button
                color="error"
                transparent
                size="md"
                icon={RedoIcon}
                iconPosition="start"
                text="Try again"
              />
            ))}
        </Box>
      )}
    </div>
  );
};

export default FileUpload;
