import React from 'react';
import { AvatarGroup as MuiAvatarGroup, Avatar, Box } from '@mui/material';

interface AvatarGroupProps {
  srcs: string[];
  type: 'img' | 'text';
  maxShown: number;
  size: 'sm' | 'md' | 'lg';
}

const AvatarGroup: React.FC<AvatarGroupProps> = ({
  srcs,
  type,
  maxShown,
  size
}) => {
  const getStyleForSizeProp = (size: AvatarGroupProps['size']) => {
    let sizeStyles = {};
    if (size === 'sm') {
      sizeStyles = {
        fontSize: '0.5rem',
        width: '1.5rem',
        height: '1.5rem'
      };
    } else if (size === 'md') {
      sizeStyles = {
        width: '2rem',
        height: '2rem',
        fontSize: '1rem'
      };
    } else {
      sizeStyles = {
        width: '2.5rem',
        height: '2.5rem',
        fontSize: '1.25rem'
      };
    }
    return sizeStyles;
  };

  const style = {
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiAvatarGroup-avatar': {
      display: 'flex',
      justifyContent: 'center',
      color: '#344054',
      textAlign: 'center',
      alignItems: 'center',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '1.08rem',
      letterSpacing: '-0.01875rem',
      borderRadius: '12.5rem',
      border: '.094rem solid #FFF',
      background: '#ECF2FE',
      zIndex: 'initial !important',
      ...getStyleForSizeProp(size)
    }
  };

  return (
    <Box display="flex" alignItems="flex-start">
      <MuiAvatarGroup total={srcs.length} max={maxShown} sx={style}>
        {type === 'img' &&
          srcs.map((url, index) => <Avatar key={index} src={url} />)}
        {type === 'text' &&
          srcs.map((text, index) => <Avatar key={index}>{text}</Avatar>)}
      </MuiAvatarGroup>
    </Box>
  );
};

export default AvatarGroup;
