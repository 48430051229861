import React, { useState, useEffect } from 'react';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { ReactComponent as Credit } from 'assets/custom-svg/credit-risk.svg';
import { ReactComponent as Check } from 'assets/svg/checkSolid.svg';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import { ReactComponent as Redo } from 'assets/svg/redo.svg';
import { SvgWrapper } from 'components/svg';
import { Button } from 'components/button';

type CreditRiskCheckProps = {
  onClick: () => void;
  loading: boolean;
};

const CreditRiskCheck = ({ onClick, loading }: CreditRiskCheckProps) => {
  const [progress, setProgress] = useState<number>(0);
  const [creditRisk, setCreditRisk] = useState<boolean | null>(null);

  useEffect(() => {
    if (progress < 100) {
      const progressCount = setInterval(() => {
        setProgress(prev => {
          const newProgress = prev + 10;
          if (newProgress === 100) {
            clearInterval(progressCount);
            const result = checkCreditRisk();
            setCreditRisk(result);
          }
          return newProgress;
        });
      }, 500);

      return () => clearInterval(progressCount);
    }
  }, [progress]);

  const checkCreditRisk = () => {
    const checkArr = [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false
    ];
    const randomCreditRisk = Math.floor(Math.random() * checkArr.length);
    return checkArr[randomCreditRisk];
  };

  const checkAgain = () => {
    setCreditRisk(null);
    setProgress(0);
  };

  return (
    <Stack
      direction="column"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        sx={{
          display: 'flex',
          width: '3.25rem',
          height: '3.25rem',
          padding: '0.125rem',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '1.25rem',
          background: '#FFF',
          boxShadow: '0px 1.5px 4px -1px rgba(20, 28, 40, 0.05)'
        }}
      >
        <SvgWrapper
          icon={creditRisk === null ? Credit : creditRisk ? Check : Close}
          width={creditRisk !== null ? '1.5rem' : '3rem'}
          height={creditRisk !== null ? '1.5rem' : '3rem'}
          styleOverrides={{
            fill: creditRisk !== null && creditRisk ? '#19956C' : '#A0252D'
          }}
        />
      </Box>

      <Stack
        direction="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt=".5rem"
      >
        <Typography variant="h7Semibold" color="#475467">
          {creditRisk === null
            ? 'Checking Credit Risk..'
            : creditRisk
              ? 'No credit risk found!'
              : 'Credit risk found!'}
        </Typography>

        <Box width="23rem" textAlign="center" mb=".5rem">
          <Typography variant="bodyMediumMedium" color="#98A2B3">
            {creditRisk === null
              ? 'To proceed with the workflow, we need to verify customers credit status.'
              : creditRisk
                ? 'Hamalaya group has no active loan repayments. You can proceed with workflow.'
                : 'Hamalaya group has active loan repayments. You cannot assign this Product Receipt till loan repayment is complete.'}
          </Typography>
        </Box>

        {creditRisk !== null ? (
          <Button
            color={creditRisk ? 'success' : 'error'}
            transparent
            size="md"
            text={creditRisk ? 'Proceed' : 'Repeat Credit Check'}
            icon={creditRisk ? undefined : Redo}
            iconPosition="start"
            styleOverrides={{ padding: '0.5rem 0.75rem' }}
            onClick={creditRisk ? onClick : checkAgain}
            loadingIcon={creditRisk === true ? loading : undefined}
          />
        ) : (
          <Box
            width="100%"
            display="flex"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <LinearProgress
              variant="determinate"
              color="inherit"
              value={progress}
              sx={{
                height: '.35rem',
                borderRadius: '.5rem',
                color: '#F2F4F7',
                '.MuiLinearProgress-bar1Determinate': {
                  backgroundColor: '#3E7DF8'
                },
                width: '90%'
              }}
            />

            <Typography
              variant="bodySmallSemibold"
              color="text.secondary"
            >{`${Math.round(progress)}%`}</Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
export default CreditRiskCheck;
