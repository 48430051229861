import { FeatureFlagsApiData } from 'services/types/config';
import { api } from './baseApi';

export const featureFlagsApi = api.injectEndpoints({
  endpoints: builder => ({
    getFeatureFlags: builder.query<FeatureFlagsApiData, unknown>({
      query: () => {
        return {
          url: 'config/feature-flags/',
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      providesTags: [{ type: 'featureFlag' }],
      keepUnusedDataFor: 3600
    })
  })
});

export const { useGetFeatureFlagsQuery, usePrefetch } = featureFlagsApi;
