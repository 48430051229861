import React, { useContext } from 'react';
import { ReactComponent as Merchant } from 'assets/svg/merchants.svg';
import { ReactComponent as Bank } from 'assets/svg/bank.svg';
import { ReactComponent as Truck } from 'assets/svg/truck.svg';
import { ReactComponent as User } from 'assets/svg/user.svg';
import { Button } from 'components/button';
import { theme } from 'themes/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { useForm, Resolver, SubmitHandler } from 'react-hook-form';
import { OnboardingContext } from '../context/onboardingContext';
import { orgStepFormValidator1 } from '../validators/onboardingValidators';
import { FormRadio } from 'components/form';

type FormValues = {
  organization_type: string;
};

const SelectUser = () => {
  const { handleNextStep, data } = useContext(OnboardingContext);

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      organization_type: data.organization_type
    },
    resolver: yupResolver(
      orgStepFormValidator1
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = data => {
    handleNextStep(data);
  };

  return (
    <Box sx={{ width: { lg: '29.25rem' } }}>
      <Box width="inherit" sx={{ mb: '2rem', textAlign: 'center' }}>
        <Typography
          variant="h5"
          sx={{
            color: `${theme.palette.common.heading}`
          }}
        >
          Getting Started
        </Typography>
        <Typography
          variant="bodyMediumRegular"
          sx={{
            color: `${theme.palette.common.labelColor}`
          }}
        >
          Choose what type of user you are to help us customize your dashboard
        </Typography>
      </Box>

      <Box width="inherit">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormRadio<FormValues>
            control={control}
            name="organization_type"
            label=""
            radioData={radioData}
          />

          <Button
            submit
            color="primary"
            size="lg"
            disabled={!isValid}
            text="Next"
            width="100%"
            styleOverrides={{ marginBottom: '0.25rem' }}
          />
        </form>
      </Box>
    </Box>
  );
};

export default SelectUser;

const radioData = [
  {
    image: Merchant,
    heading: 'Merchants',
    value: 'merchant',
    subHeading: 'Create, issue and manage Product receipts'
  },
  {
    image: Bank,
    heading: 'Lenders',
    value: 'lender',
    subHeading:
      'Secure and finance Product receipts on behalf for your clients/customers'
  },
  {
    image: Truck,
    heading: 'Distributors',
    value: 'distributor',
    subHeading: 'Source Product receipts for Wholesale or Retail Distribution'
  },
  {
    image: User,
    heading: 'End user',
    value: 'end user',
    subHeading: 'Purchase and redeem Product receipts for direct product use'
  }
];
