import React from 'react';
import {
  Box,
  FormControlLabel,
  Paper,
  Stack,
  Typography,
  Radio,
  RadioGroup,
  SvgIcon
} from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import type { FormRadioProps } from 'components/form/common/formTypes';
import { SvgWrapper } from 'components/svg';

const Checked = () => {
  return (
    <SvgIcon inheritViewBox>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" width="16" height="16" rx="8" fill="#1671D9" />
        <g clipPath="url(#clip0_2441_467)">
          <path
            d="M12.0556 5.33301L7.16666 10.2219L4.94444 7.99967"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2441_467">
            <rect
              width="10.6667"
              height="10.6667"
              fill="white"
              transform="translate(3.16667 2.6665)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default function FormRadio<FormValueT extends FieldValues>(
  props: FormRadioProps<FormValueT>
) {
  const { label, control, name, radioData, ...rest } = props;

  return (
    <Controller
      rules={{ required: true }}
      control={control}
      name={name}
      render={({ field }) => (
        <RadioGroup {...field} {...rest}>
          {radioData.map((element, index) => {
            return (
              <Paper
                key={index}
                elevation={0}
                sx={{
                  width: '100%',
                  mb: '1rem',
                  borderRadius: '0.75rem',
                  border: '.125rem solid',
                  borderColor:
                    field.value === element.value ? '#3E7DF8' : '#F2F4F7',
                  background: '#FFF',
                  padding: '1rem',
                  cursor: 'pointer'
                }}
                onClick={() => field.onChange(element.value)}
              >
                <Stack
                  width="inherit"
                  justifyContent="space-between"
                  alignItems="center"
                  direction="row"
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        borderRadius: '0.75rem',
                        border: '.063rem solid #F9F9F9',
                        background: '#FFF',
                        padding: '.75rem',
                        width: '2.5rem',
                        height: '2.5rem',
                        display: 'flex'
                      }}
                    >
                      <SvgWrapper
                        icon={element.image}
                        width="1rem"
                        height="1rem"
                        styleOverrides={{
                          fill:
                            field.value === element.value
                              ? '#3E7DF8'
                              : '#D0D5DD',
                          stroke: 'none'
                        }}
                      />
                    </Box>
                    <Box ml=".5rem" display="flex" flexDirection="column">
                      <Typography
                        variant="bodyMediumSemibold"
                        sx={{ color: '#475467' }}
                      >
                        {element.heading}
                      </Typography>
                      <Typography
                        variant="bodyMediumMedium"
                        sx={{ color: '#98A2B3' }}
                      >
                        {element.subHeading}
                      </Typography>
                    </Box>
                  </Stack>
                  <FormControlLabel
                    value={element.value}
                    control={
                      <Radio
                        checkedIcon={<Checked />}
                        sx={{
                          color: '#fff',
                          width: '1.5rem',
                          height: '1.5rem',
                          border: '.094rem solid #D0D5DD',
                          mr: '.5rem'
                        }}
                      />
                    }
                    label={label}
                    labelPlacement="start"
                    {...rest}
                  />
                </Stack>
              </Paper>
            );
          })}
        </RadioGroup>
      )}
    />
  );
}
