import React from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import { SvgWrapper } from 'components/svg';
import { Button } from 'components/button';
import { ReactComponent as Info } from 'assets/svg/infoDanger.svg';
import { ReactComponent as X } from 'assets/svg/x.svg';

type RemoveUserModalProps = {
  open: boolean;
  onClose: () => void;
};

const RemoveUserModal: React.FC<RemoveUserModalProps> = ({ open, onClose }) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        padding: '1.25rem',
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '24.5rem',
            borderRadius: '0.75rem',
            border: '.125rem solid #F5F6F7',
            backgroundColor: '#FCFCFD'
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '1rem',
          alignItems: 'space-between',
          alignSelf: 'stretch'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1.25rem',
            alignItems: 'flex-start',
            alignSelf: 'stretch'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '3.07588rem',
              width: '3.3125rem',
              borderRadius: '6.25rem',
              backgroundColor: '#F6E8E7',
              padding: '0.5rem',
              marginBottom: '1rem'
            }}
          >
            <SvgWrapper
              icon={Info}
              width="1.5rem"
              height="1.5rem"
              styleOverrides={{
                opacity: '0.8rem',
                fill: '#FF5C52'
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center'
            }}
          >
            <Typography variant="bodyLargeSemibold" color="#344054">
              Remove User
            </Typography>
            <Typography variant="bodyMediumRegular" color="#667085">
              You are about to remove the CRO role for Evelyn James. Do you wish
              to proceed?
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            padding: '1.25rem 1.5rem',
            cursor: 'pointer'
          }}
          onClick={onClose}
        >
          <SvgWrapper icon={X} width="1.5rem" height="1.5rem" />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: '.063rem solid #F5F6F7',
          padding: '1.25rem 1.5rem',
          marginBottom: '0.25rem'
        }}
      >
        <Button
          color="grey"
          transparent
          size="md"
          text="Cancel"
          width="48%"
          onClick={onClose}
        />
        <Button
          submit
          color="primary"
          size="md"
          onClick={() => {
            console.log('Hi');
          }}
          text="Remove Role"
          width="48%"
        />
      </Box>
    </Dialog>
  );
};

export default RemoveUserModal;
