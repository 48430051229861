import React, { Fragment, useContext } from 'react';
import shield from 'assets/svg/shield.svg';
import Button from 'components/button/button';
import { fontFamily2, theme } from 'themes/theme';
import { Avatar, Box, Paper, Stack, Typography } from '@mui/material';
import FileUpload from 'components/common/fileUpload';
import { useForm, SubmitHandler, Resolver } from 'react-hook-form';
import FileView from 'components/common/fileView';
import { OnboardingContext } from '../context/onboardingContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { orgStepFormValidator3 } from '../validators/onboardingValidators';

type FormValues = {
  file?: unknown;
};

const IdVerification = () => {
  const {
    handleNextStep,
    handlePrevStep,
    data,
    uploadedFile,
    setUploadedFile,
    goToStep
  } = useContext(OnboardingContext);

  const { handleSubmit } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      file: data.file
    },
    resolver: yupResolver(
      orgStepFormValidator3
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = data => {
    console.log(data);
    return uploadedFile
      ? handleNextStep({
          file: [...(uploadedFile as File[])]
        })
      : goToStep(3);
  };

  const removeFile = (value: string) => {
    setUploadedFile((uploadedFile: File[]) => {
      if (uploadedFile === null) return null;
      return uploadedFile.filter(file => file.name !== value);
    });
  };

  return (
    <Box sx={{ width: { lg: '29.25rem' } }}>
      <Box width="inherit" sx={{ mb: '2rem', textAlign: 'center' }}>
        <Typography
          variant="h5"
          sx={{
            color: `${theme.palette.common.heading}`
          }}
        >
          ID Verification
        </Typography>
        <Typography
          variant="bodyMediumRegular"
          sx={{
            color: `${theme.palette.common.labelColor}`
          }}
        >
          Security is important to us, and to you. Verify your ID to access your
          dashboard
        </Typography>
      </Box>

      <Box width={'inherit'}>
        <Box
          sx={{
            height: '16.5rem',
            marginBottom: '1rem'
          }}
        >
          <FileUpload
            onFileUpload={setUploadedFile}
            maxFiles={5}
            maxSize={1024 * 1000}
            supportedFiles={{ 'application/pdf': ['.pdf'] }}
            showBtn
          />
        </Box>

        {uploadedFile !== null && uploadedFile.length >= 1 ? (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontFamily: `${fontFamily2}`,
                width: '100%',
                marginTop: '2rem'
              }}
            >
              <Typography
                variant="bodyLargeSemibold"
                sx={{
                  mb: '1.5rem',
                  width: '100%',
                  textAlign: 'left',
                  color: '#667185'
                }}
              >
                Uploaded Files
              </Typography>
            </Box>
            <>
              {uploadedFile.map((file: File) => {
                return (
                  <Fragment key={file.name}>
                    <FileView
                      preview={file}
                      removeFile={() => removeFile(file.name)}
                    />
                  </Fragment>
                );
              })}
            </>
          </>
        ) : null}
      </Box>

      <Paper
        elevation={0}
        sx={{
          width: '100%',
          mb: '1rem',
          borderRadius: '0.75rem',
          border: '.094rem solid #F2F4F7',
          background: '#FFF',
          padding: '1rem'
        }}
      >
        <Stack
          width="inherit"
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          gap=".75rem"
        >
          <Avatar src={shield} />
          <Box>
            <Typography
              sx={{
                color: '#475467',
                fontSize: '.75rem',
                fontWeight: 600,
                lineHeight: '0.9rem',
                letterSpacing: '0.03rem',
                textTransform: 'uppercase',
                fontFamily: fontFamily2
              }}
            >
              100% trust worthy
            </Typography>
            <Typography
              variant="bodyMediumRegular"
              sx={{
                color: '#475467'
              }}
            >
              We take data security seriously. Your information is always
              protected
            </Typography>
          </Box>
        </Stack>
      </Paper>

      <Box width="inherit">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Button
            submit
            color="primary"
            size="lg"
            text={uploadedFile ? 'Next' : 'Skip for now'}
            width="100%"
            styleOverrides={{ marginBottom: '0.25rem' }}
          />
          <Button
            color="grey"
            transparent
            size="lg"
            onClick={() => handlePrevStep()}
            text="Back"
            width="100%"
          />
        </form>
      </Box>
    </Box>
  );
};

export default IdVerification;
