import React from 'react';
import { theme } from 'themes/theme';
import { Box, Stack, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import defaultLogo from 'assets/custom-svg/Scotialogo.svg';
import forgotPasswordLogo from 'assets/custom-svg/Primary Logo@2x 1.svg';
import { Button } from 'components/button';

const AuthNavbar: React.FC = () => {
  const { pathname } = useLocation();

  const forgotPasswordPath =
    pathname === '/forgot-password' || pathname.includes('/password/reset/');

  const bgTransparent = pathname === '/login';

  return (
    <Toolbar
      sx={{
        width: 'inherit',
        backgroundColor: bgTransparent
          ? 'transparent'
          : `${theme.palette.common.white}`,
        height: '3.75rem',
        position: forgotPasswordPath ? 'relative' : 'absolute',
        borderBottom: bgTransparent ? 'none' : '.063rem solid #FCFCFD',
        '&.MuiToolbar-root': {
          p: 0,
          top: 0
        }
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: '0.75rem 1.8rem'
        }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <Box
            component="img"
            src={forgotPasswordPath ? forgotPasswordLogo : defaultLogo}
            sx={{
              width: '2.5rem',
              height: 'auto'
            }}
          />
          <Typography
            variant="captionSmall"
            sx={{
              color: `${theme.palette.common.heading}`,
              fontWeight: 600
            }}
          >
            Scotia Bank
          </Typography>
        </Stack>

        <Button
          color="grey"
          transparent
          size="sm"
          iconPosition="start"
          text="Help"
        />
      </Stack>
    </Toolbar>
  );
};

export default AuthNavbar;
