import React from 'react';
import { Box, Paper, Skeleton, Stack } from '@mui/material';

const IndicatorSkeleton = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        padding: '0.5rem',
        alignItems: 'center',
        backgroundColor: '#FFF',
        border: '.125rem solid #F5F6F7',
        borderRadius: '0.5rem',
        flexGrow: '1',
        width: '16rem'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flexShrink: '0',
          width: '100%'
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="inherit"
          spacing={1}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              flex: '1 0 0'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: '0.25rem',
                alignItems: 'flex-start'
              }}
            >
              <Skeleton
                animation="wave"
                variant="circular"
                width="1rem"
                height="1rem"
                sx={{ bgcolor: '#F2F4F7' }}
              />
            </Box>

            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              sx={{ fontSize: '1.2rem', bgcolor: '#F2F4F7' }}
            />
          </Stack>
        </Stack>

        <Stack direction="column" alignItems="flex-start" width="100%">
          <Skeleton
            animation="wave"
            variant="text"
            width="100%"
            sx={{ fontSize: '2rem', bgcolor: '#F2F4F7' }}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

export default IndicatorSkeleton;
